import { FileData } from '../Types/generator.types'

export const fileList: FileData[] = [
  { id: 1, filename: 'png/action/123.png', name: '123', category: 0 },
  { id: 2, filename: 'png/action/3d_rotation.png', name: '3d_rotation', category: 0 },
  { id: 3, filename: 'png/action/abc.png', name: 'abc', category: 0 },
  { id: 4, filename: 'png/action/accessibility.png', name: 'accessibility', category: 0 },
  { id: 5, filename: 'png/action/accessibility_new.png', name: 'accessibility_new', category: 0 },
  { id: 6, filename: 'png/action/accessible.png', name: 'accessible', category: 0 },
  { id: 7, filename: 'png/action/accessible_forward.png', name: 'accessible_forward', category: 0 },
  { id: 8, filename: 'png/action/account_balance.png', name: 'account_balance', category: 0 },
  {
    id: 9,
    filename: 'png/action/account_balance_wallet.png',
    name: 'account_balance_wallet',
    category: 0,
  },
  { id: 10, filename: 'png/action/account_box.png', name: 'account_box', category: 0 },
  { id: 11, filename: 'png/action/account_circle.png', name: 'account_circle', category: 0 },
  { id: 12, filename: 'png/action/add_card.png', name: 'add_card', category: 0 },
  { id: 13, filename: 'png/action/add_shopping_cart.png', name: 'add_shopping_cart', category: 0 },
  { id: 14, filename: 'png/action/add_task.png', name: 'add_task', category: 0 },
  { id: 15, filename: 'png/action/add_to_drive.png', name: 'add_to_drive', category: 0 },
  { id: 16, filename: 'png/action/addchart.png', name: 'addchart', category: 0 },
  {
    id: 17,
    filename: 'png/action/admin_panel_settings.png',
    name: 'admin_panel_settings',
    category: 0,
  },
  { id: 18, filename: 'png/action/ads_click.png', name: 'ads_click', category: 0 },
  { id: 19, filename: 'png/action/alarm.png', name: 'alarm', category: 0 },
  { id: 20, filename: 'png/action/alarm_add.png', name: 'alarm_add', category: 0 },
  { id: 21, filename: 'png/action/alarm_off.png', name: 'alarm_off', category: 0 },
  { id: 22, filename: 'png/action/alarm_on.png', name: 'alarm_on', category: 0 },
  { id: 23, filename: 'png/action/all_inbox.png', name: 'all_inbox', category: 0 },
  { id: 24, filename: 'png/action/all_out.png', name: 'all_out', category: 0 },
  { id: 25, filename: 'png/action/analytics.png', name: 'analytics', category: 0 },
  { id: 26, filename: 'png/action/anchor.png', name: 'anchor', category: 0 },
  { id: 27, filename: 'png/action/android.png', name: 'android', category: 0 },
  { id: 28, filename: 'png/action/announcement.png', name: 'announcement', category: 0 },
  { id: 29, filename: 'png/action/api.png', name: 'api', category: 0 },
  { id: 30, filename: 'png/action/app_blocking.png', name: 'app_blocking', category: 0 },
  { id: 31, filename: 'png/action/app_shortcut.png', name: 'app_shortcut', category: 0 },
  { id: 32, filename: 'png/action/arrow_circle_down.png', name: 'arrow_circle_down', category: 0 },
  { id: 33, filename: 'png/action/arrow_circle_left.png', name: 'arrow_circle_left', category: 0 },
  {
    id: 34,
    filename: 'png/action/arrow_circle_right.png',
    name: 'arrow_circle_right',
    category: 0,
  },
  { id: 35, filename: 'png/action/arrow_circle_up.png', name: 'arrow_circle_up', category: 0 },
  { id: 36, filename: 'png/action/arrow_right_alt.png', name: 'arrow_right_alt', category: 0 },
  { id: 37, filename: 'png/action/article.png', name: 'article', category: 0 },
  { id: 38, filename: 'png/action/aspect_ratio.png', name: 'aspect_ratio', category: 0 },
  { id: 39, filename: 'png/action/assessment.png', name: 'assessment', category: 0 },
  { id: 40, filename: 'png/action/assignment.png', name: 'assignment', category: 0 },
  { id: 41, filename: 'png/action/assignment_ind.png', name: 'assignment_ind', category: 0 },
  { id: 42, filename: 'png/action/assignment_late.png', name: 'assignment_late', category: 0 },
  { id: 43, filename: 'png/action/assignment_return.png', name: 'assignment_return', category: 0 },
  {
    id: 44,
    filename: 'png/action/assignment_returned.png',
    name: 'assignment_returned',
    category: 0,
  },
  {
    id: 45,
    filename: 'png/action/assignment_turned_in.png',
    name: 'assignment_turned_in',
    category: 0,
  },
  { id: 46, filename: 'png/action/assured_workload.png', name: 'assured_workload', category: 0 },
  { id: 47, filename: 'png/action/autorenew.png', name: 'autorenew', category: 0 },
  { id: 48, filename: 'png/action/backup.png', name: 'backup', category: 0 },
  { id: 49, filename: 'png/action/backup_table.png', name: 'backup_table', category: 0 },
  { id: 50, filename: 'png/action/balance.png', name: 'balance', category: 0 },
  { id: 51, filename: 'png/action/batch_prediction.png', name: 'batch_prediction', category: 0 },
  { id: 52, filename: 'png/action/book.png', name: 'book', category: 0 },
  { id: 53, filename: 'png/action/book_online.png', name: 'book_online', category: 0 },
  { id: 54, filename: 'png/action/bookmark.png', name: 'bookmark', category: 0 },
  { id: 55, filename: 'png/action/bookmark_add.png', name: 'bookmark_add', category: 0 },
  { id: 56, filename: 'png/action/bookmark_added.png', name: 'bookmark_added', category: 0 },
  { id: 57, filename: 'png/action/bookmark_border.png', name: 'bookmark_border', category: 0 },
  { id: 58, filename: 'png/action/bookmark_remove.png', name: 'bookmark_remove', category: 0 },
  { id: 59, filename: 'png/action/bookmarks.png', name: 'bookmarks', category: 0 },
  { id: 60, filename: 'png/action/browse_gallery.png', name: 'browse_gallery', category: 0 },
  { id: 61, filename: 'png/action/bug_report.png', name: 'bug_report', category: 0 },
  { id: 62, filename: 'png/action/build.png', name: 'build', category: 0 },
  { id: 63, filename: 'png/action/build_circle.png', name: 'build_circle', category: 0 },
  { id: 64, filename: 'png/action/cached.png', name: 'cached', category: 0 },
  { id: 65, filename: 'png/action/calendar_month.png', name: 'calendar_month', category: 0 },
  { id: 66, filename: 'png/action/calendar_today.png', name: 'calendar_today', category: 0 },
  { id: 67, filename: 'png/action/calendar_view_day.png', name: 'calendar_view_day', category: 0 },
  {
    id: 68,
    filename: 'png/action/calendar_view_month.png',
    name: 'calendar_view_month',
    category: 0,
  },
  {
    id: 69,
    filename: 'png/action/calendar_view_week.png',
    name: 'calendar_view_week',
    category: 0,
  },
  { id: 70, filename: 'png/action/camera_enhance.png', name: 'camera_enhance', category: 0 },
  {
    id: 71,
    filename: 'png/action/cancel_schedule_send.png',
    name: 'cancel_schedule_send',
    category: 0,
  },
  { id: 72, filename: 'png/action/card_giftcard.png', name: 'card_giftcard', category: 0 },
  { id: 73, filename: 'png/action/card_membership.png', name: 'card_membership', category: 0 },
  { id: 74, filename: 'png/action/card_travel.png', name: 'card_travel', category: 0 },
  { id: 75, filename: 'png/action/change_history.png', name: 'change_history', category: 0 },
  { id: 76, filename: 'png/action/check_circle.png', name: 'check_circle', category: 0 },
  {
    id: 77,
    filename: 'png/action/check_circle_outline.png',
    name: 'check_circle_outline',
    category: 0,
  },
  {
    id: 78,
    filename: 'png/action/chrome_reader_mode.png',
    name: 'chrome_reader_mode',
    category: 0,
  },
  {
    id: 79,
    filename: 'png/action/circle_notifications.png',
    name: 'circle_notifications',
    category: 0,
  },
  { id: 80, filename: 'png/action/class.png', name: 'class', category: 0 },
  { id: 81, filename: 'png/action/close_fullscreen.png', name: 'close_fullscreen', category: 0 },
  { id: 82, filename: 'png/action/code.png', name: 'code', category: 0 },
  { id: 83, filename: 'png/action/code_off.png', name: 'code_off', category: 0 },
  { id: 84, filename: 'png/action/comment_bank.png', name: 'comment_bank', category: 0 },
  { id: 85, filename: 'png/action/commit.png', name: 'commit', category: 0 },
  { id: 86, filename: 'png/action/commute.png', name: 'commute', category: 0 },
  { id: 87, filename: 'png/action/compare_arrows.png', name: 'compare_arrows', category: 0 },
  { id: 88, filename: 'png/action/compress.png', name: 'compress', category: 0 },
  { id: 89, filename: 'png/action/contact_page.png', name: 'contact_page', category: 0 },
  { id: 90, filename: 'png/action/contact_support.png', name: 'contact_support', category: 0 },
  { id: 91, filename: 'png/action/contactless.png', name: 'contactless', category: 0 },
  { id: 92, filename: 'png/action/copyright.png', name: 'copyright', category: 0 },
  { id: 93, filename: 'png/action/credit_card.png', name: 'credit_card', category: 0 },
  { id: 94, filename: 'png/action/credit_card_off.png', name: 'credit_card_off', category: 0 },
  { id: 95, filename: 'png/action/css.png', name: 'css', category: 0 },
  { id: 96, filename: 'png/action/currency_exchange.png', name: 'currency_exchange', category: 0 },
  { id: 97, filename: 'png/action/dangerous.png', name: 'dangerous', category: 0 },
  { id: 98, filename: 'png/action/dashboard.png', name: 'dashboard', category: 0 },
  {
    id: 99,
    filename: 'png/action/dashboard_customize.png',
    name: 'dashboard_customize',
    category: 0,
  },
  { id: 100, filename: 'png/action/data_exploration.png', name: 'data_exploration', category: 0 },
  { id: 101, filename: 'png/action/data_thresholding.png', name: 'data_thresholding', category: 0 },
  { id: 102, filename: 'png/action/date_range.png', name: 'date_range', category: 0 },
  { id: 103, filename: 'png/action/delete.png', name: 'delete', category: 0 },
  { id: 104, filename: 'png/action/delete_forever.png', name: 'delete_forever', category: 0 },
  { id: 105, filename: 'png/action/delete_outline.png', name: 'delete_outline', category: 0 },
  { id: 106, filename: 'png/action/density_large.png', name: 'density_large', category: 0 },
  { id: 107, filename: 'png/action/density_medium.png', name: 'density_medium', category: 0 },
  { id: 108, filename: 'png/action/density_small.png', name: 'density_small', category: 0 },
  { id: 109, filename: 'png/action/description.png', name: 'description', category: 0 },
  {
    id: 110,
    filename: 'png/action/disabled_by_default.png',
    name: 'disabled_by_default',
    category: 0,
  },
  { id: 111, filename: 'png/action/disabled_visible.png', name: 'disabled_visible', category: 0 },
  { id: 112, filename: 'png/action/display_settings.png', name: 'display_settings', category: 0 },
  { id: 113, filename: 'png/action/dns.png', name: 'dns', category: 0 },
  { id: 114, filename: 'png/action/done.png', name: 'done', category: 0 },
  { id: 115, filename: 'png/action/done_all.png', name: 'done_all', category: 0 },
  { id: 116, filename: 'png/action/done_outline.png', name: 'done_outline', category: 0 },
  { id: 117, filename: 'png/action/donut_large.png', name: 'donut_large', category: 0 },
  { id: 118, filename: 'png/action/donut_small.png', name: 'donut_small', category: 0 },
  { id: 119, filename: 'png/action/drag_indicator.png', name: 'drag_indicator', category: 0 },
  { id: 120, filename: 'png/action/dynamic_form.png', name: 'dynamic_form', category: 0 },
  { id: 121, filename: 'png/action/eco.png', name: 'eco', category: 0 },
  { id: 122, filename: 'png/action/edit_calendar.png', name: 'edit_calendar', category: 0 },
  { id: 123, filename: 'png/action/edit_off.png', name: 'edit_off', category: 0 },
  { id: 124, filename: 'png/action/eject.png', name: 'eject', category: 0 },
  { id: 125, filename: 'png/action/euro_symbol.png', name: 'euro_symbol', category: 0 },
  { id: 126, filename: 'png/action/event.png', name: 'event', category: 0 },
  { id: 127, filename: 'png/action/event_repeat.png', name: 'event_repeat', category: 0 },
  { id: 128, filename: 'png/action/event_seat.png', name: 'event_seat', category: 0 },
  { id: 129, filename: 'png/action/exit_to_app.png', name: 'exit_to_app', category: 0 },
  { id: 130, filename: 'png/action/expand.png', name: 'expand', category: 0 },
  { id: 131, filename: 'png/action/explore.png', name: 'explore', category: 0 },
  { id: 132, filename: 'png/action/explore_off.png', name: 'explore_off', category: 0 },
  { id: 133, filename: 'png/action/extension.png', name: 'extension', category: 0 },
  { id: 134, filename: 'png/action/extension_off.png', name: 'extension_off', category: 0 },
  { id: 135, filename: 'png/action/face.png', name: 'face', category: 0 },
  { id: 136, filename: 'png/action/face_unlock.png', name: 'face_unlock', category: 0 },
  { id: 137, filename: 'png/action/fact_check.png', name: 'fact_check', category: 0 },
  { id: 138, filename: 'png/action/favorite.png', name: 'favorite', category: 0 },
  { id: 139, filename: 'png/action/favorite_border.png', name: 'favorite_border', category: 0 },
  { id: 140, filename: 'png/action/fax.png', name: 'fax', category: 0 },
  { id: 141, filename: 'png/action/feedback.png', name: 'feedback', category: 0 },
  { id: 142, filename: 'png/action/file_present.png', name: 'file_present', category: 0 },
  { id: 143, filename: 'png/action/filter_alt.png', name: 'filter_alt', category: 0 },
  { id: 144, filename: 'png/action/filter_alt_off.png', name: 'filter_alt_off', category: 0 },
  { id: 145, filename: 'png/action/filter_list_alt.png', name: 'filter_list_alt', category: 0 },
  { id: 146, filename: 'png/action/find_in_page.png', name: 'find_in_page', category: 0 },
  { id: 147, filename: 'png/action/find_replace.png', name: 'find_replace', category: 0 },
  { id: 148, filename: 'png/action/fingerprint.png', name: 'fingerprint', category: 0 },
  { id: 149, filename: 'png/action/fit_screen.png', name: 'fit_screen', category: 0 },
  { id: 150, filename: 'png/action/flaky.png', name: 'flaky', category: 0 },
  { id: 151, filename: 'png/action/flight_land.png', name: 'flight_land', category: 0 },
  { id: 152, filename: 'png/action/flight_takeoff.png', name: 'flight_takeoff', category: 0 },
  { id: 153, filename: 'png/action/flip_to_back.png', name: 'flip_to_back', category: 0 },
  { id: 154, filename: 'png/action/flip_to_front.png', name: 'flip_to_front', category: 0 },
  { id: 155, filename: 'png/action/flutter_dash.png', name: 'flutter_dash', category: 0 },
  { id: 156, filename: 'png/action/free_cancellation.png', name: 'free_cancellation', category: 0 },
  { id: 157, filename: 'png/action/g_translate.png', name: 'g_translate', category: 0 },
  { id: 158, filename: 'png/action/gavel.png', name: 'gavel', category: 0 },
  { id: 159, filename: 'png/action/generating_tokens.png', name: 'generating_tokens', category: 0 },
  { id: 160, filename: 'png/action/get_app.png', name: 'get_app', category: 0 },
  { id: 161, filename: 'png/action/gif.png', name: 'gif', category: 0 },
  { id: 162, filename: 'png/action/gif_box.png', name: 'gif_box', category: 0 },
  { id: 163, filename: 'png/action/grade.png', name: 'grade', category: 0 },
  { id: 164, filename: 'png/action/grading.png', name: 'grading', category: 0 },
  { id: 165, filename: 'png/action/group_work.png', name: 'group_work', category: 0 },
  { id: 166, filename: 'png/action/help.png', name: 'help', category: 0 },
  { id: 167, filename: 'png/action/help_center.png', name: 'help_center', category: 0 },
  { id: 168, filename: 'png/action/help_outline.png', name: 'help_outline', category: 0 },
  { id: 169, filename: 'png/action/hide_source.png', name: 'hide_source', category: 0 },
  { id: 170, filename: 'png/action/highlight_alt.png', name: 'highlight_alt', category: 0 },
  { id: 171, filename: 'png/action/highlight_off.png', name: 'highlight_off', category: 0 },
  { id: 172, filename: 'png/action/history.png', name: 'history', category: 0 },
  {
    id: 173,
    filename: 'png/action/history_toggle_off.png',
    name: 'history_toggle_off',
    category: 0,
  },
  { id: 174, filename: 'png/action/hls.png', name: 'hls', category: 0 },
  { id: 175, filename: 'png/action/hls_off.png', name: 'hls_off', category: 0 },
  { id: 176, filename: 'png/action/home.png', name: 'home', category: 0 },
  { id: 177, filename: 'png/action/home_filled.png', name: 'home_filled', category: 0 },
  { id: 178, filename: 'png/action/horizontal_split.png', name: 'horizontal_split', category: 0 },
  { id: 179, filename: 'png/action/hotel_class.png', name: 'hotel_class', category: 0 },
  {
    id: 180,
    filename: 'png/action/hourglass_disabled.png',
    name: 'hourglass_disabled',
    category: 0,
  },
  { id: 181, filename: 'png/action/hourglass_empty.png', name: 'hourglass_empty', category: 0 },
  { id: 182, filename: 'png/action/hourglass_full.png', name: 'hourglass_full', category: 0 },
  { id: 183, filename: 'png/action/html.png', name: 'html', category: 0 },
  { id: 184, filename: 'png/action/http.png', name: 'http', category: 0 },
  { id: 185, filename: 'png/action/https.png', name: 'https', category: 0 },
  { id: 186, filename: 'png/action/important_devices.png', name: 'important_devices', category: 0 },
  { id: 187, filename: 'png/action/info.png', name: 'info', category: 0 },
  { id: 188, filename: 'png/action/info_outline.png', name: 'info_outline', category: 0 },
  { id: 189, filename: 'png/action/input.png', name: 'input', category: 0 },
  { id: 190, filename: 'png/action/install_desktop.png', name: 'install_desktop', category: 0 },
  { id: 191, filename: 'png/action/install_mobile.png', name: 'install_mobile', category: 0 },
  {
    id: 192,
    filename: 'png/action/integration_instructions.png',
    name: 'integration_instructions',
    category: 0,
  },
  { id: 193, filename: 'png/action/invert_colors.png', name: 'invert_colors', category: 0 },
  { id: 194, filename: 'png/action/javascript.png', name: 'javascript', category: 0 },
  { id: 195, filename: 'png/action/join_full.png', name: 'join_full', category: 0 },
  { id: 196, filename: 'png/action/join_inner.png', name: 'join_inner', category: 0 },
  { id: 197, filename: 'png/action/join_left.png', name: 'join_left', category: 0 },
  { id: 198, filename: 'png/action/join_right.png', name: 'join_right', category: 0 },
  { id: 199, filename: 'png/action/label.png', name: 'label', category: 0 },
  { id: 200, filename: 'png/action/label_important.png', name: 'label_important', category: 0 },
  {
    id: 201,
    filename: 'png/action/label_important_outline.png',
    name: 'label_important_outline',
    category: 0,
  },
  { id: 202, filename: 'png/action/label_off.png', name: 'label_off', category: 0 },
  { id: 203, filename: 'png/action/label_outline.png', name: 'label_outline', category: 0 },
  { id: 204, filename: 'png/action/language.png', name: 'language', category: 0 },
  { id: 205, filename: 'png/action/launch.png', name: 'launch', category: 0 },
  { id: 206, filename: 'png/action/leaderboard.png', name: 'leaderboard', category: 0 },
  { id: 207, filename: 'png/action/lightbulb.png', name: 'lightbulb', category: 0 },
  { id: 208, filename: 'png/action/lightbulb_circle.png', name: 'lightbulb_circle', category: 0 },
  { id: 209, filename: 'png/action/lightbulb_outline.png', name: 'lightbulb_outline', category: 0 },
  { id: 210, filename: 'png/action/line_style.png', name: 'line_style', category: 0 },
  { id: 211, filename: 'png/action/line_weight.png', name: 'line_weight', category: 0 },
  { id: 212, filename: 'png/action/list.png', name: 'list', category: 0 },
  { id: 213, filename: 'png/action/lock.png', name: 'lock', category: 0 },
  { id: 214, filename: 'png/action/lock_clock.png', name: 'lock_clock', category: 0 },
  { id: 215, filename: 'png/action/lock_open.png', name: 'lock_open', category: 0 },
  { id: 216, filename: 'png/action/lock_outline.png', name: 'lock_outline', category: 0 },
  { id: 217, filename: 'png/action/lock_reset.png', name: 'lock_reset', category: 0 },
  { id: 218, filename: 'png/action/login.png', name: 'login', category: 0 },
  { id: 219, filename: 'png/action/logout.png', name: 'logout', category: 0 },
  { id: 220, filename: 'png/action/loyalty.png', name: 'loyalty', category: 0 },
  { id: 221, filename: 'png/action/manage_accounts.png', name: 'manage_accounts', category: 0 },
  { id: 222, filename: 'png/action/manage_history.png', name: 'manage_history', category: 0 },
  { id: 223, filename: 'png/action/mark_as_unread.png', name: 'mark_as_unread', category: 0 },
  {
    id: 224,
    filename: 'png/action/markunread_mailbox.png',
    name: 'markunread_mailbox',
    category: 0,
  },
  { id: 225, filename: 'png/action/maximize.png', name: 'maximize', category: 0 },
  { id: 226, filename: 'png/action/mediation.png', name: 'mediation', category: 0 },
  { id: 227, filename: 'png/action/minimize.png', name: 'minimize', category: 0 },
  { id: 228, filename: 'png/action/model_training.png', name: 'model_training', category: 0 },
  { id: 229, filename: 'png/action/network_ping.png', name: 'network_ping', category: 0 },
  { id: 230, filename: 'png/action/new_label.png', name: 'new_label', category: 0 },
  { id: 231, filename: 'png/action/next_plan.png', name: 'next_plan', category: 0 },
  { id: 232, filename: 'png/action/nightlight_round.png', name: 'nightlight_round', category: 0 },
  { id: 233, filename: 'png/action/no_accounts.png', name: 'no_accounts', category: 0 },
  { id: 234, filename: 'png/action/noise_aware.png', name: 'noise_aware', category: 0 },
  { id: 235, filename: 'png/action/noise_control_off.png', name: 'noise_control_off', category: 0 },
  { id: 236, filename: 'png/action/not_accessible.png', name: 'not_accessible', category: 0 },
  { id: 237, filename: 'png/action/not_started.png', name: 'not_started', category: 0 },
  { id: 238, filename: 'png/action/note_add.png', name: 'note_add', category: 0 },
  { id: 239, filename: 'png/action/offline_bolt.png', name: 'offline_bolt', category: 0 },
  { id: 240, filename: 'png/action/offline_pin.png', name: 'offline_pin', category: 0 },
  {
    id: 241,
    filename: 'png/action/on_device_training.png',
    name: 'on_device_training',
    category: 0,
  },
  { id: 242, filename: 'png/action/online_prediction.png', name: 'online_prediction', category: 0 },
  { id: 243, filename: 'png/action/opacity.png', name: 'opacity', category: 0 },
  { id: 244, filename: 'png/action/open_in_browser.png', name: 'open_in_browser', category: 0 },
  { id: 245, filename: 'png/action/open_in_full.png', name: 'open_in_full', category: 0 },
  { id: 246, filename: 'png/action/open_in_new.png', name: 'open_in_new', category: 0 },
  { id: 247, filename: 'png/action/open_in_new_off.png', name: 'open_in_new_off', category: 0 },
  { id: 248, filename: 'png/action/open_with.png', name: 'open_with', category: 0 },
  { id: 249, filename: 'png/action/outbond.png', name: 'outbond', category: 0 },
  { id: 250, filename: 'png/action/outbound.png', name: 'outbound', category: 0 },
  { id: 251, filename: 'png/action/outbox.png', name: 'outbox', category: 0 },
  { id: 252, filename: 'png/action/outgoing_mail.png', name: 'outgoing_mail', category: 0 },
  { id: 253, filename: 'png/action/outlet.png', name: 'outlet', category: 0 },
  { id: 254, filename: 'png/action/output.png', name: 'output', category: 0 },
  { id: 255, filename: 'png/action/pageview.png', name: 'pageview', category: 0 },
  { id: 256, filename: 'png/action/paid.png', name: 'paid', category: 0 },
  { id: 257, filename: 'png/action/pan_tool.png', name: 'pan_tool', category: 0 },
  { id: 258, filename: 'png/action/pan_tool_alt.png', name: 'pan_tool_alt', category: 0 },
  { id: 259, filename: 'png/action/payment.png', name: 'payment', category: 0 },
  { id: 260, filename: 'png/action/pending.png', name: 'pending', category: 0 },
  { id: 261, filename: 'png/action/pending_actions.png', name: 'pending_actions', category: 0 },
  { id: 262, filename: 'png/action/percent.png', name: 'percent', category: 0 },
  { id: 263, filename: 'png/action/perm_camera_mic.png', name: 'perm_camera_mic', category: 0 },
  {
    id: 264,
    filename: 'png/action/perm_contact_calendar.png',
    name: 'perm_contact_calendar',
    category: 0,
  },
  { id: 265, filename: 'png/action/perm_data_setting.png', name: 'perm_data_setting', category: 0 },
  {
    id: 266,
    filename: 'png/action/perm_device_information.png',
    name: 'perm_device_information',
    category: 0,
  },
  { id: 267, filename: 'png/action/perm_identity.png', name: 'perm_identity', category: 0 },
  { id: 268, filename: 'png/action/perm_media.png', name: 'perm_media', category: 0 },
  { id: 269, filename: 'png/action/perm_phone_msg.png', name: 'perm_phone_msg', category: 0 },
  { id: 270, filename: 'png/action/perm_scan_wifi.png', name: 'perm_scan_wifi', category: 0 },
  { id: 271, filename: 'png/action/pets.png', name: 'pets', category: 0 },
  { id: 272, filename: 'png/action/php.png', name: 'php', category: 0 },
  {
    id: 273,
    filename: 'png/action/picture_in_picture.png',
    name: 'picture_in_picture',
    category: 0,
  },
  {
    id: 274,
    filename: 'png/action/picture_in_picture_alt.png',
    name: 'picture_in_picture_alt',
    category: 0,
  },
  { id: 275, filename: 'png/action/pin_end.png', name: 'pin_end', category: 0 },
  { id: 276, filename: 'png/action/pin_invoke.png', name: 'pin_invoke', category: 0 },
  { id: 277, filename: 'png/action/pinch.png', name: 'pinch', category: 0 },
  { id: 278, filename: 'png/action/plagiarism.png', name: 'plagiarism', category: 0 },
  { id: 279, filename: 'png/action/play_for_work.png', name: 'play_for_work', category: 0 },
  { id: 280, filename: 'png/action/polymer.png', name: 'polymer', category: 0 },
  {
    id: 281,
    filename: 'png/action/power_settings_new.png',
    name: 'power_settings_new',
    category: 0,
  },
  { id: 282, filename: 'png/action/pregnant_woman.png', name: 'pregnant_woman', category: 0 },
  { id: 283, filename: 'png/action/preview.png', name: 'preview', category: 0 },
  { id: 284, filename: 'png/action/print.png', name: 'print', category: 0 },
  { id: 285, filename: 'png/action/privacy_tip.png', name: 'privacy_tip', category: 0 },
  {
    id: 286,
    filename: 'png/action/private_connectivity.png',
    name: 'private_connectivity',
    category: 0,
  },
  {
    id: 287,
    filename: 'png/action/production_quantity_limits.png',
    name: 'production_quantity_limits',
    category: 0,
  },
  {
    id: 288,
    filename: 'png/action/published_with_changes.png',
    name: 'published_with_changes',
    category: 0,
  },
  { id: 289, filename: 'png/action/query_builder.png', name: 'query_builder', category: 0 },
  { id: 290, filename: 'png/action/question_answer.png', name: 'question_answer', category: 0 },
  { id: 291, filename: 'png/action/question_mark.png', name: 'question_mark', category: 0 },
  { id: 292, filename: 'png/action/quickreply.png', name: 'quickreply', category: 0 },
  { id: 293, filename: 'png/action/receipt.png', name: 'receipt', category: 0 },
  { id: 294, filename: 'png/action/record_voice_over.png', name: 'record_voice_over', category: 0 },
  { id: 295, filename: 'png/action/redeem.png', name: 'redeem', category: 0 },
  { id: 296, filename: 'png/action/remove_done.png', name: 'remove_done', category: 0 },
  {
    id: 297,
    filename: 'png/action/remove_shopping_cart.png',
    name: 'remove_shopping_cart',
    category: 0,
  },
  { id: 298, filename: 'png/action/reorder.png', name: 'reorder', category: 0 },
  { id: 299, filename: 'png/action/report_problem.png', name: 'report_problem', category: 0 },
  { id: 300, filename: 'png/action/request_page.png', name: 'request_page', category: 0 },
  { id: 301, filename: 'png/action/restore.png', name: 'restore', category: 0 },
  {
    id: 302,
    filename: 'png/action/restore_from_trash.png',
    name: 'restore_from_trash',
    category: 0,
  },
  { id: 303, filename: 'png/action/restore_page.png', name: 'restore_page', category: 0 },
  { id: 304, filename: 'png/action/rocket.png', name: 'rocket', category: 0 },
  { id: 305, filename: 'png/action/rocket_launch.png', name: 'rocket_launch', category: 0 },
  { id: 306, filename: 'png/action/room.png', name: 'room', category: 0 },
  { id: 307, filename: 'png/action/rounded_corner.png', name: 'rounded_corner', category: 0 },
  { id: 308, filename: 'png/action/rowing.png', name: 'rowing', category: 0 },
  { id: 309, filename: 'png/action/rule.png', name: 'rule', category: 0 },
  { id: 310, filename: 'png/action/satellite_alt.png', name: 'satellite_alt', category: 0 },
  { id: 311, filename: 'png/action/saved_search.png', name: 'saved_search', category: 0 },
  { id: 312, filename: 'png/action/savings.png', name: 'savings', category: 0 },
  { id: 313, filename: 'png/action/schedule.png', name: 'schedule', category: 0 },
  { id: 314, filename: 'png/action/schedule_send.png', name: 'schedule_send', category: 0 },
  { id: 315, filename: 'png/action/search.png', name: 'search', category: 0 },
  { id: 316, filename: 'png/action/search_off.png', name: 'search_off', category: 0 },
  { id: 317, filename: 'png/action/segment.png', name: 'segment', category: 0 },
  { id: 318, filename: 'png/action/send_and_archive.png', name: 'send_and_archive', category: 0 },
  { id: 319, filename: 'png/action/sensors.png', name: 'sensors', category: 0 },
  { id: 320, filename: 'png/action/sensors_off.png', name: 'sensors_off', category: 0 },
  { id: 321, filename: 'png/action/settings.png', name: 'settings', category: 0 },
  {
    id: 322,
    filename: 'png/action/settings_accessibility.png',
    name: 'settings_accessibility',
    category: 0,
  },
  {
    id: 323,
    filename: 'png/action/settings_applications.png',
    name: 'settings_applications',
    category: 0,
  },
  {
    id: 324,
    filename: 'png/action/settings_backup_restore.png',
    name: 'settings_backup_restore',
    category: 0,
  },
  {
    id: 325,
    filename: 'png/action/settings_bluetooth.png',
    name: 'settings_bluetooth',
    category: 0,
  },
  {
    id: 326,
    filename: 'png/action/settings_brightness.png',
    name: 'settings_brightness',
    category: 0,
  },
  { id: 327, filename: 'png/action/settings_cell.png', name: 'settings_cell', category: 0 },
  { id: 328, filename: 'png/action/settings_ethernet.png', name: 'settings_ethernet', category: 0 },
  {
    id: 329,
    filename: 'png/action/settings_input_antenna.png',
    name: 'settings_input_antenna',
    category: 0,
  },
  {
    id: 330,
    filename: 'png/action/settings_input_component.png',
    name: 'settings_input_component',
    category: 0,
  },
  {
    id: 331,
    filename: 'png/action/settings_input_composite.png',
    name: 'settings_input_composite',
    category: 0,
  },
  {
    id: 332,
    filename: 'png/action/settings_input_hdmi.png',
    name: 'settings_input_hdmi',
    category: 0,
  },
  {
    id: 333,
    filename: 'png/action/settings_input_svideo.png',
    name: 'settings_input_svideo',
    category: 0,
  },
  { id: 334, filename: 'png/action/settings_overscan.png', name: 'settings_overscan', category: 0 },
  { id: 335, filename: 'png/action/settings_phone.png', name: 'settings_phone', category: 0 },
  { id: 336, filename: 'png/action/settings_power.png', name: 'settings_power', category: 0 },
  { id: 337, filename: 'png/action/settings_remote.png', name: 'settings_remote', category: 0 },
  { id: 338, filename: 'png/action/settings_voice.png', name: 'settings_voice', category: 0 },
  { id: 339, filename: 'png/action/shop.png', name: 'shop', category: 0 },
  { id: 340, filename: 'png/action/shop_2.png', name: 'shop_2', category: 0 },
  { id: 341, filename: 'png/action/shop_two.png', name: 'shop_two', category: 0 },
  { id: 342, filename: 'png/action/shopping_bag.png', name: 'shopping_bag', category: 0 },
  { id: 343, filename: 'png/action/shopping_basket.png', name: 'shopping_basket', category: 0 },
  { id: 344, filename: 'png/action/shopping_cart.png', name: 'shopping_cart', category: 0 },
  {
    id: 345,
    filename: 'png/action/shopping_cart_checkout.png',
    name: 'shopping_cart_checkout',
    category: 0,
  },
  { id: 346, filename: 'png/action/smart_button.png', name: 'smart_button', category: 0 },
  { id: 347, filename: 'png/action/source.png', name: 'source', category: 0 },
  { id: 348, filename: 'png/action/space_dashboard.png', name: 'space_dashboard', category: 0 },
  { id: 349, filename: 'png/action/spatial_audio.png', name: 'spatial_audio', category: 0 },
  { id: 350, filename: 'png/action/spatial_audio_off.png', name: 'spatial_audio_off', category: 0 },
  { id: 351, filename: 'png/action/spatial_tracking.png', name: 'spatial_tracking', category: 0 },
  { id: 352, filename: 'png/action/speaker_notes.png', name: 'speaker_notes', category: 0 },
  { id: 353, filename: 'png/action/speaker_notes_off.png', name: 'speaker_notes_off', category: 0 },
  { id: 354, filename: 'png/action/spellcheck.png', name: 'spellcheck', category: 0 },
  { id: 355, filename: 'png/action/star_rate.png', name: 'star_rate', category: 0 },
  { id: 356, filename: 'png/action/stars.png', name: 'stars', category: 0 },
  { id: 357, filename: 'png/action/sticky_note_2.png', name: 'sticky_note_2', category: 0 },
  { id: 358, filename: 'png/action/store.png', name: 'store', category: 0 },
  { id: 359, filename: 'png/action/subject.png', name: 'subject', category: 0 },
  { id: 360, filename: 'png/action/subtitles_off.png', name: 'subtitles_off', category: 0 },
  {
    id: 361,
    filename: 'png/action/supervised_user_circle.png',
    name: 'supervised_user_circle',
    category: 0,
  },
  {
    id: 362,
    filename: 'png/action/supervisor_account.png',
    name: 'supervisor_account',
    category: 0,
  },
  { id: 363, filename: 'png/action/support.png', name: 'support', category: 0 },
  { id: 364, filename: 'png/action/swap_horiz.png', name: 'swap_horiz', category: 0 },
  {
    id: 365,
    filename: 'png/action/swap_horizontal_circle.png',
    name: 'swap_horizontal_circle',
    category: 0,
  },
  { id: 366, filename: 'png/action/swap_vert.png', name: 'swap_vert', category: 0 },
  {
    id: 367,
    filename: 'png/action/swap_vertical_circle.png',
    name: 'swap_vertical_circle',
    category: 0,
  },
  { id: 368, filename: 'png/action/swipe.png', name: 'swipe', category: 0 },
  { id: 369, filename: 'png/action/swipe_down.png', name: 'swipe_down', category: 0 },
  { id: 370, filename: 'png/action/swipe_down_alt.png', name: 'swipe_down_alt', category: 0 },
  { id: 371, filename: 'png/action/swipe_left.png', name: 'swipe_left', category: 0 },
  { id: 372, filename: 'png/action/swipe_left_alt.png', name: 'swipe_left_alt', category: 0 },
  { id: 373, filename: 'png/action/swipe_right.png', name: 'swipe_right', category: 0 },
  { id: 374, filename: 'png/action/swipe_right_alt.png', name: 'swipe_right_alt', category: 0 },
  { id: 375, filename: 'png/action/swipe_up.png', name: 'swipe_up', category: 0 },
  { id: 376, filename: 'png/action/swipe_up_alt.png', name: 'swipe_up_alt', category: 0 },
  { id: 377, filename: 'png/action/swipe_vertical.png', name: 'swipe_vertical', category: 0 },
  {
    id: 378,
    filename: 'png/action/switch_access_shortcut.png',
    name: 'switch_access_shortcut',
    category: 0,
  },
  {
    id: 379,
    filename: 'png/action/switch_access_shortcut_add.png',
    name: 'switch_access_shortcut_add',
    category: 0,
  },
  { id: 380, filename: 'png/action/sync_alt.png', name: 'sync_alt', category: 0 },
  { id: 381, filename: 'png/action/system_update_alt.png', name: 'system_update_alt', category: 0 },
  { id: 382, filename: 'png/action/tab.png', name: 'tab', category: 0 },
  { id: 383, filename: 'png/action/tab_unselected.png', name: 'tab_unselected', category: 0 },
  { id: 384, filename: 'png/action/table_view.png', name: 'table_view', category: 0 },
  { id: 385, filename: 'png/action/task_alt.png', name: 'task_alt', category: 0 },
  { id: 386, filename: 'png/action/terminal.png', name: 'terminal', category: 0 },
  { id: 387, filename: 'png/action/text_rotate_up.png', name: 'text_rotate_up', category: 0 },
  {
    id: 388,
    filename: 'png/action/text_rotate_vertical.png',
    name: 'text_rotate_vertical',
    category: 0,
  },
  {
    id: 389,
    filename: 'png/action/text_rotation_angledown.png',
    name: 'text_rotation_angledown',
    category: 0,
  },
  {
    id: 390,
    filename: 'png/action/text_rotation_angleup.png',
    name: 'text_rotation_angleup',
    category: 0,
  },
  {
    id: 391,
    filename: 'png/action/text_rotation_down.png',
    name: 'text_rotation_down',
    category: 0,
  },
  {
    id: 392,
    filename: 'png/action/text_rotation_none.png',
    name: 'text_rotation_none',
    category: 0,
  },
  { id: 393, filename: 'png/action/theaters.png', name: 'theaters', category: 0 },
  { id: 394, filename: 'png/action/thumb_down.png', name: 'thumb_down', category: 0 },
  {
    id: 395,
    filename: 'png/action/thumb_down_off_alt.png',
    name: 'thumb_down_off_alt',
    category: 0,
  },
  { id: 396, filename: 'png/action/thumb_up.png', name: 'thumb_up', category: 0 },
  { id: 397, filename: 'png/action/thumb_up_off_alt.png', name: 'thumb_up_off_alt', category: 0 },
  { id: 398, filename: 'png/action/thumbs_up_down.png', name: 'thumbs_up_down', category: 0 },
  { id: 399, filename: 'png/action/timeline.png', name: 'timeline', category: 0 },
  { id: 400, filename: 'png/action/tips_and_updates.png', name: 'tips_and_updates', category: 0 },
  { id: 401, filename: 'png/action/toc.png', name: 'toc', category: 0 },
  { id: 402, filename: 'png/action/today.png', name: 'today', category: 0 },
  { id: 403, filename: 'png/action/token.png', name: 'token', category: 0 },
  { id: 404, filename: 'png/action/toll.png', name: 'toll', category: 0 },
  { id: 405, filename: 'png/action/touch_app.png', name: 'touch_app', category: 0 },
  { id: 406, filename: 'png/action/tour.png', name: 'tour', category: 0 },
  { id: 407, filename: 'png/action/track_changes.png', name: 'track_changes', category: 0 },
  { id: 408, filename: 'png/action/translate.png', name: 'translate', category: 0 },
  { id: 409, filename: 'png/action/trending_down.png', name: 'trending_down', category: 0 },
  { id: 410, filename: 'png/action/trending_flat.png', name: 'trending_flat', category: 0 },
  { id: 411, filename: 'png/action/trending_up.png', name: 'trending_up', category: 0 },
  { id: 412, filename: 'png/action/try.png', name: 'try', category: 0 },
  { id: 413, filename: 'png/action/turned_in.png', name: 'turned_in', category: 0 },
  { id: 414, filename: 'png/action/turned_in_not.png', name: 'turned_in_not', category: 0 },
  { id: 415, filename: 'png/action/unpublished.png', name: 'unpublished', category: 0 },
  { id: 416, filename: 'png/action/update.png', name: 'update', category: 0 },
  { id: 417, filename: 'png/action/update_disabled.png', name: 'update_disabled', category: 0 },
  { id: 418, filename: 'png/action/upgrade.png', name: 'upgrade', category: 0 },
  { id: 419, filename: 'png/action/verified.png', name: 'verified', category: 0 },
  { id: 420, filename: 'png/action/verified_user.png', name: 'verified_user', category: 0 },
  { id: 421, filename: 'png/action/vertical_split.png', name: 'vertical_split', category: 0 },
  { id: 422, filename: 'png/action/view_agenda.png', name: 'view_agenda', category: 0 },
  { id: 423, filename: 'png/action/view_array.png', name: 'view_array', category: 0 },
  { id: 424, filename: 'png/action/view_carousel.png', name: 'view_carousel', category: 0 },
  { id: 425, filename: 'png/action/view_column.png', name: 'view_column', category: 0 },
  { id: 426, filename: 'png/action/view_comfy_alt.png', name: 'view_comfy_alt', category: 0 },
  { id: 427, filename: 'png/action/view_compact_alt.png', name: 'view_compact_alt', category: 0 },
  { id: 428, filename: 'png/action/view_cozy.png', name: 'view_cozy', category: 0 },
  { id: 429, filename: 'png/action/view_day.png', name: 'view_day', category: 0 },
  { id: 430, filename: 'png/action/view_headline.png', name: 'view_headline', category: 0 },
  { id: 431, filename: 'png/action/view_in_ar.png', name: 'view_in_ar', category: 0 },
  { id: 432, filename: 'png/action/view_kanban.png', name: 'view_kanban', category: 0 },
  { id: 433, filename: 'png/action/view_list.png', name: 'view_list', category: 0 },
  { id: 434, filename: 'png/action/view_module.png', name: 'view_module', category: 0 },
  { id: 435, filename: 'png/action/view_quilt.png', name: 'view_quilt', category: 0 },
  { id: 436, filename: 'png/action/view_sidebar.png', name: 'view_sidebar', category: 0 },
  { id: 437, filename: 'png/action/view_stream.png', name: 'view_stream', category: 0 },
  { id: 438, filename: 'png/action/view_timeline.png', name: 'view_timeline', category: 0 },
  { id: 439, filename: 'png/action/view_week.png', name: 'view_week', category: 0 },
  { id: 440, filename: 'png/action/visibility.png', name: 'visibility', category: 0 },
  { id: 441, filename: 'png/action/visibility_off.png', name: 'visibility_off', category: 0 },
  { id: 442, filename: 'png/action/voice_over_off.png', name: 'voice_over_off', category: 0 },
  { id: 443, filename: 'png/action/watch_later.png', name: 'watch_later', category: 0 },
  { id: 444, filename: 'png/action/webhook.png', name: 'webhook', category: 0 },
  {
    id: 445,
    filename: 'png/action/wifi_protected_setup.png',
    name: 'wifi_protected_setup',
    category: 0,
  },
  { id: 446, filename: 'png/action/work.png', name: 'work', category: 0 },
  { id: 447, filename: 'png/action/work_history.png', name: 'work_history', category: 0 },
  { id: 448, filename: 'png/action/work_off.png', name: 'work_off', category: 0 },
  { id: 449, filename: 'png/action/work_outline.png', name: 'work_outline', category: 0 },
  { id: 450, filename: 'png/action/wysiwyg.png', name: 'wysiwyg', category: 0 },
  {
    id: 451,
    filename: 'png/action/youtube_searched_for.png',
    name: 'youtube_searched_for',
    category: 0,
  },
  { id: 452, filename: 'png/action/zoom_in.png', name: 'zoom_in', category: 0 },
  { id: 453, filename: 'png/action/zoom_out.png', name: 'zoom_out', category: 0 },
  { id: 454, filename: 'png/alert/add_alert.png', name: 'add_alert', category: 1 },
  { id: 455, filename: 'png/alert/auto_delete.png', name: 'auto_delete', category: 1 },
  { id: 456, filename: 'png/alert/error.png', name: 'error', category: 1 },
  { id: 457, filename: 'png/alert/error_outline.png', name: 'error_outline', category: 1 },
  {
    id: 458,
    filename: 'png/alert/notification_important.png',
    name: 'notification_important',
    category: 1,
  },
  { id: 459, filename: 'png/alert/warning.png', name: 'warning', category: 1 },
  { id: 460, filename: 'png/alert/warning_amber.png', name: 'warning_amber', category: 1 },
  { id: 461, filename: 'png/av/10k.png', name: '10k', category: 2 },
  { id: 462, filename: 'png/av/1k.png', name: '1k', category: 2 },
  { id: 463, filename: 'png/av/1k_plus.png', name: '1k_plus', category: 2 },
  { id: 464, filename: 'png/av/2k.png', name: '2k', category: 2 },
  { id: 465, filename: 'png/av/2k_plus.png', name: '2k_plus', category: 2 },
  { id: 466, filename: 'png/av/3k.png', name: '3k', category: 2 },
  { id: 467, filename: 'png/av/3k_plus.png', name: '3k_plus', category: 2 },
  { id: 468, filename: 'png/av/4k.png', name: '4k', category: 2 },
  { id: 469, filename: 'png/av/4k_plus.png', name: '4k_plus', category: 2 },
  { id: 470, filename: 'png/av/5g.png', name: '5g', category: 2 },
  { id: 471, filename: 'png/av/5k.png', name: '5k', category: 2 },
  { id: 472, filename: 'png/av/5k_plus.png', name: '5k_plus', category: 2 },
  { id: 473, filename: 'png/av/6k.png', name: '6k', category: 2 },
  { id: 474, filename: 'png/av/6k_plus.png', name: '6k_plus', category: 2 },
  { id: 475, filename: 'png/av/7k.png', name: '7k', category: 2 },
  { id: 476, filename: 'png/av/7k_plus.png', name: '7k_plus', category: 2 },
  { id: 477, filename: 'png/av/8k.png', name: '8k', category: 2 },
  { id: 478, filename: 'png/av/8k_plus.png', name: '8k_plus', category: 2 },
  { id: 479, filename: 'png/av/9k.png', name: '9k', category: 2 },
  { id: 480, filename: 'png/av/9k_plus.png', name: '9k_plus', category: 2 },
  { id: 481, filename: 'png/av/add_to_queue.png', name: 'add_to_queue', category: 2 },
  { id: 482, filename: 'png/av/airplay.png', name: 'airplay', category: 2 },
  { id: 483, filename: 'png/av/album.png', name: 'album', category: 2 },
  { id: 484, filename: 'png/av/art_track.png', name: 'art_track', category: 2 },
  { id: 485, filename: 'png/av/audio_file.png', name: 'audio_file', category: 2 },
  { id: 486, filename: 'png/av/av_timer.png', name: 'av_timer', category: 2 },
  { id: 487, filename: 'png/av/branding_watermark.png', name: 'branding_watermark', category: 2 },
  { id: 488, filename: 'png/av/call_to_action.png', name: 'call_to_action', category: 2 },
  { id: 489, filename: 'png/av/closed_caption.png', name: 'closed_caption', category: 2 },
  {
    id: 490,
    filename: 'png/av/closed_caption_disabled.png',
    name: 'closed_caption_disabled',
    category: 2,
  },
  { id: 491, filename: 'png/av/closed_caption_off.png', name: 'closed_caption_off', category: 2 },
  { id: 492, filename: 'png/av/control_camera.png', name: 'control_camera', category: 2 },
  { id: 493, filename: 'png/av/equalizer.png', name: 'equalizer', category: 2 },
  { id: 494, filename: 'png/av/explicit.png', name: 'explicit', category: 2 },
  { id: 495, filename: 'png/av/fast_forward.png', name: 'fast_forward', category: 2 },
  { id: 496, filename: 'png/av/fast_rewind.png', name: 'fast_rewind', category: 2 },
  { id: 497, filename: 'png/av/featured_play_list.png', name: 'featured_play_list', category: 2 },
  { id: 498, filename: 'png/av/featured_video.png', name: 'featured_video', category: 2 },
  { id: 499, filename: 'png/av/fiber_dvr.png', name: 'fiber_dvr', category: 2 },
  { id: 500, filename: 'png/av/fiber_manual_record.png', name: 'fiber_manual_record', category: 2 },
  { id: 501, filename: 'png/av/fiber_new.png', name: 'fiber_new', category: 2 },
  { id: 502, filename: 'png/av/fiber_pin.png', name: 'fiber_pin', category: 2 },
  { id: 503, filename: 'png/av/fiber_smart_record.png', name: 'fiber_smart_record', category: 2 },
  { id: 504, filename: 'png/av/forward_10.png', name: 'forward_10', category: 2 },
  { id: 505, filename: 'png/av/forward_30.png', name: 'forward_30', category: 2 },
  { id: 506, filename: 'png/av/forward_5.png', name: 'forward_5', category: 2 },
  { id: 507, filename: 'png/av/games.png', name: 'games', category: 2 },
  { id: 508, filename: 'png/av/hd.png', name: 'hd', category: 2 },
  { id: 509, filename: 'png/av/hearing.png', name: 'hearing', category: 2 },
  { id: 510, filename: 'png/av/hearing_disabled.png', name: 'hearing_disabled', category: 2 },
  { id: 511, filename: 'png/av/high_quality.png', name: 'high_quality', category: 2 },
  { id: 512, filename: 'png/av/interpreter_mode.png', name: 'interpreter_mode', category: 2 },
  { id: 513, filename: 'png/av/library_add.png', name: 'library_add', category: 2 },
  { id: 514, filename: 'png/av/library_add_check.png', name: 'library_add_check', category: 2 },
  { id: 515, filename: 'png/av/library_books.png', name: 'library_books', category: 2 },
  { id: 516, filename: 'png/av/library_music.png', name: 'library_music', category: 2 },
  { id: 517, filename: 'png/av/loop.png', name: 'loop', category: 2 },
  { id: 518, filename: 'png/av/lyrics.png', name: 'lyrics', category: 2 },
  { id: 519, filename: 'png/av/mic.png', name: 'mic', category: 2 },
  { id: 520, filename: 'png/av/mic_none.png', name: 'mic_none', category: 2 },
  { id: 521, filename: 'png/av/mic_off.png', name: 'mic_off', category: 2 },
  { id: 522, filename: 'png/av/missed_video_call.png', name: 'missed_video_call', category: 2 },
  { id: 523, filename: 'png/av/movie.png', name: 'movie', category: 2 },
  { id: 524, filename: 'png/av/music_video.png', name: 'music_video', category: 2 },
  { id: 525, filename: 'png/av/new_releases.png', name: 'new_releases', category: 2 },
  { id: 526, filename: 'png/av/not_interested.png', name: 'not_interested', category: 2 },
  { id: 527, filename: 'png/av/note.png', name: 'note', category: 2 },
  { id: 528, filename: 'png/av/pause.png', name: 'pause', category: 2 },
  { id: 529, filename: 'png/av/pause_circle.png', name: 'pause_circle', category: 2 },
  { id: 530, filename: 'png/av/pause_circle_filled.png', name: 'pause_circle_filled', category: 2 },
  {
    id: 531,
    filename: 'png/av/pause_circle_outline.png',
    name: 'pause_circle_outline',
    category: 2,
  },
  { id: 532, filename: 'png/av/play_arrow.png', name: 'play_arrow', category: 2 },
  { id: 533, filename: 'png/av/play_circle.png', name: 'play_circle', category: 2 },
  { id: 534, filename: 'png/av/play_circle_filled.png', name: 'play_circle_filled', category: 2 },
  { id: 535, filename: 'png/av/play_circle_outline.png', name: 'play_circle_outline', category: 2 },
  { id: 536, filename: 'png/av/play_disabled.png', name: 'play_disabled', category: 2 },
  { id: 537, filename: 'png/av/playlist_add.png', name: 'playlist_add', category: 2 },
  { id: 538, filename: 'png/av/playlist_add_check.png', name: 'playlist_add_check', category: 2 },
  {
    id: 539,
    filename: 'png/av/playlist_add_check_circle.png',
    name: 'playlist_add_check_circle',
    category: 2,
  },
  { id: 540, filename: 'png/av/playlist_add_circle.png', name: 'playlist_add_circle', category: 2 },
  { id: 541, filename: 'png/av/playlist_play.png', name: 'playlist_play', category: 2 },
  { id: 542, filename: 'png/av/playlist_remove.png', name: 'playlist_remove', category: 2 },
  { id: 543, filename: 'png/av/queue.png', name: 'queue', category: 2 },
  { id: 544, filename: 'png/av/queue_music.png', name: 'queue_music', category: 2 },
  { id: 545, filename: 'png/av/queue_play_next.png', name: 'queue_play_next', category: 2 },
  { id: 546, filename: 'png/av/radio.png', name: 'radio', category: 2 },
  { id: 547, filename: 'png/av/recent_actors.png', name: 'recent_actors', category: 2 },
  { id: 548, filename: 'png/av/remove_from_queue.png', name: 'remove_from_queue', category: 2 },
  { id: 549, filename: 'png/av/repeat.png', name: 'repeat', category: 2 },
  { id: 550, filename: 'png/av/repeat_on.png', name: 'repeat_on', category: 2 },
  { id: 551, filename: 'png/av/repeat_one.png', name: 'repeat_one', category: 2 },
  { id: 552, filename: 'png/av/repeat_one_on.png', name: 'repeat_one_on', category: 2 },
  { id: 553, filename: 'png/av/replay.png', name: 'replay', category: 2 },
  { id: 554, filename: 'png/av/replay_10.png', name: 'replay_10', category: 2 },
  { id: 555, filename: 'png/av/replay_30.png', name: 'replay_30', category: 2 },
  { id: 556, filename: 'png/av/replay_5.png', name: 'replay_5', category: 2 },
  {
    id: 557,
    filename: 'png/av/replay_circle_filled.png',
    name: 'replay_circle_filled',
    category: 2,
  },
  { id: 558, filename: 'png/av/sd.png', name: 'sd', category: 2 },
  { id: 559, filename: 'png/av/shuffle.png', name: 'shuffle', category: 2 },
  { id: 560, filename: 'png/av/shuffle_on.png', name: 'shuffle_on', category: 2 },
  { id: 561, filename: 'png/av/skip_next.png', name: 'skip_next', category: 2 },
  { id: 562, filename: 'png/av/skip_previous.png', name: 'skip_previous', category: 2 },
  { id: 563, filename: 'png/av/slow_motion_video.png', name: 'slow_motion_video', category: 2 },
  { id: 564, filename: 'png/av/snooze.png', name: 'snooze', category: 2 },
  { id: 565, filename: 'png/av/sort_by_alpha.png', name: 'sort_by_alpha', category: 2 },
  { id: 566, filename: 'png/av/speed.png', name: 'speed', category: 2 },
  { id: 567, filename: 'png/av/stop.png', name: 'stop', category: 2 },
  { id: 568, filename: 'png/av/stop_circle.png', name: 'stop_circle', category: 2 },
  { id: 569, filename: 'png/av/subscriptions.png', name: 'subscriptions', category: 2 },
  { id: 570, filename: 'png/av/subtitles.png', name: 'subtitles', category: 2 },
  { id: 571, filename: 'png/av/surround_sound.png', name: 'surround_sound', category: 2 },
  { id: 572, filename: 'png/av/video_call.png', name: 'video_call', category: 2 },
  { id: 573, filename: 'png/av/video_file.png', name: 'video_file', category: 2 },
  { id: 574, filename: 'png/av/video_label.png', name: 'video_label', category: 2 },
  { id: 575, filename: 'png/av/video_library.png', name: 'video_library', category: 2 },
  { id: 576, filename: 'png/av/video_settings.png', name: 'video_settings', category: 2 },
  { id: 577, filename: 'png/av/videocam.png', name: 'videocam', category: 2 },
  { id: 578, filename: 'png/av/videocam_off.png', name: 'videocam_off', category: 2 },
  { id: 579, filename: 'png/av/volume_down.png', name: 'volume_down', category: 2 },
  { id: 580, filename: 'png/av/volume_down_alt.png', name: 'volume_down_alt', category: 2 },
  { id: 581, filename: 'png/av/volume_mute.png', name: 'volume_mute', category: 2 },
  { id: 582, filename: 'png/av/volume_off.png', name: 'volume_off', category: 2 },
  { id: 583, filename: 'png/av/volume_up.png', name: 'volume_up', category: 2 },
  { id: 584, filename: 'png/av/web.png', name: 'web', category: 2 },
  { id: 585, filename: 'png/av/web_asset.png', name: 'web_asset', category: 2 },
  { id: 586, filename: 'png/av/web_asset_off.png', name: 'web_asset_off', category: 2 },
  { id: 587, filename: 'png/communication/3p.png', name: '3p', category: 3 },
  { id: 588, filename: 'png/communication/add_ic_call.png', name: 'add_ic_call', category: 3 },
  {
    id: 589,
    filename: 'png/communication/alternate_email.png',
    name: 'alternate_email',
    category: 3,
  },
  {
    id: 590,
    filename: 'png/communication/app_registration.png',
    name: 'app_registration',
    category: 3,
  },
  { id: 591, filename: 'png/communication/business.png', name: 'business', category: 3 },
  { id: 592, filename: 'png/communication/call.png', name: 'call', category: 3 },
  { id: 593, filename: 'png/communication/call_end.png', name: 'call_end', category: 3 },
  { id: 594, filename: 'png/communication/call_made.png', name: 'call_made', category: 3 },
  { id: 595, filename: 'png/communication/call_merge.png', name: 'call_merge', category: 3 },
  { id: 596, filename: 'png/communication/call_missed.png', name: 'call_missed', category: 3 },
  {
    id: 597,
    filename: 'png/communication/call_missed_outgoing.png',
    name: 'call_missed_outgoing',
    category: 3,
  },
  { id: 598, filename: 'png/communication/call_received.png', name: 'call_received', category: 3 },
  { id: 599, filename: 'png/communication/call_split.png', name: 'call_split', category: 3 },
  {
    id: 600,
    filename: 'png/communication/cancel_presentation.png',
    name: 'cancel_presentation',
    category: 3,
  },
  { id: 601, filename: 'png/communication/cell_tower.png', name: 'cell_tower', category: 3 },
  { id: 602, filename: 'png/communication/cell_wifi.png', name: 'cell_wifi', category: 3 },
  { id: 603, filename: 'png/communication/chat.png', name: 'chat', category: 3 },
  { id: 604, filename: 'png/communication/chat_bubble.png', name: 'chat_bubble', category: 3 },
  {
    id: 605,
    filename: 'png/communication/chat_bubble_outline.png',
    name: 'chat_bubble_outline',
    category: 3,
  },
  { id: 606, filename: 'png/communication/clear_all.png', name: 'clear_all', category: 3 },
  { id: 607, filename: 'png/communication/co_present.png', name: 'co_present', category: 3 },
  { id: 608, filename: 'png/communication/comment.png', name: 'comment', category: 3 },
  {
    id: 609,
    filename: 'png/communication/comments_disabled.png',
    name: 'comments_disabled',
    category: 3,
  },
  { id: 610, filename: 'png/communication/contact_mail.png', name: 'contact_mail', category: 3 },
  { id: 611, filename: 'png/communication/contact_phone.png', name: 'contact_phone', category: 3 },
  { id: 612, filename: 'png/communication/contacts.png', name: 'contacts', category: 3 },
  {
    id: 613,
    filename: 'png/communication/desktop_access_disabled.png',
    name: 'desktop_access_disabled',
    category: 3,
  },
  { id: 614, filename: 'png/communication/dialer_sip.png', name: 'dialer_sip', category: 3 },
  { id: 615, filename: 'png/communication/dialpad.png', name: 'dialpad', category: 3 },
  {
    id: 616,
    filename: 'png/communication/document_scanner.png',
    name: 'document_scanner',
    category: 3,
  },
  {
    id: 617,
    filename: 'png/communication/domain_disabled.png',
    name: 'domain_disabled',
    category: 3,
  },
  {
    id: 618,
    filename: 'png/communication/domain_verification.png',
    name: 'domain_verification',
    category: 3,
  },
  { id: 619, filename: 'png/communication/duo.png', name: 'duo', category: 3 },
  { id: 620, filename: 'png/communication/email.png', name: 'email', category: 3 },
  { id: 621, filename: 'png/communication/forum.png', name: 'forum', category: 3 },
  {
    id: 622,
    filename: 'png/communication/forward_to_inbox.png',
    name: 'forward_to_inbox',
    category: 3,
  },
  {
    id: 623,
    filename: 'png/communication/hourglass_bottom.png',
    name: 'hourglass_bottom',
    category: 3,
  },
  { id: 624, filename: 'png/communication/hourglass_top.png', name: 'hourglass_top', category: 3 },
  { id: 625, filename: 'png/communication/hub.png', name: 'hub', category: 3 },
  {
    id: 626,
    filename: 'png/communication/import_contacts.png',
    name: 'import_contacts',
    category: 3,
  },
  { id: 627, filename: 'png/communication/import_export.png', name: 'import_export', category: 3 },
  {
    id: 628,
    filename: 'png/communication/invert_colors_off.png',
    name: 'invert_colors_off',
    category: 3,
  },
  { id: 629, filename: 'png/communication/key.png', name: 'key', category: 3 },
  { id: 630, filename: 'png/communication/key_off.png', name: 'key_off', category: 3 },
  { id: 631, filename: 'png/communication/list_alt.png', name: 'list_alt', category: 3 },
  { id: 632, filename: 'png/communication/live_help.png', name: 'live_help', category: 3 },
  { id: 633, filename: 'png/communication/location_off.png', name: 'location_off', category: 3 },
  { id: 634, filename: 'png/communication/location_on.png', name: 'location_on', category: 3 },
  { id: 635, filename: 'png/communication/mail_lock.png', name: 'mail_lock', category: 3 },
  { id: 636, filename: 'png/communication/mail_outline.png', name: 'mail_outline', category: 3 },
  {
    id: 637,
    filename: 'png/communication/mark_chat_read.png',
    name: 'mark_chat_read',
    category: 3,
  },
  {
    id: 638,
    filename: 'png/communication/mark_chat_unread.png',
    name: 'mark_chat_unread',
    category: 3,
  },
  {
    id: 639,
    filename: 'png/communication/mark_email_read.png',
    name: 'mark_email_read',
    category: 3,
  },
  {
    id: 640,
    filename: 'png/communication/mark_email_unread.png',
    name: 'mark_email_unread',
    category: 3,
  },
  {
    id: 641,
    filename: 'png/communication/mark_unread_chat_alt.png',
    name: 'mark_unread_chat_alt',
    category: 3,
  },
  { id: 642, filename: 'png/communication/message.png', name: 'message', category: 3 },
  {
    id: 643,
    filename: 'png/communication/mobile_screen_share.png',
    name: 'mobile_screen_share',
    category: 3,
  },
  { id: 644, filename: 'png/communication/more_time.png', name: 'more_time', category: 3 },
  { id: 645, filename: 'png/communication/nat.png', name: 'nat', category: 3 },
  { id: 646, filename: 'png/communication/no_sim.png', name: 'no_sim', category: 3 },
  {
    id: 647,
    filename: 'png/communication/pause_presentation.png',
    name: 'pause_presentation',
    category: 3,
  },
  {
    id: 648,
    filename: 'png/communication/person_add_disabled.png',
    name: 'person_add_disabled',
    category: 3,
  },
  { id: 649, filename: 'png/communication/person_search.png', name: 'person_search', category: 3 },
  { id: 650, filename: 'png/communication/phone.png', name: 'phone', category: 3 },
  {
    id: 651,
    filename: 'png/communication/phone_disabled.png',
    name: 'phone_disabled',
    category: 3,
  },
  { id: 652, filename: 'png/communication/phone_enabled.png', name: 'phone_enabled', category: 3 },
  {
    id: 653,
    filename: 'png/communication/phonelink_erase.png',
    name: 'phonelink_erase',
    category: 3,
  },
  {
    id: 654,
    filename: 'png/communication/phonelink_lock.png',
    name: 'phonelink_lock',
    category: 3,
  },
  {
    id: 655,
    filename: 'png/communication/phonelink_ring.png',
    name: 'phonelink_ring',
    category: 3,
  },
  {
    id: 656,
    filename: 'png/communication/phonelink_setup.png',
    name: 'phonelink_setup',
    category: 3,
  },
  {
    id: 657,
    filename: 'png/communication/portable_wifi_off.png',
    name: 'portable_wifi_off',
    category: 3,
  },
  {
    id: 658,
    filename: 'png/communication/present_to_all.png',
    name: 'present_to_all',
    category: 3,
  },
  {
    id: 659,
    filename: 'png/communication/print_disabled.png',
    name: 'print_disabled',
    category: 3,
  },
  { id: 660, filename: 'png/communication/qr_code.png', name: 'qr_code', category: 3 },
  { id: 661, filename: 'png/communication/qr_code_2.png', name: 'qr_code_2', category: 3 },
  {
    id: 662,
    filename: 'png/communication/qr_code_scanner.png',
    name: 'qr_code_scanner',
    category: 3,
  },
  { id: 663, filename: 'png/communication/read_more.png', name: 'read_more', category: 3 },
  { id: 664, filename: 'png/communication/ring_volume.png', name: 'ring_volume', category: 3 },
  { id: 665, filename: 'png/communication/rss_feed.png', name: 'rss_feed', category: 3 },
  { id: 666, filename: 'png/communication/rtt.png', name: 'rtt', category: 3 },
  { id: 667, filename: 'png/communication/screen_share.png', name: 'screen_share', category: 3 },
  {
    id: 668,
    filename: 'png/communication/send_time_extension.png',
    name: 'send_time_extension',
    category: 3,
  },
  {
    id: 669,
    filename: 'png/communication/sentiment_satisfied_alt.png',
    name: 'sentiment_satisfied_alt',
    category: 3,
  },
  { id: 670, filename: 'png/communication/sip.png', name: 'sip', category: 3 },
  { id: 671, filename: 'png/communication/speaker_phone.png', name: 'speaker_phone', category: 3 },
  { id: 672, filename: 'png/communication/spoke.png', name: 'spoke', category: 3 },
  {
    id: 673,
    filename: 'png/communication/stay_current_landscape.png',
    name: 'stay_current_landscape',
    category: 3,
  },
  {
    id: 674,
    filename: 'png/communication/stay_current_portrait.png',
    name: 'stay_current_portrait',
    category: 3,
  },
  {
    id: 675,
    filename: 'png/communication/stay_primary_landscape.png',
    name: 'stay_primary_landscape',
    category: 3,
  },
  {
    id: 676,
    filename: 'png/communication/stay_primary_portrait.png',
    name: 'stay_primary_portrait',
    category: 3,
  },
  {
    id: 677,
    filename: 'png/communication/stop_screen_share.png',
    name: 'stop_screen_share',
    category: 3,
  },
  { id: 678, filename: 'png/communication/swap_calls.png', name: 'swap_calls', category: 3 },
  { id: 679, filename: 'png/communication/textsms.png', name: 'textsms', category: 3 },
  { id: 680, filename: 'png/communication/unsubscribe.png', name: 'unsubscribe', category: 3 },
  { id: 681, filename: 'png/communication/voicemail.png', name: 'voicemail', category: 3 },
  { id: 682, filename: 'png/communication/vpn_key.png', name: 'vpn_key', category: 3 },
  { id: 683, filename: 'png/communication/vpn_key_off.png', name: 'vpn_key_off', category: 3 },
  { id: 684, filename: 'png/communication/wifi_calling.png', name: 'wifi_calling', category: 3 },
  { id: 685, filename: 'png/content/add.png', name: 'add', category: 4 },
  { id: 686, filename: 'png/content/add_box.png', name: 'add_box', category: 4 },
  { id: 687, filename: 'png/content/add_circle.png', name: 'add_circle', category: 4 },
  {
    id: 688,
    filename: 'png/content/add_circle_outline.png',
    name: 'add_circle_outline',
    category: 4,
  },
  { id: 689, filename: 'png/content/add_link.png', name: 'add_link', category: 4 },
  { id: 690, filename: 'png/content/amp_stories.png', name: 'amp_stories', category: 4 },
  { id: 691, filename: 'png/content/archive.png', name: 'archive', category: 4 },
  { id: 692, filename: 'png/content/attribution.png', name: 'attribution', category: 4 },
  { id: 693, filename: 'png/content/backspace.png', name: 'backspace', category: 4 },
  { id: 694, filename: 'png/content/ballot.png', name: 'ballot', category: 4 },
  { id: 695, filename: 'png/content/biotech.png', name: 'biotech', category: 4 },
  { id: 696, filename: 'png/content/block.png', name: 'block', category: 4 },
  { id: 697, filename: 'png/content/block_flipped.png', name: 'block_flipped', category: 4 },
  { id: 698, filename: 'png/content/bolt.png', name: 'bolt', category: 4 },
  { id: 699, filename: 'png/content/calculate.png', name: 'calculate', category: 4 },
  { id: 700, filename: 'png/content/change_circle.png', name: 'change_circle', category: 4 },
  { id: 701, filename: 'png/content/clear.png', name: 'clear', category: 4 },
  { id: 702, filename: 'png/content/content_copy.png', name: 'content_copy', category: 4 },
  { id: 703, filename: 'png/content/content_cut.png', name: 'content_cut', category: 4 },
  { id: 704, filename: 'png/content/content_paste.png', name: 'content_paste', category: 4 },
  { id: 705, filename: 'png/content/content_paste_go.png', name: 'content_paste_go', category: 4 },
  {
    id: 706,
    filename: 'png/content/content_paste_off.png',
    name: 'content_paste_off',
    category: 4,
  },
  {
    id: 707,
    filename: 'png/content/content_paste_search.png',
    name: 'content_paste_search',
    category: 4,
  },
  { id: 708, filename: 'png/content/copy_all.png', name: 'copy_all', category: 4 },
  { id: 709, filename: 'png/content/create.png', name: 'create', category: 4 },
  { id: 710, filename: 'png/content/delete_sweep.png', name: 'delete_sweep', category: 4 },
  { id: 711, filename: 'png/content/deselect.png', name: 'deselect', category: 4 },
  { id: 712, filename: 'png/content/drafts.png', name: 'drafts', category: 4 },
  { id: 713, filename: 'png/content/dynamic_feed.png', name: 'dynamic_feed', category: 4 },
  { id: 714, filename: 'png/content/file_copy.png', name: 'file_copy', category: 4 },
  { id: 715, filename: 'png/content/filter_list.png', name: 'filter_list', category: 4 },
  { id: 716, filename: 'png/content/filter_list_off.png', name: 'filter_list_off', category: 4 },
  { id: 717, filename: 'png/content/flag.png', name: 'flag', category: 4 },
  { id: 718, filename: 'png/content/flag_circle.png', name: 'flag_circle', category: 4 },
  { id: 719, filename: 'png/content/font_download.png', name: 'font_download', category: 4 },
  {
    id: 720,
    filename: 'png/content/font_download_off.png',
    name: 'font_download_off',
    category: 4,
  },
  { id: 721, filename: 'png/content/forward.png', name: 'forward', category: 4 },
  { id: 722, filename: 'png/content/gesture.png', name: 'gesture', category: 4 },
  { id: 723, filename: 'png/content/how_to_reg.png', name: 'how_to_reg', category: 4 },
  { id: 724, filename: 'png/content/how_to_vote.png', name: 'how_to_vote', category: 4 },
  { id: 725, filename: 'png/content/inbox.png', name: 'inbox', category: 4 },
  { id: 726, filename: 'png/content/insights.png', name: 'insights', category: 4 },
  { id: 727, filename: 'png/content/inventory.png', name: 'inventory', category: 4 },
  { id: 728, filename: 'png/content/inventory_2.png', name: 'inventory_2', category: 4 },
  { id: 729, filename: 'png/content/link.png', name: 'link', category: 4 },
  { id: 730, filename: 'png/content/link_off.png', name: 'link_off', category: 4 },
  { id: 731, filename: 'png/content/low_priority.png', name: 'low_priority', category: 4 },
  { id: 732, filename: 'png/content/mail.png', name: 'mail', category: 4 },
  { id: 733, filename: 'png/content/markunread.png', name: 'markunread', category: 4 },
  { id: 734, filename: 'png/content/move_to_inbox.png', name: 'move_to_inbox', category: 4 },
  { id: 735, filename: 'png/content/next_week.png', name: 'next_week', category: 4 },
  { id: 736, filename: 'png/content/outlined_flag.png', name: 'outlined_flag', category: 4 },
  { id: 737, filename: 'png/content/policy.png', name: 'policy', category: 4 },
  { id: 738, filename: 'png/content/push_pin.png', name: 'push_pin', category: 4 },
  { id: 739, filename: 'png/content/redo.png', name: 'redo', category: 4 },
  { id: 740, filename: 'png/content/remove.png', name: 'remove', category: 4 },
  { id: 741, filename: 'png/content/remove_circle.png', name: 'remove_circle', category: 4 },
  {
    id: 742,
    filename: 'png/content/remove_circle_outline.png',
    name: 'remove_circle_outline',
    category: 4,
  },
  { id: 743, filename: 'png/content/reply.png', name: 'reply', category: 4 },
  { id: 744, filename: 'png/content/reply_all.png', name: 'reply_all', category: 4 },
  { id: 745, filename: 'png/content/report.png', name: 'report', category: 4 },
  {
    id: 746,
    filename: 'png/content/report_gmailerrorred.png',
    name: 'report_gmailerrorred',
    category: 4,
  },
  { id: 747, filename: 'png/content/report_off.png', name: 'report_off', category: 4 },
  { id: 748, filename: 'png/content/save.png', name: 'save', category: 4 },
  { id: 749, filename: 'png/content/save_alt.png', name: 'save_alt', category: 4 },
  { id: 750, filename: 'png/content/save_as.png', name: 'save_as', category: 4 },
  { id: 751, filename: 'png/content/select_all.png', name: 'select_all', category: 4 },
  { id: 752, filename: 'png/content/send.png', name: 'send', category: 4 },
  { id: 753, filename: 'png/content/shield.png', name: 'shield', category: 4 },
  { id: 754, filename: 'png/content/sort.png', name: 'sort', category: 4 },
  { id: 755, filename: 'png/content/square_foot.png', name: 'square_foot', category: 4 },
  {
    id: 756,
    filename: 'png/content/stacked_bar_chart.png',
    name: 'stacked_bar_chart',
    category: 4,
  },
  { id: 757, filename: 'png/content/stream.png', name: 'stream', category: 4 },
  { id: 758, filename: 'png/content/tag.png', name: 'tag', category: 4 },
  { id: 759, filename: 'png/content/text_format.png', name: 'text_format', category: 4 },
  { id: 760, filename: 'png/content/unarchive.png', name: 'unarchive', category: 4 },
  { id: 761, filename: 'png/content/undo.png', name: 'undo', category: 4 },
  { id: 762, filename: 'png/content/upcoming.png', name: 'upcoming', category: 4 },
  { id: 763, filename: 'png/content/waves.png', name: 'waves', category: 4 },
  { id: 764, filename: 'png/content/web_stories.png', name: 'web_stories', category: 4 },
  { id: 765, filename: 'png/content/weekend.png', name: 'weekend', category: 4 },
  { id: 766, filename: 'png/content/where_to_vote.png', name: 'where_to_vote', category: 4 },
  { id: 767, filename: 'png/device/1x_mobiledata.png', name: '1x_mobiledata', category: 5 },
  { id: 768, filename: 'png/device/30fps.png', name: '30fps', category: 5 },
  { id: 769, filename: 'png/device/3g_mobiledata.png', name: '3g_mobiledata', category: 5 },
  { id: 770, filename: 'png/device/4g_mobiledata.png', name: '4g_mobiledata', category: 5 },
  {
    id: 771,
    filename: 'png/device/4g_plus_mobiledata.png',
    name: '4g_plus_mobiledata',
    category: 5,
  },
  { id: 772, filename: 'png/device/60fps.png', name: '60fps', category: 5 },
  { id: 773, filename: 'png/device/access_alarm.png', name: 'access_alarm', category: 5 },
  { id: 774, filename: 'png/device/access_alarms.png', name: 'access_alarms', category: 5 },
  { id: 775, filename: 'png/device/access_time.png', name: 'access_time', category: 5 },
  {
    id: 776,
    filename: 'png/device/access_time_filled.png',
    name: 'access_time_filled',
    category: 5,
  },
  { id: 777, filename: 'png/device/ad_units.png', name: 'ad_units', category: 5 },
  { id: 778, filename: 'png/device/add_alarm.png', name: 'add_alarm', category: 5 },
  {
    id: 779,
    filename: 'png/device/add_to_home_screen.png',
    name: 'add_to_home_screen',
    category: 5,
  },
  { id: 780, filename: 'png/device/air.png', name: 'air', category: 5 },
  { id: 781, filename: 'png/device/airplane_ticket.png', name: 'airplane_ticket', category: 5 },
  {
    id: 782,
    filename: 'png/device/airplanemode_active.png',
    name: 'airplanemode_active',
    category: 5,
  },
  {
    id: 783,
    filename: 'png/device/airplanemode_inactive.png',
    name: 'airplanemode_inactive',
    category: 5,
  },
  { id: 784, filename: 'png/device/aod.png', name: 'aod', category: 5 },
  { id: 785, filename: 'png/device/battery_0_bar.png', name: 'battery_0_bar', category: 5 },
  { id: 786, filename: 'png/device/battery_1_bar.png', name: 'battery_1_bar', category: 5 },
  { id: 787, filename: 'png/device/battery_20.png', name: 'battery_20', category: 5 },
  { id: 788, filename: 'png/device/battery_2_bar.png', name: 'battery_2_bar', category: 5 },
  { id: 789, filename: 'png/device/battery_30.png', name: 'battery_30', category: 5 },
  { id: 790, filename: 'png/device/battery_3_bar.png', name: 'battery_3_bar', category: 5 },
  { id: 791, filename: 'png/device/battery_4_bar.png', name: 'battery_4_bar', category: 5 },
  { id: 792, filename: 'png/device/battery_50.png', name: 'battery_50', category: 5 },
  { id: 793, filename: 'png/device/battery_5_bar.png', name: 'battery_5_bar', category: 5 },
  { id: 794, filename: 'png/device/battery_60.png', name: 'battery_60', category: 5 },
  { id: 795, filename: 'png/device/battery_6_bar.png', name: 'battery_6_bar', category: 5 },
  { id: 796, filename: 'png/device/battery_80.png', name: 'battery_80', category: 5 },
  { id: 797, filename: 'png/device/battery_90.png', name: 'battery_90', category: 5 },
  { id: 798, filename: 'png/device/battery_alert.png', name: 'battery_alert', category: 5 },
  {
    id: 799,
    filename: 'png/device/battery_charging_20.png',
    name: 'battery_charging_20',
    category: 5,
  },
  {
    id: 800,
    filename: 'png/device/battery_charging_30.png',
    name: 'battery_charging_30',
    category: 5,
  },
  {
    id: 801,
    filename: 'png/device/battery_charging_50.png',
    name: 'battery_charging_50',
    category: 5,
  },
  {
    id: 802,
    filename: 'png/device/battery_charging_60.png',
    name: 'battery_charging_60',
    category: 5,
  },
  {
    id: 803,
    filename: 'png/device/battery_charging_80.png',
    name: 'battery_charging_80',
    category: 5,
  },
  {
    id: 804,
    filename: 'png/device/battery_charging_90.png',
    name: 'battery_charging_90',
    category: 5,
  },
  {
    id: 805,
    filename: 'png/device/battery_charging_full.png',
    name: 'battery_charging_full',
    category: 5,
  },
  { id: 806, filename: 'png/device/battery_full.png', name: 'battery_full', category: 5 },
  { id: 807, filename: 'png/device/battery_saver.png', name: 'battery_saver', category: 5 },
  { id: 808, filename: 'png/device/battery_std.png', name: 'battery_std', category: 5 },
  { id: 809, filename: 'png/device/battery_unknown.png', name: 'battery_unknown', category: 5 },
  { id: 810, filename: 'png/device/bloodtype.png', name: 'bloodtype', category: 5 },
  { id: 811, filename: 'png/device/bluetooth.png', name: 'bluetooth', category: 5 },
  {
    id: 812,
    filename: 'png/device/bluetooth_connected.png',
    name: 'bluetooth_connected',
    category: 5,
  },
  {
    id: 813,
    filename: 'png/device/bluetooth_disabled.png',
    name: 'bluetooth_disabled',
    category: 5,
  },
  { id: 814, filename: 'png/device/bluetooth_drive.png', name: 'bluetooth_drive', category: 5 },
  {
    id: 815,
    filename: 'png/device/bluetooth_searching.png',
    name: 'bluetooth_searching',
    category: 5,
  },
  { id: 816, filename: 'png/device/brightness_auto.png', name: 'brightness_auto', category: 5 },
  { id: 817, filename: 'png/device/brightness_high.png', name: 'brightness_high', category: 5 },
  { id: 818, filename: 'png/device/brightness_low.png', name: 'brightness_low', category: 5 },
  { id: 819, filename: 'png/device/brightness_medium.png', name: 'brightness_medium', category: 5 },
  { id: 820, filename: 'png/device/cable.png', name: 'cable', category: 5 },
  { id: 821, filename: 'png/device/cameraswitch.png', name: 'cameraswitch', category: 5 },
  { id: 822, filename: 'png/device/credit_score.png', name: 'credit_score', category: 5 },
  { id: 823, filename: 'png/device/dark_mode.png', name: 'dark_mode', category: 5 },
  { id: 824, filename: 'png/device/data_saver_off.png', name: 'data_saver_off', category: 5 },
  { id: 825, filename: 'png/device/data_saver_on.png', name: 'data_saver_on', category: 5 },
  { id: 826, filename: 'png/device/data_usage.png', name: 'data_usage', category: 5 },
  { id: 827, filename: 'png/device/developer_mode.png', name: 'developer_mode', category: 5 },
  { id: 828, filename: 'png/device/device_thermostat.png', name: 'device_thermostat', category: 5 },
  { id: 829, filename: 'png/device/devices.png', name: 'devices', category: 5 },
  { id: 830, filename: 'png/device/devices_fold.png', name: 'devices_fold', category: 5 },
  { id: 831, filename: 'png/device/discount.png', name: 'discount', category: 5 },
  {
    id: 832,
    filename: 'png/device/do_not_disturb_on_total_silence.png',
    name: 'do_not_disturb_on_total_silence',
    category: 5,
  },
  { id: 833, filename: 'png/device/dvr.png', name: 'dvr', category: 5 },
  { id: 834, filename: 'png/device/e_mobiledata.png', name: 'e_mobiledata', category: 5 },
  { id: 835, filename: 'png/device/edgesensor_high.png', name: 'edgesensor_high', category: 5 },
  { id: 836, filename: 'png/device/edgesensor_low.png', name: 'edgesensor_low', category: 5 },
  { id: 837, filename: 'png/device/flashlight_off.png', name: 'flashlight_off', category: 5 },
  { id: 838, filename: 'png/device/flashlight_on.png', name: 'flashlight_on', category: 5 },
  { id: 839, filename: 'png/device/flourescent.png', name: 'flourescent', category: 5 },
  { id: 840, filename: 'png/device/fmd_bad.png', name: 'fmd_bad', category: 5 },
  { id: 841, filename: 'png/device/fmd_good.png', name: 'fmd_good', category: 5 },
  { id: 842, filename: 'png/device/g_mobiledata.png', name: 'g_mobiledata', category: 5 },
  { id: 843, filename: 'png/device/gpp_bad.png', name: 'gpp_bad', category: 5 },
  { id: 844, filename: 'png/device/gpp_good.png', name: 'gpp_good', category: 5 },
  { id: 845, filename: 'png/device/gpp_maybe.png', name: 'gpp_maybe', category: 5 },
  { id: 846, filename: 'png/device/gps_fixed.png', name: 'gps_fixed', category: 5 },
  { id: 847, filename: 'png/device/gps_not_fixed.png', name: 'gps_not_fixed', category: 5 },
  { id: 848, filename: 'png/device/gps_off.png', name: 'gps_off', category: 5 },
  { id: 849, filename: 'png/device/graphic_eq.png', name: 'graphic_eq', category: 5 },
  { id: 850, filename: 'png/device/grid_3x3.png', name: 'grid_3x3', category: 5 },
  { id: 851, filename: 'png/device/grid_4x4.png', name: 'grid_4x4', category: 5 },
  { id: 852, filename: 'png/device/grid_goldenratio.png', name: 'grid_goldenratio', category: 5 },
  { id: 853, filename: 'png/device/h_mobiledata.png', name: 'h_mobiledata', category: 5 },
  { id: 854, filename: 'png/device/h_plus_mobiledata.png', name: 'h_plus_mobiledata', category: 5 },
  { id: 855, filename: 'png/device/hdr_auto.png', name: 'hdr_auto', category: 5 },
  { id: 856, filename: 'png/device/hdr_auto_select.png', name: 'hdr_auto_select', category: 5 },
  { id: 857, filename: 'png/device/hdr_off_select.png', name: 'hdr_off_select', category: 5 },
  { id: 858, filename: 'png/device/hdr_on_select.png', name: 'hdr_on_select', category: 5 },
  { id: 859, filename: 'png/device/lan.png', name: 'lan', category: 5 },
  { id: 860, filename: 'png/device/lens_blur.png', name: 'lens_blur', category: 5 },
  { id: 861, filename: 'png/device/light_mode.png', name: 'light_mode', category: 5 },
  { id: 862, filename: 'png/device/location_disabled.png', name: 'location_disabled', category: 5 },
  {
    id: 863,
    filename: 'png/device/location_searching.png',
    name: 'location_searching',
    category: 5,
  },
  { id: 864, filename: 'png/device/lte_mobiledata.png', name: 'lte_mobiledata', category: 5 },
  {
    id: 865,
    filename: 'png/device/lte_plus_mobiledata.png',
    name: 'lte_plus_mobiledata',
    category: 5,
  },
  {
    id: 866,
    filename: 'png/device/media_bluetooth_off.png',
    name: 'media_bluetooth_off',
    category: 5,
  },
  {
    id: 867,
    filename: 'png/device/media_bluetooth_on.png',
    name: 'media_bluetooth_on',
    category: 5,
  },
  { id: 868, filename: 'png/device/medication.png', name: 'medication', category: 5 },
  { id: 869, filename: 'png/device/medication_liquid.png', name: 'medication_liquid', category: 5 },
  { id: 870, filename: 'png/device/mobile_friendly.png', name: 'mobile_friendly', category: 5 },
  { id: 871, filename: 'png/device/mobile_off.png', name: 'mobile_off', category: 5 },
  { id: 872, filename: 'png/device/mobiledata_off.png', name: 'mobiledata_off', category: 5 },
  { id: 873, filename: 'png/device/mode_night.png', name: 'mode_night', category: 5 },
  { id: 874, filename: 'png/device/mode_standby.png', name: 'mode_standby', category: 5 },
  { id: 875, filename: 'png/device/monitor_heart.png', name: 'monitor_heart', category: 5 },
  { id: 876, filename: 'png/device/monitor_weight.png', name: 'monitor_weight', category: 5 },
  { id: 877, filename: 'png/device/nearby_error.png', name: 'nearby_error', category: 5 },
  { id: 878, filename: 'png/device/nearby_off.png', name: 'nearby_off', category: 5 },
  { id: 879, filename: 'png/device/network_cell.png', name: 'network_cell', category: 5 },
  { id: 880, filename: 'png/device/network_wifi.png', name: 'network_wifi', category: 5 },
  {
    id: 881,
    filename: 'png/device/network_wifi_1_bar.png',
    name: 'network_wifi_1_bar',
    category: 5,
  },
  {
    id: 882,
    filename: 'png/device/network_wifi_2_bar.png',
    name: 'network_wifi_2_bar',
    category: 5,
  },
  {
    id: 883,
    filename: 'png/device/network_wifi_3_bar.png',
    name: 'network_wifi_3_bar',
    category: 5,
  },
  { id: 884, filename: 'png/device/nfc.png', name: 'nfc', category: 5 },
  { id: 885, filename: 'png/device/nightlight.png', name: 'nightlight', category: 5 },
  { id: 886, filename: 'png/device/note_alt.png', name: 'note_alt', category: 5 },
  { id: 887, filename: 'png/device/password.png', name: 'password', category: 5 },
  { id: 888, filename: 'png/device/pattern.png', name: 'pattern', category: 5 },
  { id: 889, filename: 'png/device/phishing.png', name: 'phishing', category: 5 },
  { id: 890, filename: 'png/device/pin.png', name: 'pin', category: 5 },
  { id: 891, filename: 'png/device/play_lesson.png', name: 'play_lesson', category: 5 },
  { id: 892, filename: 'png/device/price_change.png', name: 'price_change', category: 5 },
  { id: 893, filename: 'png/device/price_check.png', name: 'price_check', category: 5 },
  { id: 894, filename: 'png/device/punch_clock.png', name: 'punch_clock', category: 5 },
  { id: 895, filename: 'png/device/quiz.png', name: 'quiz', category: 5 },
  { id: 896, filename: 'png/device/r_mobiledata.png', name: 'r_mobiledata', category: 5 },
  { id: 897, filename: 'png/device/radar.png', name: 'radar', category: 5 },
  { id: 898, filename: 'png/device/remember_me.png', name: 'remember_me', category: 5 },
  { id: 899, filename: 'png/device/reset_tv.png', name: 'reset_tv', category: 5 },
  { id: 900, filename: 'png/device/restart_alt.png', name: 'restart_alt', category: 5 },
  { id: 901, filename: 'png/device/reviews.png', name: 'reviews', category: 5 },
  { id: 902, filename: 'png/device/rsvp.png', name: 'rsvp', category: 5 },
  {
    id: 903,
    filename: 'png/device/screen_lock_landscape.png',
    name: 'screen_lock_landscape',
    category: 5,
  },
  {
    id: 904,
    filename: 'png/device/screen_lock_portrait.png',
    name: 'screen_lock_portrait',
    category: 5,
  },
  {
    id: 905,
    filename: 'png/device/screen_lock_rotation.png',
    name: 'screen_lock_rotation',
    category: 5,
  },
  { id: 906, filename: 'png/device/screen_rotation.png', name: 'screen_rotation', category: 5 },
  {
    id: 907,
    filename: 'png/device/screen_search_desktop.png',
    name: 'screen_search_desktop',
    category: 5,
  },
  { id: 908, filename: 'png/device/screenshot.png', name: 'screenshot', category: 5 },
  {
    id: 909,
    filename: 'png/device/screenshot_monitor.png',
    name: 'screenshot_monitor',
    category: 5,
  },
  { id: 910, filename: 'png/device/sd_storage.png', name: 'sd_storage', category: 5 },
  { id: 911, filename: 'png/device/security_update.png', name: 'security_update', category: 5 },
  {
    id: 912,
    filename: 'png/device/security_update_good.png',
    name: 'security_update_good',
    category: 5,
  },
  {
    id: 913,
    filename: 'png/device/security_update_warning.png',
    name: 'security_update_warning',
    category: 5,
  },
  { id: 914, filename: 'png/device/sell.png', name: 'sell', category: 5 },
  { id: 915, filename: 'png/device/send_to_mobile.png', name: 'send_to_mobile', category: 5 },
  { id: 916, filename: 'png/device/settings_suggest.png', name: 'settings_suggest', category: 5 },
  {
    id: 917,
    filename: 'png/device/settings_system_daydream.png',
    name: 'settings_system_daydream',
    category: 5,
  },
  { id: 918, filename: 'png/device/share_location.png', name: 'share_location', category: 5 },
  { id: 919, filename: 'png/device/shortcut.png', name: 'shortcut', category: 5 },
  {
    id: 920,
    filename: 'png/device/signal_cellular_0_bar.png',
    name: 'signal_cellular_0_bar',
    category: 5,
  },
  {
    id: 921,
    filename: 'png/device/signal_cellular_1_bar.png',
    name: 'signal_cellular_1_bar',
    category: 5,
  },
  {
    id: 922,
    filename: 'png/device/signal_cellular_2_bar.png',
    name: 'signal_cellular_2_bar',
    category: 5,
  },
  {
    id: 923,
    filename: 'png/device/signal_cellular_3_bar.png',
    name: 'signal_cellular_3_bar',
    category: 5,
  },
  {
    id: 924,
    filename: 'png/device/signal_cellular_4_bar.png',
    name: 'signal_cellular_4_bar',
    category: 5,
  },
  {
    id: 925,
    filename: 'png/device/signal_cellular_alt.png',
    name: 'signal_cellular_alt',
    category: 5,
  },
  {
    id: 926,
    filename: 'png/device/signal_cellular_alt_1_bar.png',
    name: 'signal_cellular_alt_1_bar',
    category: 5,
  },
  {
    id: 927,
    filename: 'png/device/signal_cellular_alt_2_bar.png',
    name: 'signal_cellular_alt_2_bar',
    category: 5,
  },
  {
    id: 928,
    filename: 'png/device/signal_cellular_connected_no_internet_0_bar.png',
    name: 'signal_cellular_connected_no_internet_0_bar',
    category: 5,
  },
  {
    id: 929,
    filename: 'png/device/signal_cellular_connected_no_internet_1_bar.png',
    name: 'signal_cellular_connected_no_internet_1_bar',
    category: 5,
  },
  {
    id: 930,
    filename: 'png/device/signal_cellular_connected_no_internet_2_bar.png',
    name: 'signal_cellular_connected_no_internet_2_bar',
    category: 5,
  },
  {
    id: 931,
    filename: 'png/device/signal_cellular_connected_no_internet_3_bar.png',
    name: 'signal_cellular_connected_no_internet_3_bar',
    category: 5,
  },
  {
    id: 932,
    filename: 'png/device/signal_cellular_connected_no_internet_4_bar.png',
    name: 'signal_cellular_connected_no_internet_4_bar',
    category: 5,
  },
  {
    id: 933,
    filename: 'png/device/signal_cellular_no_sim.png',
    name: 'signal_cellular_no_sim',
    category: 5,
  },
  {
    id: 934,
    filename: 'png/device/signal_cellular_nodata.png',
    name: 'signal_cellular_nodata',
    category: 5,
  },
  {
    id: 935,
    filename: 'png/device/signal_cellular_null.png',
    name: 'signal_cellular_null',
    category: 5,
  },
  {
    id: 936,
    filename: 'png/device/signal_cellular_off.png',
    name: 'signal_cellular_off',
    category: 5,
  },
  { id: 937, filename: 'png/device/signal_wifi_0_bar.png', name: 'signal_wifi_0_bar', category: 5 },
  { id: 938, filename: 'png/device/signal_wifi_1_bar.png', name: 'signal_wifi_1_bar', category: 5 },
  {
    id: 939,
    filename: 'png/device/signal_wifi_1_bar_lock.png',
    name: 'signal_wifi_1_bar_lock',
    category: 5,
  },
  { id: 940, filename: 'png/device/signal_wifi_2_bar.png', name: 'signal_wifi_2_bar', category: 5 },
  {
    id: 941,
    filename: 'png/device/signal_wifi_2_bar_lock.png',
    name: 'signal_wifi_2_bar_lock',
    category: 5,
  },
  { id: 942, filename: 'png/device/signal_wifi_3_bar.png', name: 'signal_wifi_3_bar', category: 5 },
  {
    id: 943,
    filename: 'png/device/signal_wifi_3_bar_lock.png',
    name: 'signal_wifi_3_bar_lock',
    category: 5,
  },
  { id: 944, filename: 'png/device/signal_wifi_4_bar.png', name: 'signal_wifi_4_bar', category: 5 },
  {
    id: 945,
    filename: 'png/device/signal_wifi_4_bar_lock.png',
    name: 'signal_wifi_4_bar_lock',
    category: 5,
  },
  { id: 946, filename: 'png/device/signal_wifi_bad.png', name: 'signal_wifi_bad', category: 5 },
  {
    id: 947,
    filename: 'png/device/signal_wifi_connected_no_internet_0.png',
    name: 'signal_wifi_connected_no_internet_0',
    category: 5,
  },
  {
    id: 948,
    filename: 'png/device/signal_wifi_connected_no_internet_1.png',
    name: 'signal_wifi_connected_no_internet_1',
    category: 5,
  },
  {
    id: 949,
    filename: 'png/device/signal_wifi_connected_no_internet_2.png',
    name: 'signal_wifi_connected_no_internet_2',
    category: 5,
  },
  {
    id: 950,
    filename: 'png/device/signal_wifi_connected_no_internet_3.png',
    name: 'signal_wifi_connected_no_internet_3',
    category: 5,
  },
  {
    id: 951,
    filename: 'png/device/signal_wifi_connected_no_internet_4.png',
    name: 'signal_wifi_connected_no_internet_4',
    category: 5,
  },
  { id: 952, filename: 'png/device/signal_wifi_off.png', name: 'signal_wifi_off', category: 5 },
  {
    id: 953,
    filename: 'png/device/signal_wifi_statusbar_1_bar.png',
    name: 'signal_wifi_statusbar_1_bar',
    category: 5,
  },
  {
    id: 954,
    filename: 'png/device/signal_wifi_statusbar_2_bar.png',
    name: 'signal_wifi_statusbar_2_bar',
    category: 5,
  },
  {
    id: 955,
    filename: 'png/device/signal_wifi_statusbar_3_bar.png',
    name: 'signal_wifi_statusbar_3_bar',
    category: 5,
  },
  {
    id: 956,
    filename: 'png/device/signal_wifi_statusbar_4_bar.png',
    name: 'signal_wifi_statusbar_4_bar',
    category: 5,
  },
  {
    id: 957,
    filename: 'png/device/signal_wifi_statusbar_connected_no_internet.png',
    name: 'signal_wifi_statusbar_connected_no_internet',
    category: 5,
  },
  {
    id: 958,
    filename: 'png/device/signal_wifi_statusbar_connected_no_internet_1.png',
    name: 'signal_wifi_statusbar_connected_no_internet_1',
    category: 5,
  },
  {
    id: 959,
    filename: 'png/device/signal_wifi_statusbar_connected_no_internet_2.png',
    name: 'signal_wifi_statusbar_connected_no_internet_2',
    category: 5,
  },
  {
    id: 960,
    filename: 'png/device/signal_wifi_statusbar_connected_no_internet_3.png',
    name: 'signal_wifi_statusbar_connected_no_internet_3',
    category: 5,
  },
  {
    id: 961,
    filename: 'png/device/signal_wifi_statusbar_connected_no_internet_4.png',
    name: 'signal_wifi_statusbar_connected_no_internet_4',
    category: 5,
  },
  {
    id: 962,
    filename: 'png/device/signal_wifi_statusbar_not_connected.png',
    name: 'signal_wifi_statusbar_not_connected',
    category: 5,
  },
  {
    id: 963,
    filename: 'png/device/signal_wifi_statusbar_null.png',
    name: 'signal_wifi_statusbar_null',
    category: 5,
  },
  { id: 964, filename: 'png/device/sim_card_download.png', name: 'sim_card_download', category: 5 },
  { id: 965, filename: 'png/device/splitscreen.png', name: 'splitscreen', category: 5 },
  { id: 966, filename: 'png/device/sports_score.png', name: 'sports_score', category: 5 },
  { id: 967, filename: 'png/device/ssid_chart.png', name: 'ssid_chart', category: 5 },
  { id: 968, filename: 'png/device/storage.png', name: 'storage', category: 5 },
  { id: 969, filename: 'png/device/storm.png', name: 'storm', category: 5 },
  { id: 970, filename: 'png/device/summarize.png', name: 'summarize', category: 5 },
  {
    id: 971,
    filename: 'png/device/system_security_update.png',
    name: 'system_security_update',
    category: 5,
  },
  {
    id: 972,
    filename: 'png/device/system_security_update_good.png',
    name: 'system_security_update_good',
    category: 5,
  },
  {
    id: 973,
    filename: 'png/device/system_security_update_warning.png',
    name: 'system_security_update_warning',
    category: 5,
  },
  { id: 974, filename: 'png/device/task.png', name: 'task', category: 5 },
  { id: 975, filename: 'png/device/thermostat.png', name: 'thermostat', category: 5 },
  { id: 976, filename: 'png/device/timer_10_select.png', name: 'timer_10_select', category: 5 },
  { id: 977, filename: 'png/device/timer_3_select.png', name: 'timer_3_select', category: 5 },
  { id: 978, filename: 'png/device/tungsten.png', name: 'tungsten', category: 5 },
  { id: 979, filename: 'png/device/usb.png', name: 'usb', category: 5 },
  { id: 980, filename: 'png/device/usb_off.png', name: 'usb_off', category: 5 },
  { id: 981, filename: 'png/device/wallpaper.png', name: 'wallpaper', category: 5 },
  { id: 982, filename: 'png/device/water.png', name: 'water', category: 5 },
  { id: 983, filename: 'png/device/widgets.png', name: 'widgets', category: 5 },
  { id: 984, filename: 'png/device/wifi_1_bar.png', name: 'wifi_1_bar', category: 5 },
  { id: 985, filename: 'png/device/wifi_2_bar.png', name: 'wifi_2_bar', category: 5 },
  { id: 986, filename: 'png/device/wifi_calling_1.png', name: 'wifi_calling_1', category: 5 },
  { id: 987, filename: 'png/device/wifi_calling_2.png', name: 'wifi_calling_2', category: 5 },
  { id: 988, filename: 'png/device/wifi_calling_3.png', name: 'wifi_calling_3', category: 5 },
  { id: 989, filename: 'png/device/wifi_channel.png', name: 'wifi_channel', category: 5 },
  { id: 990, filename: 'png/device/wifi_find.png', name: 'wifi_find', category: 5 },
  { id: 991, filename: 'png/device/wifi_lock.png', name: 'wifi_lock', category: 5 },
  { id: 992, filename: 'png/device/wifi_password.png', name: 'wifi_password', category: 5 },
  { id: 993, filename: 'png/device/wifi_tethering.png', name: 'wifi_tethering', category: 5 },
  {
    id: 994,
    filename: 'png/device/wifi_tethering_error.png',
    name: 'wifi_tethering_error',
    category: 5,
  },
  {
    id: 995,
    filename: 'png/device/wifi_tethering_error_rounded.png',
    name: 'wifi_tethering_error_rounded',
    category: 5,
  },
  {
    id: 996,
    filename: 'png/device/wifi_tethering_off.png',
    name: 'wifi_tethering_off',
    category: 5,
  },
  { id: 997, filename: 'png/editor/add_chart.png', name: 'add_chart', category: 6 },
  { id: 998, filename: 'png/editor/add_comment.png', name: 'add_comment', category: 6 },
  {
    id: 999,
    filename: 'png/editor/align_horizontal_center.png',
    name: 'align_horizontal_center',
    category: 6,
  },
  {
    id: 1000,
    filename: 'png/editor/align_horizontal_left.png',
    name: 'align_horizontal_left',
    category: 6,
  },
  {
    id: 1001,
    filename: 'png/editor/align_horizontal_right.png',
    name: 'align_horizontal_right',
    category: 6,
  },
  {
    id: 1002,
    filename: 'png/editor/align_vertical_bottom.png',
    name: 'align_vertical_bottom',
    category: 6,
  },
  {
    id: 1003,
    filename: 'png/editor/align_vertical_center.png',
    name: 'align_vertical_center',
    category: 6,
  },
  {
    id: 1004,
    filename: 'png/editor/align_vertical_top.png',
    name: 'align_vertical_top',
    category: 6,
  },
  { id: 1005, filename: 'png/editor/area_chart.png', name: 'area_chart', category: 6 },
  { id: 1006, filename: 'png/editor/attach_file.png', name: 'attach_file', category: 6 },
  { id: 1007, filename: 'png/editor/attach_money.png', name: 'attach_money', category: 6 },
  { id: 1008, filename: 'png/editor/auto_graph.png', name: 'auto_graph', category: 6 },
  { id: 1009, filename: 'png/editor/bar_chart.png', name: 'bar_chart', category: 6 },
  { id: 1010, filename: 'png/editor/border_all.png', name: 'border_all', category: 6 },
  { id: 1011, filename: 'png/editor/border_bottom.png', name: 'border_bottom', category: 6 },
  { id: 1012, filename: 'png/editor/border_clear.png', name: 'border_clear', category: 6 },
  { id: 1013, filename: 'png/editor/border_color.png', name: 'border_color', category: 6 },
  {
    id: 1014,
    filename: 'png/editor/border_horizontal.png',
    name: 'border_horizontal',
    category: 6,
  },
  { id: 1015, filename: 'png/editor/border_inner.png', name: 'border_inner', category: 6 },
  { id: 1016, filename: 'png/editor/border_left.png', name: 'border_left', category: 6 },
  { id: 1017, filename: 'png/editor/border_outer.png', name: 'border_outer', category: 6 },
  { id: 1018, filename: 'png/editor/border_right.png', name: 'border_right', category: 6 },
  { id: 1019, filename: 'png/editor/border_style.png', name: 'border_style', category: 6 },
  { id: 1020, filename: 'png/editor/border_top.png', name: 'border_top', category: 6 },
  { id: 1021, filename: 'png/editor/border_vertical.png', name: 'border_vertical', category: 6 },
  { id: 1022, filename: 'png/editor/bubble_chart.png', name: 'bubble_chart', category: 6 },
  {
    id: 1023,
    filename: 'png/editor/candlestick_chart.png',
    name: 'candlestick_chart',
    category: 6,
  },
  { id: 1024, filename: 'png/editor/checklist.png', name: 'checklist', category: 6 },
  { id: 1025, filename: 'png/editor/checklist_rtl.png', name: 'checklist_rtl', category: 6 },
  { id: 1026, filename: 'png/editor/data_array.png', name: 'data_array', category: 6 },
  { id: 1027, filename: 'png/editor/data_object.png', name: 'data_object', category: 6 },
  { id: 1028, filename: 'png/editor/drag_handle.png', name: 'drag_handle', category: 6 },
  { id: 1029, filename: 'png/editor/draw.png', name: 'draw', category: 6 },
  { id: 1030, filename: 'png/editor/edit_note.png', name: 'edit_note', category: 6 },
  {
    id: 1031,
    filename: 'png/editor/format_align_center.png',
    name: 'format_align_center',
    category: 6,
  },
  {
    id: 1032,
    filename: 'png/editor/format_align_justify.png',
    name: 'format_align_justify',
    category: 6,
  },
  {
    id: 1033,
    filename: 'png/editor/format_align_left.png',
    name: 'format_align_left',
    category: 6,
  },
  {
    id: 1034,
    filename: 'png/editor/format_align_right.png',
    name: 'format_align_right',
    category: 6,
  },
  { id: 1035, filename: 'png/editor/format_bold.png', name: 'format_bold', category: 6 },
  { id: 1036, filename: 'png/editor/format_clear.png', name: 'format_clear', category: 6 },
  {
    id: 1037,
    filename: 'png/editor/format_color_fill.png',
    name: 'format_color_fill',
    category: 6,
  },
  {
    id: 1038,
    filename: 'png/editor/format_color_reset.png',
    name: 'format_color_reset',
    category: 6,
  },
  {
    id: 1039,
    filename: 'png/editor/format_color_text.png',
    name: 'format_color_text',
    category: 6,
  },
  {
    id: 1040,
    filename: 'png/editor/format_indent_decrease.png',
    name: 'format_indent_decrease',
    category: 6,
  },
  {
    id: 1041,
    filename: 'png/editor/format_indent_increase.png',
    name: 'format_indent_increase',
    category: 6,
  },
  { id: 1042, filename: 'png/editor/format_italic.png', name: 'format_italic', category: 6 },
  {
    id: 1043,
    filename: 'png/editor/format_line_spacing.png',
    name: 'format_line_spacing',
    category: 6,
  },
  {
    id: 1044,
    filename: 'png/editor/format_list_bulleted.png',
    name: 'format_list_bulleted',
    category: 6,
  },
  {
    id: 1045,
    filename: 'png/editor/format_list_numbered.png',
    name: 'format_list_numbered',
    category: 6,
  },
  {
    id: 1046,
    filename: 'png/editor/format_list_numbered_rtl.png',
    name: 'format_list_numbered_rtl',
    category: 6,
  },
  { id: 1047, filename: 'png/editor/format_paint.png', name: 'format_paint', category: 6 },
  { id: 1048, filename: 'png/editor/format_quote.png', name: 'format_quote', category: 6 },
  { id: 1049, filename: 'png/editor/format_shapes.png', name: 'format_shapes', category: 6 },
  { id: 1050, filename: 'png/editor/format_size.png', name: 'format_size', category: 6 },
  {
    id: 1051,
    filename: 'png/editor/format_strikethrough.png',
    name: 'format_strikethrough',
    category: 6,
  },
  {
    id: 1052,
    filename: 'png/editor/format_textdirection_l_to_r.png',
    name: 'format_textdirection_l_to_r',
    category: 6,
  },
  {
    id: 1053,
    filename: 'png/editor/format_textdirection_r_to_l.png',
    name: 'format_textdirection_r_to_l',
    category: 6,
  },
  {
    id: 1054,
    filename: 'png/editor/format_underlined.png',
    name: 'format_underlined',
    category: 6,
  },
  { id: 1055, filename: 'png/editor/functions.png', name: 'functions', category: 6 },
  { id: 1056, filename: 'png/editor/height.png', name: 'height', category: 6 },
  { id: 1057, filename: 'png/editor/hexagon.png', name: 'hexagon', category: 6 },
  { id: 1058, filename: 'png/editor/highlight.png', name: 'highlight', category: 6 },
  {
    id: 1059,
    filename: 'png/editor/horizontal_distribute.png',
    name: 'horizontal_distribute',
    category: 6,
  },
  { id: 1060, filename: 'png/editor/horizontal_rule.png', name: 'horizontal_rule', category: 6 },
  { id: 1061, filename: 'png/editor/insert_chart.png', name: 'insert_chart', category: 6 },
  {
    id: 1062,
    filename: 'png/editor/insert_chart_outlined.png',
    name: 'insert_chart_outlined',
    category: 6,
  },
  { id: 1063, filename: 'png/editor/insert_comment.png', name: 'insert_comment', category: 6 },
  {
    id: 1064,
    filename: 'png/editor/insert_drive_file.png',
    name: 'insert_drive_file',
    category: 6,
  },
  { id: 1065, filename: 'png/editor/insert_emoticon.png', name: 'insert_emoticon', category: 6 },
  {
    id: 1066,
    filename: 'png/editor/insert_invitation.png',
    name: 'insert_invitation',
    category: 6,
  },
  { id: 1067, filename: 'png/editor/insert_link.png', name: 'insert_link', category: 6 },
  {
    id: 1068,
    filename: 'png/editor/insert_page_break.png',
    name: 'insert_page_break',
    category: 6,
  },
  { id: 1069, filename: 'png/editor/insert_photo.png', name: 'insert_photo', category: 6 },
  { id: 1070, filename: 'png/editor/line_axis.png', name: 'line_axis', category: 6 },
  { id: 1071, filename: 'png/editor/linear_scale.png', name: 'linear_scale', category: 6 },
  { id: 1072, filename: 'png/editor/margin.png', name: 'margin', category: 6 },
  { id: 1073, filename: 'png/editor/merge_type.png', name: 'merge_type', category: 6 },
  { id: 1074, filename: 'png/editor/mode.png', name: 'mode', category: 6 },
  { id: 1075, filename: 'png/editor/mode_comment.png', name: 'mode_comment', category: 6 },
  { id: 1076, filename: 'png/editor/mode_edit.png', name: 'mode_edit', category: 6 },
  {
    id: 1077,
    filename: 'png/editor/mode_edit_outline.png',
    name: 'mode_edit_outline',
    category: 6,
  },
  { id: 1078, filename: 'png/editor/monetization_on.png', name: 'monetization_on', category: 6 },
  { id: 1079, filename: 'png/editor/money_off.png', name: 'money_off', category: 6 },
  { id: 1080, filename: 'png/editor/money_off_csred.png', name: 'money_off_csred', category: 6 },
  { id: 1081, filename: 'png/editor/move_down.png', name: 'move_down', category: 6 },
  { id: 1082, filename: 'png/editor/move_up.png', name: 'move_up', category: 6 },
  { id: 1083, filename: 'png/editor/multiline_chart.png', name: 'multiline_chart', category: 6 },
  { id: 1084, filename: 'png/editor/notes.png', name: 'notes', category: 6 },
  { id: 1085, filename: 'png/editor/numbers.png', name: 'numbers', category: 6 },
  { id: 1086, filename: 'png/editor/padding.png', name: 'padding', category: 6 },
  { id: 1087, filename: 'png/editor/pentagon.png', name: 'pentagon', category: 6 },
  { id: 1088, filename: 'png/editor/pie_chart.png', name: 'pie_chart', category: 6 },
  {
    id: 1089,
    filename: 'png/editor/pie_chart_outline.png',
    name: 'pie_chart_outline',
    category: 6,
  },
  {
    id: 1090,
    filename: 'png/editor/pie_chart_outlined.png',
    name: 'pie_chart_outlined',
    category: 6,
  },
  { id: 1091, filename: 'png/editor/polyline.png', name: 'polyline', category: 6 },
  { id: 1092, filename: 'png/editor/post_add.png', name: 'post_add', category: 6 },
  { id: 1093, filename: 'png/editor/publish.png', name: 'publish', category: 6 },
  { id: 1094, filename: 'png/editor/query_stats.png', name: 'query_stats', category: 6 },
  { id: 1095, filename: 'png/editor/rectangle.png', name: 'rectangle', category: 6 },
  { id: 1096, filename: 'png/editor/scatter_plot.png', name: 'scatter_plot', category: 6 },
  { id: 1097, filename: 'png/editor/schema.png', name: 'schema', category: 6 },
  { id: 1098, filename: 'png/editor/score.png', name: 'score', category: 6 },
  { id: 1099, filename: 'png/editor/short_text.png', name: 'short_text', category: 6 },
  { id: 1100, filename: 'png/editor/show_chart.png', name: 'show_chart', category: 6 },
  { id: 1101, filename: 'png/editor/space_bar.png', name: 'space_bar', category: 6 },
  { id: 1102, filename: 'png/editor/square.png', name: 'square', category: 6 },
  {
    id: 1103,
    filename: 'png/editor/stacked_line_chart.png',
    name: 'stacked_line_chart',
    category: 6,
  },
  { id: 1104, filename: 'png/editor/strikethrough_s.png', name: 'strikethrough_s', category: 6 },
  { id: 1105, filename: 'png/editor/subscript.png', name: 'subscript', category: 6 },
  { id: 1106, filename: 'png/editor/superscript.png', name: 'superscript', category: 6 },
  { id: 1107, filename: 'png/editor/table_chart.png', name: 'table_chart', category: 6 },
  { id: 1108, filename: 'png/editor/table_rows.png', name: 'table_rows', category: 6 },
  { id: 1109, filename: 'png/editor/text_decrease.png', name: 'text_decrease', category: 6 },
  { id: 1110, filename: 'png/editor/text_fields.png', name: 'text_fields', category: 6 },
  { id: 1111, filename: 'png/editor/text_increase.png', name: 'text_increase', category: 6 },
  { id: 1112, filename: 'png/editor/title.png', name: 'title', category: 6 },
  {
    id: 1113,
    filename: 'png/editor/vertical_align_bottom.png',
    name: 'vertical_align_bottom',
    category: 6,
  },
  {
    id: 1114,
    filename: 'png/editor/vertical_align_center.png',
    name: 'vertical_align_center',
    category: 6,
  },
  {
    id: 1115,
    filename: 'png/editor/vertical_align_top.png',
    name: 'vertical_align_top',
    category: 6,
  },
  {
    id: 1116,
    filename: 'png/editor/vertical_distribute.png',
    name: 'vertical_distribute',
    category: 6,
  },
  { id: 1117, filename: 'png/editor/wrap_text.png', name: 'wrap_text', category: 6 },
  { id: 1118, filename: 'png/file/approval.png', name: 'approval', category: 7 },
  { id: 1119, filename: 'png/file/attach_email.png', name: 'attach_email', category: 7 },
  { id: 1120, filename: 'png/file/attachment.png', name: 'attachment', category: 7 },
  { id: 1121, filename: 'png/file/cloud.png', name: 'cloud', category: 7 },
  { id: 1122, filename: 'png/file/cloud_circle.png', name: 'cloud_circle', category: 7 },
  { id: 1123, filename: 'png/file/cloud_done.png', name: 'cloud_done', category: 7 },
  { id: 1124, filename: 'png/file/cloud_download.png', name: 'cloud_download', category: 7 },
  { id: 1125, filename: 'png/file/cloud_off.png', name: 'cloud_off', category: 7 },
  { id: 1126, filename: 'png/file/cloud_queue.png', name: 'cloud_queue', category: 7 },
  { id: 1127, filename: 'png/file/cloud_sync.png', name: 'cloud_sync', category: 7 },
  { id: 1128, filename: 'png/file/cloud_upload.png', name: 'cloud_upload', category: 7 },
  { id: 1129, filename: 'png/file/create_new_folder.png', name: 'create_new_folder', category: 7 },
  { id: 1130, filename: 'png/file/difference.png', name: 'difference', category: 7 },
  { id: 1131, filename: 'png/file/download.png', name: 'download', category: 7 },
  { id: 1132, filename: 'png/file/download_done.png', name: 'download_done', category: 7 },
  {
    id: 1133,
    filename: 'png/file/download_for_offline.png',
    name: 'download_for_offline',
    category: 7,
  },
  { id: 1134, filename: 'png/file/downloading.png', name: 'downloading', category: 7 },
  { id: 1135, filename: 'png/file/drive_file_move.png', name: 'drive_file_move', category: 7 },
  {
    id: 1136,
    filename: 'png/file/drive_file_move_outline.png',
    name: 'drive_file_move_outline',
    category: 7,
  },
  {
    id: 1137,
    filename: 'png/file/drive_file_move_rtl.png',
    name: 'drive_file_move_rtl',
    category: 7,
  },
  {
    id: 1138,
    filename: 'png/file/drive_file_rename_outline.png',
    name: 'drive_file_rename_outline',
    category: 7,
  },
  {
    id: 1139,
    filename: 'png/file/drive_folder_upload.png',
    name: 'drive_folder_upload',
    category: 7,
  },
  { id: 1140, filename: 'png/file/file_download.png', name: 'file_download', category: 7 },
  {
    id: 1141,
    filename: 'png/file/file_download_done.png',
    name: 'file_download_done',
    category: 7,
  },
  { id: 1142, filename: 'png/file/file_download_off.png', name: 'file_download_off', category: 7 },
  { id: 1143, filename: 'png/file/file_open.png', name: 'file_open', category: 7 },
  { id: 1144, filename: 'png/file/file_upload.png', name: 'file_upload', category: 7 },
  { id: 1145, filename: 'png/file/folder.png', name: 'folder', category: 7 },
  { id: 1146, filename: 'png/file/folder_copy.png', name: 'folder_copy', category: 7 },
  { id: 1147, filename: 'png/file/folder_delete.png', name: 'folder_delete', category: 7 },
  { id: 1148, filename: 'png/file/folder_off.png', name: 'folder_off', category: 7 },
  { id: 1149, filename: 'png/file/folder_open.png', name: 'folder_open', category: 7 },
  { id: 1150, filename: 'png/file/folder_shared.png', name: 'folder_shared', category: 7 },
  { id: 1151, filename: 'png/file/folder_zip.png', name: 'folder_zip', category: 7 },
  { id: 1152, filename: 'png/file/format_overline.png', name: 'format_overline', category: 7 },
  { id: 1153, filename: 'png/file/grid_view.png', name: 'grid_view', category: 7 },
  { id: 1154, filename: 'png/file/newspaper.png', name: 'newspaper', category: 7 },
  { id: 1155, filename: 'png/file/request_quote.png', name: 'request_quote', category: 7 },
  { id: 1156, filename: 'png/file/rule_folder.png', name: 'rule_folder', category: 7 },
  { id: 1157, filename: 'png/file/snippet_folder.png', name: 'snippet_folder', category: 7 },
  { id: 1158, filename: 'png/file/text_snippet.png', name: 'text_snippet', category: 7 },
  { id: 1159, filename: 'png/file/topic.png', name: 'topic', category: 7 },
  { id: 1160, filename: 'png/file/upload.png', name: 'upload', category: 7 },
  { id: 1161, filename: 'png/file/upload_file.png', name: 'upload_file', category: 7 },
  { id: 1162, filename: 'png/file/workspaces.png', name: 'workspaces', category: 7 },
  { id: 1163, filename: 'png/file/workspaces_filled.png', name: 'workspaces_filled', category: 7 },
  {
    id: 1164,
    filename: 'png/file/workspaces_outline.png',
    name: 'workspaces_outline',
    category: 7,
  },
  { id: 1165, filename: 'png/hardware/adf_scanner.png', name: 'adf_scanner', category: 8 },
  {
    id: 1166,
    filename: 'png/hardware/browser_not_supported.png',
    name: 'browser_not_supported',
    category: 8,
  },
  { id: 1167, filename: 'png/hardware/browser_updated.png', name: 'browser_updated', category: 8 },
  { id: 1168, filename: 'png/hardware/cast.png', name: 'cast', category: 8 },
  { id: 1169, filename: 'png/hardware/cast_connected.png', name: 'cast_connected', category: 8 },
  {
    id: 1170,
    filename: 'png/hardware/cast_for_education.png',
    name: 'cast_for_education',
    category: 8,
  },
  { id: 1171, filename: 'png/hardware/computer.png', name: 'computer', category: 8 },
  { id: 1172, filename: 'png/hardware/connected_tv.png', name: 'connected_tv', category: 8 },
  { id: 1173, filename: 'png/hardware/desktop_mac.png', name: 'desktop_mac', category: 8 },
  { id: 1174, filename: 'png/hardware/desktop_windows.png', name: 'desktop_windows', category: 8 },
  { id: 1175, filename: 'png/hardware/developer_board.png', name: 'developer_board', category: 8 },
  {
    id: 1176,
    filename: 'png/hardware/developer_board_off.png',
    name: 'developer_board_off',
    category: 8,
  },
  { id: 1177, filename: 'png/hardware/device_hub.png', name: 'device_hub', category: 8 },
  { id: 1178, filename: 'png/hardware/device_unknown.png', name: 'device_unknown', category: 8 },
  { id: 1179, filename: 'png/hardware/devices_other.png', name: 'devices_other', category: 8 },
  { id: 1180, filename: 'png/hardware/dock.png', name: 'dock', category: 8 },
  { id: 1181, filename: 'png/hardware/earbuds.png', name: 'earbuds', category: 8 },
  { id: 1182, filename: 'png/hardware/earbuds_battery.png', name: 'earbuds_battery', category: 8 },
  { id: 1183, filename: 'png/hardware/gamepad.png', name: 'gamepad', category: 8 },
  { id: 1184, filename: 'png/hardware/headphones.png', name: 'headphones', category: 8 },
  {
    id: 1185,
    filename: 'png/hardware/headphones_battery.png',
    name: 'headphones_battery',
    category: 8,
  },
  { id: 1186, filename: 'png/hardware/headset.png', name: 'headset', category: 8 },
  { id: 1187, filename: 'png/hardware/headset_mic.png', name: 'headset_mic', category: 8 },
  { id: 1188, filename: 'png/hardware/headset_off.png', name: 'headset_off', category: 8 },
  { id: 1189, filename: 'png/hardware/home_max.png', name: 'home_max', category: 8 },
  { id: 1190, filename: 'png/hardware/home_mini.png', name: 'home_mini', category: 8 },
  { id: 1191, filename: 'png/hardware/keyboard.png', name: 'keyboard', category: 8 },
  { id: 1192, filename: 'png/hardware/keyboard_alt.png', name: 'keyboard_alt', category: 8 },
  {
    id: 1193,
    filename: 'png/hardware/keyboard_arrow_down.png',
    name: 'keyboard_arrow_down',
    category: 8,
  },
  {
    id: 1194,
    filename: 'png/hardware/keyboard_arrow_left.png',
    name: 'keyboard_arrow_left',
    category: 8,
  },
  {
    id: 1195,
    filename: 'png/hardware/keyboard_arrow_right.png',
    name: 'keyboard_arrow_right',
    category: 8,
  },
  {
    id: 1196,
    filename: 'png/hardware/keyboard_arrow_up.png',
    name: 'keyboard_arrow_up',
    category: 8,
  },
  {
    id: 1197,
    filename: 'png/hardware/keyboard_backspace.png',
    name: 'keyboard_backspace',
    category: 8,
  },
  {
    id: 1198,
    filename: 'png/hardware/keyboard_capslock.png',
    name: 'keyboard_capslock',
    category: 8,
  },
  {
    id: 1199,
    filename: 'png/hardware/keyboard_command_key.png',
    name: 'keyboard_command_key',
    category: 8,
  },
  {
    id: 1200,
    filename: 'png/hardware/keyboard_control_key.png',
    name: 'keyboard_control_key',
    category: 8,
  },
  {
    id: 1201,
    filename: 'png/hardware/keyboard_double_arrow_down.png',
    name: 'keyboard_double_arrow_down',
    category: 8,
  },
  {
    id: 1202,
    filename: 'png/hardware/keyboard_double_arrow_left.png',
    name: 'keyboard_double_arrow_left',
    category: 8,
  },
  {
    id: 1203,
    filename: 'png/hardware/keyboard_double_arrow_right.png',
    name: 'keyboard_double_arrow_right',
    category: 8,
  },
  {
    id: 1204,
    filename: 'png/hardware/keyboard_double_arrow_up.png',
    name: 'keyboard_double_arrow_up',
    category: 8,
  },
  { id: 1205, filename: 'png/hardware/keyboard_hide.png', name: 'keyboard_hide', category: 8 },
  {
    id: 1206,
    filename: 'png/hardware/keyboard_option_key.png',
    name: 'keyboard_option_key',
    category: 8,
  },
  { id: 1207, filename: 'png/hardware/keyboard_return.png', name: 'keyboard_return', category: 8 },
  { id: 1208, filename: 'png/hardware/keyboard_tab.png', name: 'keyboard_tab', category: 8 },
  { id: 1209, filename: 'png/hardware/keyboard_voice.png', name: 'keyboard_voice', category: 8 },
  { id: 1210, filename: 'png/hardware/laptop.png', name: 'laptop', category: 8 },
  {
    id: 1211,
    filename: 'png/hardware/laptop_chromebook.png',
    name: 'laptop_chromebook',
    category: 8,
  },
  { id: 1212, filename: 'png/hardware/laptop_mac.png', name: 'laptop_mac', category: 8 },
  { id: 1213, filename: 'png/hardware/laptop_windows.png', name: 'laptop_windows', category: 8 },
  { id: 1214, filename: 'png/hardware/memory.png', name: 'memory', category: 8 },
  { id: 1215, filename: 'png/hardware/monitor.png', name: 'monitor', category: 8 },
  { id: 1216, filename: 'png/hardware/mouse.png', name: 'mouse', category: 8 },
  { id: 1217, filename: 'png/hardware/phone_android.png', name: 'phone_android', category: 8 },
  { id: 1218, filename: 'png/hardware/phone_iphone.png', name: 'phone_iphone', category: 8 },
  { id: 1219, filename: 'png/hardware/phonelink.png', name: 'phonelink', category: 8 },
  { id: 1220, filename: 'png/hardware/phonelink_off.png', name: 'phonelink_off', category: 8 },
  { id: 1221, filename: 'png/hardware/point_of_sale.png', name: 'point_of_sale', category: 8 },
  { id: 1222, filename: 'png/hardware/power_input.png', name: 'power_input', category: 8 },
  { id: 1223, filename: 'png/hardware/router.png', name: 'router', category: 8 },
  { id: 1224, filename: 'png/hardware/scanner.png', name: 'scanner', category: 8 },
  { id: 1225, filename: 'png/hardware/security.png', name: 'security', category: 8 },
  { id: 1226, filename: 'png/hardware/sim_card.png', name: 'sim_card', category: 8 },
  { id: 1227, filename: 'png/hardware/smart_display.png', name: 'smart_display', category: 8 },
  { id: 1228, filename: 'png/hardware/smart_screen.png', name: 'smart_screen', category: 8 },
  { id: 1229, filename: 'png/hardware/smart_toy.png', name: 'smart_toy', category: 8 },
  { id: 1230, filename: 'png/hardware/smartphone.png', name: 'smartphone', category: 8 },
  { id: 1231, filename: 'png/hardware/speaker.png', name: 'speaker', category: 8 },
  { id: 1232, filename: 'png/hardware/speaker_group.png', name: 'speaker_group', category: 8 },
  { id: 1233, filename: 'png/hardware/start.png', name: 'start', category: 8 },
  { id: 1234, filename: 'png/hardware/tablet.png', name: 'tablet', category: 8 },
  { id: 1235, filename: 'png/hardware/tablet_android.png', name: 'tablet_android', category: 8 },
  { id: 1236, filename: 'png/hardware/tablet_mac.png', name: 'tablet_mac', category: 8 },
  { id: 1237, filename: 'png/hardware/toys.png', name: 'toys', category: 8 },
  { id: 1238, filename: 'png/hardware/tv.png', name: 'tv', category: 8 },
  { id: 1239, filename: 'png/hardware/videogame_asset.png', name: 'videogame_asset', category: 8 },
  {
    id: 1240,
    filename: 'png/hardware/videogame_asset_off.png',
    name: 'videogame_asset_off',
    category: 8,
  },
  { id: 1241, filename: 'png/hardware/watch.png', name: 'watch', category: 8 },
  { id: 1242, filename: 'png/hardware/watch_off.png', name: 'watch_off', category: 8 },
  { id: 1243, filename: 'png/home/auto_mode.png', name: 'auto_mode', category: 9 },
  { id: 1244, filename: 'png/home/blinds_closed.png', name: 'blinds_closed', category: 9 },
  { id: 1245, filename: 'png/home/cloudy_snowing.png', name: 'cloudy_snowing', category: 9 },
  { id: 1246, filename: 'png/home/curtains.png', name: 'curtains', category: 9 },
  { id: 1247, filename: 'png/home/curtains_closed.png', name: 'curtains_closed', category: 9 },
  { id: 1248, filename: 'png/home/electric_bolt.png', name: 'electric_bolt', category: 9 },
  { id: 1249, filename: 'png/home/electric_meter.png', name: 'electric_meter', category: 9 },
  {
    id: 1250,
    filename: 'png/home/energy_savings_leaf.png',
    name: 'energy_savings_leaf',
    category: 9,
  },
  { id: 1251, filename: 'png/home/foggy.png', name: 'foggy', category: 9 },
  { id: 1252, filename: 'png/home/gas_meter.png', name: 'gas_meter', category: 9 },
  { id: 1253, filename: 'png/home/heat_pump.png', name: 'heat_pump', category: 9 },
  { id: 1254, filename: 'png/home/mode_fan_off.png', name: 'mode_fan_off', category: 9 },
  {
    id: 1255,
    filename: 'png/home/nest_cam_wired_stand.png',
    name: 'nest_cam_wired_stand',
    category: 9,
  },
  { id: 1256, filename: 'png/home/oil_barrel.png', name: 'oil_barrel', category: 9 },
  { id: 1257, filename: 'png/home/propane.png', name: 'propane', category: 9 },
  { id: 1258, filename: 'png/home/propane_tank.png', name: 'propane_tank', category: 9 },
  { id: 1259, filename: 'png/home/roller_shades.png', name: 'roller_shades', category: 9 },
  {
    id: 1260,
    filename: 'png/home/roller_shades_closed.png',
    name: 'roller_shades_closed',
    category: 9,
  },
  { id: 1261, filename: 'png/home/sensor_door.png', name: 'sensor_door', category: 9 },
  { id: 1262, filename: 'png/home/sensor_occupied.png', name: 'sensor_occupied', category: 9 },
  { id: 1263, filename: 'png/home/sensor_window.png', name: 'sensor_window', category: 9 },
  { id: 1264, filename: 'png/home/shield_moon.png', name: 'shield_moon', category: 9 },
  { id: 1265, filename: 'png/home/snowing.png', name: 'snowing', category: 9 },
  { id: 1266, filename: 'png/home/solar_power.png', name: 'solar_power', category: 9 },
  { id: 1267, filename: 'png/home/sunny.png', name: 'sunny', category: 9 },
  { id: 1268, filename: 'png/home/sunny_snowing.png', name: 'sunny_snowing', category: 9 },
  { id: 1269, filename: 'png/home/vertical_shades.png', name: 'vertical_shades', category: 9 },
  {
    id: 1270,
    filename: 'png/home/vertical_shades_closed.png',
    name: 'vertical_shades_closed',
    category: 9,
  },
  { id: 1271, filename: 'png/home/wind_power.png', name: 'wind_power', category: 9 },
  { id: 1272, filename: 'png/image/10mp.png', name: '10mp', category: 10 },
  { id: 1273, filename: 'png/image/11mp.png', name: '11mp', category: 10 },
  { id: 1274, filename: 'png/image/12mp.png', name: '12mp', category: 10 },
  { id: 1275, filename: 'png/image/13mp.png', name: '13mp', category: 10 },
  { id: 1276, filename: 'png/image/14mp.png', name: '14mp', category: 10 },
  { id: 1277, filename: 'png/image/15mp.png', name: '15mp', category: 10 },
  { id: 1278, filename: 'png/image/16mp.png', name: '16mp', category: 10 },
  { id: 1279, filename: 'png/image/17mp.png', name: '17mp', category: 10 },
  { id: 1280, filename: 'png/image/18mp.png', name: '18mp', category: 10 },
  { id: 1281, filename: 'png/image/19mp.png', name: '19mp', category: 10 },
  { id: 1282, filename: 'png/image/20mp.png', name: '20mp', category: 10 },
  { id: 1283, filename: 'png/image/21mp.png', name: '21mp', category: 10 },
  { id: 1284, filename: 'png/image/22mp.png', name: '22mp', category: 10 },
  { id: 1285, filename: 'png/image/23mp.png', name: '23mp', category: 10 },
  { id: 1286, filename: 'png/image/24mp.png', name: '24mp', category: 10 },
  { id: 1287, filename: 'png/image/2mp.png', name: '2mp', category: 10 },
  { id: 1288, filename: 'png/image/30fps_select.png', name: '30fps_select', category: 10 },
  { id: 1289, filename: 'png/image/3mp.png', name: '3mp', category: 10 },
  { id: 1290, filename: 'png/image/4mp.png', name: '4mp', category: 10 },
  { id: 1291, filename: 'png/image/5mp.png', name: '5mp', category: 10 },
  { id: 1292, filename: 'png/image/60fps_select.png', name: '60fps_select', category: 10 },
  { id: 1293, filename: 'png/image/6mp.png', name: '6mp', category: 10 },
  { id: 1294, filename: 'png/image/7mp.png', name: '7mp', category: 10 },
  { id: 1295, filename: 'png/image/8mp.png', name: '8mp', category: 10 },
  { id: 1296, filename: 'png/image/9mp.png', name: '9mp', category: 10 },
  { id: 1297, filename: 'png/image/add_a_photo.png', name: 'add_a_photo', category: 10 },
  {
    id: 1298,
    filename: 'png/image/add_photo_alternate.png',
    name: 'add_photo_alternate',
    category: 10,
  },
  { id: 1299, filename: 'png/image/add_to_photos.png', name: 'add_to_photos', category: 10 },
  { id: 1300, filename: 'png/image/adjust.png', name: 'adjust', category: 10 },
  { id: 1301, filename: 'png/image/animation.png', name: 'animation', category: 10 },
  { id: 1302, filename: 'png/image/assistant.png', name: 'assistant', category: 10 },
  { id: 1303, filename: 'png/image/assistant_photo.png', name: 'assistant_photo', category: 10 },
  { id: 1304, filename: 'png/image/audiotrack.png', name: 'audiotrack', category: 10 },
  { id: 1305, filename: 'png/image/auto_awesome.png', name: 'auto_awesome', category: 10 },
  {
    id: 1306,
    filename: 'png/image/auto_awesome_mosaic.png',
    name: 'auto_awesome_mosaic',
    category: 10,
  },
  {
    id: 1307,
    filename: 'png/image/auto_awesome_motion.png',
    name: 'auto_awesome_motion',
    category: 10,
  },
  { id: 1308, filename: 'png/image/auto_fix_high.png', name: 'auto_fix_high', category: 10 },
  { id: 1309, filename: 'png/image/auto_fix_normal.png', name: 'auto_fix_normal', category: 10 },
  { id: 1310, filename: 'png/image/auto_fix_off.png', name: 'auto_fix_off', category: 10 },
  { id: 1311, filename: 'png/image/auto_stories.png', name: 'auto_stories', category: 10 },
  { id: 1312, filename: 'png/image/autofps_select.png', name: 'autofps_select', category: 10 },
  { id: 1313, filename: 'png/image/bedtime.png', name: 'bedtime', category: 10 },
  { id: 1314, filename: 'png/image/bedtime_off.png', name: 'bedtime_off', category: 10 },
  { id: 1315, filename: 'png/image/blur_circular.png', name: 'blur_circular', category: 10 },
  { id: 1316, filename: 'png/image/blur_linear.png', name: 'blur_linear', category: 10 },
  { id: 1317, filename: 'png/image/blur_off.png', name: 'blur_off', category: 10 },
  { id: 1318, filename: 'png/image/blur_on.png', name: 'blur_on', category: 10 },
  { id: 1319, filename: 'png/image/brightness_1.png', name: 'brightness_1', category: 10 },
  { id: 1320, filename: 'png/image/brightness_2.png', name: 'brightness_2', category: 10 },
  { id: 1321, filename: 'png/image/brightness_3.png', name: 'brightness_3', category: 10 },
  { id: 1322, filename: 'png/image/brightness_4.png', name: 'brightness_4', category: 10 },
  { id: 1323, filename: 'png/image/brightness_5.png', name: 'brightness_5', category: 10 },
  { id: 1324, filename: 'png/image/brightness_6.png', name: 'brightness_6', category: 10 },
  { id: 1325, filename: 'png/image/brightness_7.png', name: 'brightness_7', category: 10 },
  { id: 1326, filename: 'png/image/broken_image.png', name: 'broken_image', category: 10 },
  { id: 1327, filename: 'png/image/brush.png', name: 'brush', category: 10 },
  { id: 1328, filename: 'png/image/burst_mode.png', name: 'burst_mode', category: 10 },
  { id: 1329, filename: 'png/image/camera.png', name: 'camera', category: 10 },
  { id: 1330, filename: 'png/image/camera_alt.png', name: 'camera_alt', category: 10 },
  { id: 1331, filename: 'png/image/camera_front.png', name: 'camera_front', category: 10 },
  { id: 1332, filename: 'png/image/camera_rear.png', name: 'camera_rear', category: 10 },
  { id: 1333, filename: 'png/image/camera_roll.png', name: 'camera_roll', category: 10 },
  { id: 1334, filename: 'png/image/cases.png', name: 'cases', category: 10 },
  {
    id: 1335,
    filename: 'png/image/center_focus_strong.png',
    name: 'center_focus_strong',
    category: 10,
  },
  {
    id: 1336,
    filename: 'png/image/center_focus_weak.png',
    name: 'center_focus_weak',
    category: 10,
  },
  { id: 1337, filename: 'png/image/circle.png', name: 'circle', category: 10 },
  { id: 1338, filename: 'png/image/collections.png', name: 'collections', category: 10 },
  {
    id: 1339,
    filename: 'png/image/collections_bookmark.png',
    name: 'collections_bookmark',
    category: 10,
  },
  { id: 1340, filename: 'png/image/color_lens.png', name: 'color_lens', category: 10 },
  { id: 1341, filename: 'png/image/colorize.png', name: 'colorize', category: 10 },
  { id: 1342, filename: 'png/image/compare.png', name: 'compare', category: 10 },
  { id: 1343, filename: 'png/image/contrast.png', name: 'contrast', category: 10 },
  { id: 1344, filename: 'png/image/control_point.png', name: 'control_point', category: 10 },
  {
    id: 1345,
    filename: 'png/image/control_point_duplicate.png',
    name: 'control_point_duplicate',
    category: 10,
  },
  { id: 1346, filename: 'png/image/crop.png', name: 'crop', category: 10 },
  { id: 1347, filename: 'png/image/crop_16_9.png', name: 'crop_16_9', category: 10 },
  { id: 1348, filename: 'png/image/crop_3_2.png', name: 'crop_3_2', category: 10 },
  { id: 1349, filename: 'png/image/crop_5_4.png', name: 'crop_5_4', category: 10 },
  { id: 1350, filename: 'png/image/crop_7_5.png', name: 'crop_7_5', category: 10 },
  { id: 1351, filename: 'png/image/crop_din.png', name: 'crop_din', category: 10 },
  { id: 1352, filename: 'png/image/crop_free.png', name: 'crop_free', category: 10 },
  { id: 1353, filename: 'png/image/crop_landscape.png', name: 'crop_landscape', category: 10 },
  { id: 1354, filename: 'png/image/crop_original.png', name: 'crop_original', category: 10 },
  { id: 1355, filename: 'png/image/crop_portrait.png', name: 'crop_portrait', category: 10 },
  { id: 1356, filename: 'png/image/crop_rotate.png', name: 'crop_rotate', category: 10 },
  { id: 1357, filename: 'png/image/crop_square.png', name: 'crop_square', category: 10 },
  { id: 1358, filename: 'png/image/currency_bitcoin.png', name: 'currency_bitcoin', category: 10 },
  { id: 1359, filename: 'png/image/currency_franc.png', name: 'currency_franc', category: 10 },
  { id: 1360, filename: 'png/image/currency_lira.png', name: 'currency_lira', category: 10 },
  { id: 1361, filename: 'png/image/currency_pound.png', name: 'currency_pound', category: 10 },
  { id: 1362, filename: 'png/image/currency_ruble.png', name: 'currency_ruble', category: 10 },
  { id: 1363, filename: 'png/image/currency_rupee.png', name: 'currency_rupee', category: 10 },
  { id: 1364, filename: 'png/image/currency_yen.png', name: 'currency_yen', category: 10 },
  { id: 1365, filename: 'png/image/currency_yuan.png', name: 'currency_yuan', category: 10 },
  { id: 1366, filename: 'png/image/deblur.png', name: 'deblur', category: 10 },
  { id: 1367, filename: 'png/image/dehaze.png', name: 'dehaze', category: 10 },
  { id: 1368, filename: 'png/image/details.png', name: 'details', category: 10 },
  { id: 1369, filename: 'png/image/dirty_lens.png', name: 'dirty_lens', category: 10 },
  { id: 1370, filename: 'png/image/edit.png', name: 'edit', category: 10 },
  { id: 1371, filename: 'png/image/euro.png', name: 'euro', category: 10 },
  { id: 1372, filename: 'png/image/exposure.png', name: 'exposure', category: 10 },
  { id: 1373, filename: 'png/image/exposure_neg_1.png', name: 'exposure_neg_1', category: 10 },
  { id: 1374, filename: 'png/image/exposure_neg_2.png', name: 'exposure_neg_2', category: 10 },
  { id: 1375, filename: 'png/image/exposure_plus_1.png', name: 'exposure_plus_1', category: 10 },
  { id: 1376, filename: 'png/image/exposure_plus_2.png', name: 'exposure_plus_2', category: 10 },
  { id: 1377, filename: 'png/image/exposure_zero.png', name: 'exposure_zero', category: 10 },
  {
    id: 1378,
    filename: 'png/image/face_retouching_natural.png',
    name: 'face_retouching_natural',
    category: 10,
  },
  {
    id: 1379,
    filename: 'png/image/face_retouching_off.png',
    name: 'face_retouching_off',
    category: 10,
  },
  { id: 1380, filename: 'png/image/filter.png', name: 'filter', category: 10 },
  { id: 1381, filename: 'png/image/filter_1.png', name: 'filter_1', category: 10 },
  { id: 1382, filename: 'png/image/filter_2.png', name: 'filter_2', category: 10 },
  { id: 1383, filename: 'png/image/filter_3.png', name: 'filter_3', category: 10 },
  { id: 1384, filename: 'png/image/filter_4.png', name: 'filter_4', category: 10 },
  { id: 1385, filename: 'png/image/filter_5.png', name: 'filter_5', category: 10 },
  { id: 1386, filename: 'png/image/filter_6.png', name: 'filter_6', category: 10 },
  { id: 1387, filename: 'png/image/filter_7.png', name: 'filter_7', category: 10 },
  { id: 1388, filename: 'png/image/filter_8.png', name: 'filter_8', category: 10 },
  { id: 1389, filename: 'png/image/filter_9.png', name: 'filter_9', category: 10 },
  { id: 1390, filename: 'png/image/filter_9_plus.png', name: 'filter_9_plus', category: 10 },
  { id: 1391, filename: 'png/image/filter_b_and_w.png', name: 'filter_b_and_w', category: 10 },
  {
    id: 1392,
    filename: 'png/image/filter_center_focus.png',
    name: 'filter_center_focus',
    category: 10,
  },
  { id: 1393, filename: 'png/image/filter_drama.png', name: 'filter_drama', category: 10 },
  { id: 1394, filename: 'png/image/filter_frames.png', name: 'filter_frames', category: 10 },
  { id: 1395, filename: 'png/image/filter_hdr.png', name: 'filter_hdr', category: 10 },
  { id: 1396, filename: 'png/image/filter_none.png', name: 'filter_none', category: 10 },
  {
    id: 1397,
    filename: 'png/image/filter_tilt_shift.png',
    name: 'filter_tilt_shift',
    category: 10,
  },
  { id: 1398, filename: 'png/image/filter_vintage.png', name: 'filter_vintage', category: 10 },
  { id: 1399, filename: 'png/image/flare.png', name: 'flare', category: 10 },
  { id: 1400, filename: 'png/image/flash_auto.png', name: 'flash_auto', category: 10 },
  { id: 1401, filename: 'png/image/flash_off.png', name: 'flash_off', category: 10 },
  { id: 1402, filename: 'png/image/flash_on.png', name: 'flash_on', category: 10 },
  { id: 1403, filename: 'png/image/flip.png', name: 'flip', category: 10 },
  {
    id: 1404,
    filename: 'png/image/flip_camera_android.png',
    name: 'flip_camera_android',
    category: 10,
  },
  { id: 1405, filename: 'png/image/flip_camera_ios.png', name: 'flip_camera_ios', category: 10 },
  { id: 1406, filename: 'png/image/gradient.png', name: 'gradient', category: 10 },
  { id: 1407, filename: 'png/image/grain.png', name: 'grain', category: 10 },
  { id: 1408, filename: 'png/image/grid_off.png', name: 'grid_off', category: 10 },
  { id: 1409, filename: 'png/image/grid_on.png', name: 'grid_on', category: 10 },
  {
    id: 1410,
    filename: 'png/image/hdr_enhanced_select.png',
    name: 'hdr_enhanced_select',
    category: 10,
  },
  { id: 1411, filename: 'png/image/hdr_off.png', name: 'hdr_off', category: 10 },
  { id: 1412, filename: 'png/image/hdr_on.png', name: 'hdr_on', category: 10 },
  { id: 1413, filename: 'png/image/hdr_plus.png', name: 'hdr_plus', category: 10 },
  { id: 1414, filename: 'png/image/hdr_strong.png', name: 'hdr_strong', category: 10 },
  { id: 1415, filename: 'png/image/hdr_weak.png', name: 'hdr_weak', category: 10 },
  { id: 1416, filename: 'png/image/healing.png', name: 'healing', category: 10 },
  { id: 1417, filename: 'png/image/hevc.png', name: 'hevc', category: 10 },
  { id: 1418, filename: 'png/image/hide_image.png', name: 'hide_image', category: 10 },
  { id: 1419, filename: 'png/image/image.png', name: 'image', category: 10 },
  {
    id: 1420,
    filename: 'png/image/image_aspect_ratio.png',
    name: 'image_aspect_ratio',
    category: 10,
  },
  {
    id: 1421,
    filename: 'png/image/image_not_supported.png',
    name: 'image_not_supported',
    category: 10,
  },
  { id: 1422, filename: 'png/image/image_search.png', name: 'image_search', category: 10 },
  {
    id: 1423,
    filename: 'png/image/incomplete_circle.png',
    name: 'incomplete_circle',
    category: 10,
  },
  { id: 1424, filename: 'png/image/iso.png', name: 'iso', category: 10 },
  { id: 1425, filename: 'png/image/landscape.png', name: 'landscape', category: 10 },
  { id: 1426, filename: 'png/image/leak_add.png', name: 'leak_add', category: 10 },
  { id: 1427, filename: 'png/image/leak_remove.png', name: 'leak_remove', category: 10 },
  { id: 1428, filename: 'png/image/lens.png', name: 'lens', category: 10 },
  { id: 1429, filename: 'png/image/linked_camera.png', name: 'linked_camera', category: 10 },
  { id: 1430, filename: 'png/image/logo_dev.png', name: 'logo_dev', category: 10 },
  { id: 1431, filename: 'png/image/looks.png', name: 'looks', category: 10 },
  { id: 1432, filename: 'png/image/looks_3.png', name: 'looks_3', category: 10 },
  { id: 1433, filename: 'png/image/looks_4.png', name: 'looks_4', category: 10 },
  { id: 1434, filename: 'png/image/looks_5.png', name: 'looks_5', category: 10 },
  { id: 1435, filename: 'png/image/looks_6.png', name: 'looks_6', category: 10 },
  { id: 1436, filename: 'png/image/looks_one.png', name: 'looks_one', category: 10 },
  { id: 1437, filename: 'png/image/looks_two.png', name: 'looks_two', category: 10 },
  { id: 1438, filename: 'png/image/loupe.png', name: 'loupe', category: 10 },
  { id: 1439, filename: 'png/image/mic_external_off.png', name: 'mic_external_off', category: 10 },
  { id: 1440, filename: 'png/image/mic_external_on.png', name: 'mic_external_on', category: 10 },
  {
    id: 1441,
    filename: 'png/image/monochrome_photos.png',
    name: 'monochrome_photos',
    category: 10,
  },
  {
    id: 1442,
    filename: 'png/image/motion_photos_auto.png',
    name: 'motion_photos_auto',
    category: 10,
  },
  {
    id: 1443,
    filename: 'png/image/motion_photos_off.png',
    name: 'motion_photos_off',
    category: 10,
  },
  { id: 1444, filename: 'png/image/motion_photos_on.png', name: 'motion_photos_on', category: 10 },
  {
    id: 1445,
    filename: 'png/image/motion_photos_pause.png',
    name: 'motion_photos_pause',
    category: 10,
  },
  {
    id: 1446,
    filename: 'png/image/motion_photos_paused.png',
    name: 'motion_photos_paused',
    category: 10,
  },
  { id: 1447, filename: 'png/image/movie_creation.png', name: 'movie_creation', category: 10 },
  { id: 1448, filename: 'png/image/movie_filter.png', name: 'movie_filter', category: 10 },
  { id: 1449, filename: 'png/image/mp.png', name: 'mp', category: 10 },
  { id: 1450, filename: 'png/image/music_note.png', name: 'music_note', category: 10 },
  { id: 1451, filename: 'png/image/music_off.png', name: 'music_off', category: 10 },
  { id: 1452, filename: 'png/image/nature.png', name: 'nature', category: 10 },
  { id: 1453, filename: 'png/image/nature_people.png', name: 'nature_people', category: 10 },
  { id: 1454, filename: 'png/image/navigate_before.png', name: 'navigate_before', category: 10 },
  { id: 1455, filename: 'png/image/navigate_next.png', name: 'navigate_next', category: 10 },
  { id: 1456, filename: 'png/image/palette.png', name: 'palette', category: 10 },
  { id: 1457, filename: 'png/image/panorama.png', name: 'panorama', category: 10 },
  {
    id: 1458,
    filename: 'png/image/panorama_fish_eye.png',
    name: 'panorama_fish_eye',
    category: 10,
  },
  {
    id: 1459,
    filename: 'png/image/panorama_horizontal.png',
    name: 'panorama_horizontal',
    category: 10,
  },
  {
    id: 1460,
    filename: 'png/image/panorama_horizontal_select.png',
    name: 'panorama_horizontal_select',
    category: 10,
  },
  {
    id: 1461,
    filename: 'png/image/panorama_photosphere.png',
    name: 'panorama_photosphere',
    category: 10,
  },
  {
    id: 1462,
    filename: 'png/image/panorama_photosphere_select.png',
    name: 'panorama_photosphere_select',
    category: 10,
  },
  {
    id: 1463,
    filename: 'png/image/panorama_vertical.png',
    name: 'panorama_vertical',
    category: 10,
  },
  {
    id: 1464,
    filename: 'png/image/panorama_vertical_select.png',
    name: 'panorama_vertical_select',
    category: 10,
  },
  {
    id: 1465,
    filename: 'png/image/panorama_wide_angle.png',
    name: 'panorama_wide_angle',
    category: 10,
  },
  {
    id: 1466,
    filename: 'png/image/panorama_wide_angle_select.png',
    name: 'panorama_wide_angle_select',
    category: 10,
  },
  { id: 1467, filename: 'png/image/photo.png', name: 'photo', category: 10 },
  { id: 1468, filename: 'png/image/photo_album.png', name: 'photo_album', category: 10 },
  { id: 1469, filename: 'png/image/photo_camera.png', name: 'photo_camera', category: 10 },
  {
    id: 1470,
    filename: 'png/image/photo_camera_back.png',
    name: 'photo_camera_back',
    category: 10,
  },
  {
    id: 1471,
    filename: 'png/image/photo_camera_front.png',
    name: 'photo_camera_front',
    category: 10,
  },
  { id: 1472, filename: 'png/image/photo_filter.png', name: 'photo_filter', category: 10 },
  { id: 1473, filename: 'png/image/photo_library.png', name: 'photo_library', category: 10 },
  {
    id: 1474,
    filename: 'png/image/photo_size_select_actual.png',
    name: 'photo_size_select_actual',
    category: 10,
  },
  {
    id: 1475,
    filename: 'png/image/photo_size_select_large.png',
    name: 'photo_size_select_large',
    category: 10,
  },
  {
    id: 1476,
    filename: 'png/image/photo_size_select_small.png',
    name: 'photo_size_select_small',
    category: 10,
  },
  { id: 1477, filename: 'png/image/picture_as_pdf.png', name: 'picture_as_pdf', category: 10 },
  { id: 1478, filename: 'png/image/portrait.png', name: 'portrait', category: 10 },
  { id: 1479, filename: 'png/image/raw_off.png', name: 'raw_off', category: 10 },
  { id: 1480, filename: 'png/image/raw_on.png', name: 'raw_on', category: 10 },
  { id: 1481, filename: 'png/image/receipt_long.png', name: 'receipt_long', category: 10 },
  { id: 1482, filename: 'png/image/remove_red_eye.png', name: 'remove_red_eye', category: 10 },
  {
    id: 1483,
    filename: 'png/image/rotate_90_degrees_ccw.png',
    name: 'rotate_90_degrees_ccw',
    category: 10,
  },
  {
    id: 1484,
    filename: 'png/image/rotate_90_degrees_cw.png',
    name: 'rotate_90_degrees_cw',
    category: 10,
  },
  { id: 1485, filename: 'png/image/rotate_left.png', name: 'rotate_left', category: 10 },
  { id: 1486, filename: 'png/image/rotate_right.png', name: 'rotate_right', category: 10 },
  { id: 1487, filename: 'png/image/shutter_speed.png', name: 'shutter_speed', category: 10 },
  { id: 1488, filename: 'png/image/slideshow.png', name: 'slideshow', category: 10 },
  { id: 1489, filename: 'png/image/straighten.png', name: 'straighten', category: 10 },
  { id: 1490, filename: 'png/image/style.png', name: 'style', category: 10 },
  { id: 1491, filename: 'png/image/switch_camera.png', name: 'switch_camera', category: 10 },
  { id: 1492, filename: 'png/image/switch_video.png', name: 'switch_video', category: 10 },
  { id: 1493, filename: 'png/image/tag_faces.png', name: 'tag_faces', category: 10 },
  { id: 1494, filename: 'png/image/texture.png', name: 'texture', category: 10 },
  { id: 1495, filename: 'png/image/thermostat_auto.png', name: 'thermostat_auto', category: 10 },
  { id: 1496, filename: 'png/image/timelapse.png', name: 'timelapse', category: 10 },
  { id: 1497, filename: 'png/image/timer.png', name: 'timer', category: 10 },
  { id: 1498, filename: 'png/image/timer_10.png', name: 'timer_10', category: 10 },
  { id: 1499, filename: 'png/image/timer_3.png', name: 'timer_3', category: 10 },
  { id: 1500, filename: 'png/image/timer_off.png', name: 'timer_off', category: 10 },
  { id: 1501, filename: 'png/image/tonality.png', name: 'tonality', category: 10 },
  { id: 1502, filename: 'png/image/transform.png', name: 'transform', category: 10 },
  { id: 1503, filename: 'png/image/tune.png', name: 'tune', category: 10 },
  {
    id: 1504,
    filename: 'png/image/video_camera_back.png',
    name: 'video_camera_back',
    category: 10,
  },
  {
    id: 1505,
    filename: 'png/image/video_camera_front.png',
    name: 'video_camera_front',
    category: 10,
  },
  { id: 1506, filename: 'png/image/video_stable.png', name: 'video_stable', category: 10 },
  { id: 1507, filename: 'png/image/view_comfy.png', name: 'view_comfy', category: 10 },
  { id: 1508, filename: 'png/image/view_compact.png', name: 'view_compact', category: 10 },
  { id: 1509, filename: 'png/image/vignette.png', name: 'vignette', category: 10 },
  { id: 1510, filename: 'png/image/vrpano.png', name: 'vrpano', category: 10 },
  { id: 1511, filename: 'png/image/wb_auto.png', name: 'wb_auto', category: 10 },
  { id: 1512, filename: 'png/image/wb_cloudy.png', name: 'wb_cloudy', category: 10 },
  { id: 1513, filename: 'png/image/wb_incandescent.png', name: 'wb_incandescent', category: 10 },
  { id: 1514, filename: 'png/image/wb_iridescent.png', name: 'wb_iridescent', category: 10 },
  { id: 1515, filename: 'png/image/wb_shade.png', name: 'wb_shade', category: 10 },
  { id: 1516, filename: 'png/image/wb_sunny.png', name: 'wb_sunny', category: 10 },
  { id: 1517, filename: 'png/image/wb_twighlight.png', name: 'wb_twighlight', category: 10 },
  { id: 1518, filename: 'png/image/wb_twilight.png', name: 'wb_twilight', category: 10 },
  { id: 1519, filename: 'png/maps/360.png', name: '360', category: 11 },
  { id: 1520, filename: 'png/maps/add_business.png', name: 'add_business', category: 11 },
  { id: 1521, filename: 'png/maps/add_location.png', name: 'add_location', category: 11 },
  { id: 1522, filename: 'png/maps/add_location_alt.png', name: 'add_location_alt', category: 11 },
  { id: 1523, filename: 'png/maps/add_road.png', name: 'add_road', category: 11 },
  { id: 1524, filename: 'png/maps/agriculture.png', name: 'agriculture', category: 11 },
  { id: 1525, filename: 'png/maps/airline_stops.png', name: 'airline_stops', category: 11 },
  { id: 1526, filename: 'png/maps/airlines.png', name: 'airlines', category: 11 },
  { id: 1527, filename: 'png/maps/alt_route.png', name: 'alt_route', category: 11 },
  { id: 1528, filename: 'png/maps/atm.png', name: 'atm', category: 11 },
  { id: 1529, filename: 'png/maps/attractions.png', name: 'attractions', category: 11 },
  { id: 1530, filename: 'png/maps/badge.png', name: 'badge', category: 11 },
  { id: 1531, filename: 'png/maps/bakery_dining.png', name: 'bakery_dining', category: 11 },
  { id: 1532, filename: 'png/maps/beenhere.png', name: 'beenhere', category: 11 },
  { id: 1533, filename: 'png/maps/bike_scooter.png', name: 'bike_scooter', category: 11 },
  { id: 1534, filename: 'png/maps/breakfast_dining.png', name: 'breakfast_dining', category: 11 },
  { id: 1535, filename: 'png/maps/brunch_dining.png', name: 'brunch_dining', category: 11 },
  { id: 1536, filename: 'png/maps/bus_alert.png', name: 'bus_alert', category: 11 },
  { id: 1537, filename: 'png/maps/car_crash.png', name: 'car_crash', category: 11 },
  { id: 1538, filename: 'png/maps/car_rental.png', name: 'car_rental', category: 11 },
  { id: 1539, filename: 'png/maps/car_repair.png', name: 'car_repair', category: 11 },
  { id: 1540, filename: 'png/maps/castle.png', name: 'castle', category: 11 },
  { id: 1541, filename: 'png/maps/category.png', name: 'category', category: 11 },
  { id: 1542, filename: 'png/maps/celebration.png', name: 'celebration', category: 11 },
  { id: 1543, filename: 'png/maps/church.png', name: 'church', category: 11 },
  { id: 1544, filename: 'png/maps/cleaning_services.png', name: 'cleaning_services', category: 11 },
  {
    id: 1545,
    filename: 'png/maps/compass_calibration.png',
    name: 'compass_calibration',
    category: 11,
  },
  {
    id: 1546,
    filename: 'png/maps/connecting_airports.png',
    name: 'connecting_airports',
    category: 11,
  },
  { id: 1547, filename: 'png/maps/crisis_alert.png', name: 'crisis_alert', category: 11 },
  { id: 1548, filename: 'png/maps/delivery_dining.png', name: 'delivery_dining', category: 11 },
  { id: 1549, filename: 'png/maps/departure_board.png', name: 'departure_board', category: 11 },
  { id: 1550, filename: 'png/maps/design_services.png', name: 'design_services', category: 11 },
  { id: 1551, filename: 'png/maps/diamond.png', name: 'diamond', category: 11 },
  { id: 1552, filename: 'png/maps/dinner_dining.png', name: 'dinner_dining', category: 11 },
  { id: 1553, filename: 'png/maps/directions.png', name: 'directions', category: 11 },
  { id: 1554, filename: 'png/maps/directions_bike.png', name: 'directions_bike', category: 11 },
  { id: 1555, filename: 'png/maps/directions_boat.png', name: 'directions_boat', category: 11 },
  {
    id: 1556,
    filename: 'png/maps/directions_boat_filled.png',
    name: 'directions_boat_filled',
    category: 11,
  },
  { id: 1557, filename: 'png/maps/directions_bus.png', name: 'directions_bus', category: 11 },
  {
    id: 1558,
    filename: 'png/maps/directions_bus_filled.png',
    name: 'directions_bus_filled',
    category: 11,
  },
  { id: 1559, filename: 'png/maps/directions_car.png', name: 'directions_car', category: 11 },
  {
    id: 1560,
    filename: 'png/maps/directions_car_filled.png',
    name: 'directions_car_filled',
    category: 11,
  },
  {
    id: 1561,
    filename: 'png/maps/directions_railway.png',
    name: 'directions_railway',
    category: 11,
  },
  {
    id: 1562,
    filename: 'png/maps/directions_railway_filled.png',
    name: 'directions_railway_filled',
    category: 11,
  },
  { id: 1563, filename: 'png/maps/directions_run.png', name: 'directions_run', category: 11 },
  { id: 1564, filename: 'png/maps/directions_subway.png', name: 'directions_subway', category: 11 },
  {
    id: 1565,
    filename: 'png/maps/directions_subway_filled.png',
    name: 'directions_subway_filled',
    category: 11,
  },
  {
    id: 1566,
    filename: 'png/maps/directions_transit.png',
    name: 'directions_transit',
    category: 11,
  },
  {
    id: 1567,
    filename: 'png/maps/directions_transit_filled.png',
    name: 'directions_transit_filled',
    category: 11,
  },
  { id: 1568, filename: 'png/maps/directions_walk.png', name: 'directions_walk', category: 11 },
  { id: 1569, filename: 'png/maps/dry_cleaning.png', name: 'dry_cleaning', category: 11 },
  { id: 1570, filename: 'png/maps/edit_attributes.png', name: 'edit_attributes', category: 11 },
  { id: 1571, filename: 'png/maps/edit_location.png', name: 'edit_location', category: 11 },
  { id: 1572, filename: 'png/maps/edit_location_alt.png', name: 'edit_location_alt', category: 11 },
  { id: 1573, filename: 'png/maps/edit_road.png', name: 'edit_road', category: 11 },
  { id: 1574, filename: 'png/maps/egg.png', name: 'egg', category: 11 },
  { id: 1575, filename: 'png/maps/egg_alt.png', name: 'egg_alt', category: 11 },
  { id: 1576, filename: 'png/maps/electric_bike.png', name: 'electric_bike', category: 11 },
  { id: 1577, filename: 'png/maps/electric_car.png', name: 'electric_car', category: 11 },
  { id: 1578, filename: 'png/maps/electric_moped.png', name: 'electric_moped', category: 11 },
  { id: 1579, filename: 'png/maps/electric_rickshaw.png', name: 'electric_rickshaw', category: 11 },
  { id: 1580, filename: 'png/maps/electric_scooter.png', name: 'electric_scooter', category: 11 },
  {
    id: 1581,
    filename: 'png/maps/electrical_services.png',
    name: 'electrical_services',
    category: 11,
  },
  { id: 1582, filename: 'png/maps/emergency.png', name: 'emergency', category: 11 },
  {
    id: 1583,
    filename: 'png/maps/emergency_recording.png',
    name: 'emergency_recording',
    category: 11,
  },
  { id: 1584, filename: 'png/maps/emergency_share.png', name: 'emergency_share', category: 11 },
  { id: 1585, filename: 'png/maps/ev_station.png', name: 'ev_station', category: 11 },
  { id: 1586, filename: 'png/maps/factory.png', name: 'factory', category: 11 },
  { id: 1587, filename: 'png/maps/fastfood.png', name: 'fastfood', category: 11 },
  { id: 1588, filename: 'png/maps/festival.png', name: 'festival', category: 11 },
  { id: 1589, filename: 'png/maps/flight.png', name: 'flight', category: 11 },
  { id: 1590, filename: 'png/maps/flight_class.png', name: 'flight_class', category: 11 },
  { id: 1591, filename: 'png/maps/forest.png', name: 'forest', category: 11 },
  { id: 1592, filename: 'png/maps/fork_left.png', name: 'fork_left', category: 11 },
  { id: 1593, filename: 'png/maps/fork_right.png', name: 'fork_right', category: 11 },
  { id: 1594, filename: 'png/maps/fort.png', name: 'fort', category: 11 },
  { id: 1595, filename: 'png/maps/hail.png', name: 'hail', category: 11 },
  { id: 1596, filename: 'png/maps/handyman.png', name: 'handyman', category: 11 },
  { id: 1597, filename: 'png/maps/hardware.png', name: 'hardware', category: 11 },
  {
    id: 1598,
    filename: 'png/maps/home_repair_service.png',
    name: 'home_repair_service',
    category: 11,
  },
  { id: 1599, filename: 'png/maps/hotel.png', name: 'hotel', category: 11 },
  { id: 1600, filename: 'png/maps/hvac.png', name: 'hvac', category: 11 },
  { id: 1601, filename: 'png/maps/icecream.png', name: 'icecream', category: 11 },
  { id: 1602, filename: 'png/maps/kebab_dining.png', name: 'kebab_dining', category: 11 },
  { id: 1603, filename: 'png/maps/layers.png', name: 'layers', category: 11 },
  { id: 1604, filename: 'png/maps/layers_clear.png', name: 'layers_clear', category: 11 },
  { id: 1605, filename: 'png/maps/liquor.png', name: 'liquor', category: 11 },
  { id: 1606, filename: 'png/maps/local_activity.png', name: 'local_activity', category: 11 },
  { id: 1607, filename: 'png/maps/local_airport.png', name: 'local_airport', category: 11 },
  { id: 1608, filename: 'png/maps/local_atm.png', name: 'local_atm', category: 11 },
  { id: 1609, filename: 'png/maps/local_bar.png', name: 'local_bar', category: 11 },
  { id: 1610, filename: 'png/maps/local_cafe.png', name: 'local_cafe', category: 11 },
  { id: 1611, filename: 'png/maps/local_car_wash.png', name: 'local_car_wash', category: 11 },
  {
    id: 1612,
    filename: 'png/maps/local_convenience_store.png',
    name: 'local_convenience_store',
    category: 11,
  },
  { id: 1613, filename: 'png/maps/local_dining.png', name: 'local_dining', category: 11 },
  { id: 1614, filename: 'png/maps/local_drink.png', name: 'local_drink', category: 11 },
  {
    id: 1615,
    filename: 'png/maps/local_fire_department.png',
    name: 'local_fire_department',
    category: 11,
  },
  { id: 1616, filename: 'png/maps/local_florist.png', name: 'local_florist', category: 11 },
  { id: 1617, filename: 'png/maps/local_gas_station.png', name: 'local_gas_station', category: 11 },
  {
    id: 1618,
    filename: 'png/maps/local_grocery_store.png',
    name: 'local_grocery_store',
    category: 11,
  },
  { id: 1619, filename: 'png/maps/local_hospital.png', name: 'local_hospital', category: 11 },
  { id: 1620, filename: 'png/maps/local_hotel.png', name: 'local_hotel', category: 11 },
  {
    id: 1621,
    filename: 'png/maps/local_laundry_service.png',
    name: 'local_laundry_service',
    category: 11,
  },
  { id: 1622, filename: 'png/maps/local_library.png', name: 'local_library', category: 11 },
  { id: 1623, filename: 'png/maps/local_mall.png', name: 'local_mall', category: 11 },
  { id: 1624, filename: 'png/maps/local_movies.png', name: 'local_movies', category: 11 },
  { id: 1625, filename: 'png/maps/local_offer.png', name: 'local_offer', category: 11 },
  { id: 1626, filename: 'png/maps/local_parking.png', name: 'local_parking', category: 11 },
  { id: 1627, filename: 'png/maps/local_pharmacy.png', name: 'local_pharmacy', category: 11 },
  { id: 1628, filename: 'png/maps/local_phone.png', name: 'local_phone', category: 11 },
  { id: 1629, filename: 'png/maps/local_pizza.png', name: 'local_pizza', category: 11 },
  { id: 1630, filename: 'png/maps/local_play.png', name: 'local_play', category: 11 },
  { id: 1631, filename: 'png/maps/local_police.png', name: 'local_police', category: 11 },
  { id: 1632, filename: 'png/maps/local_post_office.png', name: 'local_post_office', category: 11 },
  { id: 1633, filename: 'png/maps/local_printshop.png', name: 'local_printshop', category: 11 },
  { id: 1634, filename: 'png/maps/local_see.png', name: 'local_see', category: 11 },
  { id: 1635, filename: 'png/maps/local_shipping.png', name: 'local_shipping', category: 11 },
  { id: 1636, filename: 'png/maps/local_taxi.png', name: 'local_taxi', category: 11 },
  { id: 1637, filename: 'png/maps/location_pin.png', name: 'location_pin', category: 11 },
  { id: 1638, filename: 'png/maps/lunch_dining.png', name: 'lunch_dining', category: 11 },
  { id: 1639, filename: 'png/maps/map.png', name: 'map', category: 11 },
  { id: 1640, filename: 'png/maps/maps_ugc.png', name: 'maps_ugc', category: 11 },
  {
    id: 1641,
    filename: 'png/maps/medical_information.png',
    name: 'medical_information',
    category: 11,
  },
  { id: 1642, filename: 'png/maps/medical_services.png', name: 'medical_services', category: 11 },
  { id: 1643, filename: 'png/maps/menu_book.png', name: 'menu_book', category: 11 },
  { id: 1644, filename: 'png/maps/merge.png', name: 'merge', category: 11 },
  { id: 1645, filename: 'png/maps/minor_crash.png', name: 'minor_crash', category: 11 },
  {
    id: 1646,
    filename: 'png/maps/miscellaneous_services.png',
    name: 'miscellaneous_services',
    category: 11,
  },
  { id: 1647, filename: 'png/maps/mode_of_travel.png', name: 'mode_of_travel', category: 11 },
  { id: 1648, filename: 'png/maps/money.png', name: 'money', category: 11 },
  { id: 1649, filename: 'png/maps/moped.png', name: 'moped', category: 11 },
  { id: 1650, filename: 'png/maps/mosque.png', name: 'mosque', category: 11 },
  { id: 1651, filename: 'png/maps/moving.png', name: 'moving', category: 11 },
  { id: 1652, filename: 'png/maps/multiple_stop.png', name: 'multiple_stop', category: 11 },
  { id: 1653, filename: 'png/maps/museum.png', name: 'museum', category: 11 },
  { id: 1654, filename: 'png/maps/my_location.png', name: 'my_location', category: 11 },
  { id: 1655, filename: 'png/maps/navigation.png', name: 'navigation', category: 11 },
  { id: 1656, filename: 'png/maps/near_me.png', name: 'near_me', category: 11 },
  { id: 1657, filename: 'png/maps/near_me_disabled.png', name: 'near_me_disabled', category: 11 },
  { id: 1658, filename: 'png/maps/nightlife.png', name: 'nightlife', category: 11 },
  { id: 1659, filename: 'png/maps/no_crash.png', name: 'no_crash', category: 11 },
  { id: 1660, filename: 'png/maps/no_meals.png', name: 'no_meals', category: 11 },
  { id: 1661, filename: 'png/maps/no_meals_ouline.png', name: 'no_meals_ouline', category: 11 },
  { id: 1662, filename: 'png/maps/no_transfer.png', name: 'no_transfer', category: 11 },
  {
    id: 1663,
    filename: 'png/maps/not_listed_location.png',
    name: 'not_listed_location',
    category: 11,
  },
  { id: 1664, filename: 'png/maps/park.png', name: 'park', category: 11 },
  { id: 1665, filename: 'png/maps/pedal_bike.png', name: 'pedal_bike', category: 11 },
  { id: 1666, filename: 'png/maps/person_pin.png', name: 'person_pin', category: 11 },
  { id: 1667, filename: 'png/maps/person_pin_circle.png', name: 'person_pin_circle', category: 11 },
  { id: 1668, filename: 'png/maps/pest_control.png', name: 'pest_control', category: 11 },
  {
    id: 1669,
    filename: 'png/maps/pest_control_rodent.png',
    name: 'pest_control_rodent',
    category: 11,
  },
  { id: 1670, filename: 'png/maps/pin_drop.png', name: 'pin_drop', category: 11 },
  { id: 1671, filename: 'png/maps/place.png', name: 'place', category: 11 },
  { id: 1672, filename: 'png/maps/plumbing.png', name: 'plumbing', category: 11 },
  { id: 1673, filename: 'png/maps/railway_alert.png', name: 'railway_alert', category: 11 },
  { id: 1674, filename: 'png/maps/ramen_dining.png', name: 'ramen_dining', category: 11 },
  { id: 1675, filename: 'png/maps/ramp_left.png', name: 'ramp_left', category: 11 },
  { id: 1676, filename: 'png/maps/ramp_right.png', name: 'ramp_right', category: 11 },
  { id: 1677, filename: 'png/maps/rate_review.png', name: 'rate_review', category: 11 },
  { id: 1678, filename: 'png/maps/remove_road.png', name: 'remove_road', category: 11 },
  { id: 1679, filename: 'png/maps/restaurant.png', name: 'restaurant', category: 11 },
  { id: 1680, filename: 'png/maps/restaurant_menu.png', name: 'restaurant_menu', category: 11 },
  { id: 1681, filename: 'png/maps/roundabout_left.png', name: 'roundabout_left', category: 11 },
  { id: 1682, filename: 'png/maps/roundabout_right.png', name: 'roundabout_right', category: 11 },
  { id: 1683, filename: 'png/maps/route.png', name: 'route', category: 11 },
  { id: 1684, filename: 'png/maps/run_circle.png', name: 'run_circle', category: 11 },
  { id: 1685, filename: 'png/maps/safety_check.png', name: 'safety_check', category: 11 },
  { id: 1686, filename: 'png/maps/sailing.png', name: 'sailing', category: 11 },
  { id: 1687, filename: 'png/maps/satellite.png', name: 'satellite', category: 11 },
  {
    id: 1688,
    filename: 'png/maps/screen_rotation_alt.png',
    name: 'screen_rotation_alt',
    category: 11,
  },
  { id: 1689, filename: 'png/maps/set_meal.png', name: 'set_meal', category: 11 },
  { id: 1690, filename: 'png/maps/signpost.png', name: 'signpost', category: 11 },
  { id: 1691, filename: 'png/maps/snowmobile.png', name: 'snowmobile', category: 11 },
  { id: 1692, filename: 'png/maps/sos.png', name: 'sos', category: 11 },
  { id: 1693, filename: 'png/maps/soup_kitchen.png', name: 'soup_kitchen', category: 11 },
  { id: 1694, filename: 'png/maps/stadium.png', name: 'stadium', category: 11 },
  {
    id: 1695,
    filename: 'png/maps/store_mall_directory.png',
    name: 'store_mall_directory',
    category: 11,
  },
  { id: 1696, filename: 'png/maps/straight.png', name: 'straight', category: 11 },
  { id: 1697, filename: 'png/maps/streetview.png', name: 'streetview', category: 11 },
  { id: 1698, filename: 'png/maps/subway.png', name: 'subway', category: 11 },
  { id: 1699, filename: 'png/maps/synagogue.png', name: 'synagogue', category: 11 },
  { id: 1700, filename: 'png/maps/takeout_dining.png', name: 'takeout_dining', category: 11 },
  { id: 1701, filename: 'png/maps/taxi_alert.png', name: 'taxi_alert', category: 11 },
  { id: 1702, filename: 'png/maps/temple_buddhist.png', name: 'temple_buddhist', category: 11 },
  { id: 1703, filename: 'png/maps/temple_hindu.png', name: 'temple_hindu', category: 11 },
  { id: 1704, filename: 'png/maps/terrain.png', name: 'terrain', category: 11 },
  { id: 1705, filename: 'png/maps/theater_comedy.png', name: 'theater_comedy', category: 11 },
  { id: 1706, filename: 'png/maps/tire_repair.png', name: 'tire_repair', category: 11 },
  { id: 1707, filename: 'png/maps/traffic.png', name: 'traffic', category: 11 },
  { id: 1708, filename: 'png/maps/train.png', name: 'train', category: 11 },
  { id: 1709, filename: 'png/maps/tram.png', name: 'tram', category: 11 },
  {
    id: 1710,
    filename: 'png/maps/transfer_within_a_station.png',
    name: 'transfer_within_a_station',
    category: 11,
  },
  { id: 1711, filename: 'png/maps/transit_enterexit.png', name: 'transit_enterexit', category: 11 },
  { id: 1712, filename: 'png/maps/trip_origin.png', name: 'trip_origin', category: 11 },
  { id: 1713, filename: 'png/maps/turn_left.png', name: 'turn_left', category: 11 },
  { id: 1714, filename: 'png/maps/turn_right.png', name: 'turn_right', category: 11 },
  { id: 1715, filename: 'png/maps/turn_sharp_left.png', name: 'turn_sharp_left', category: 11 },
  { id: 1716, filename: 'png/maps/turn_sharp_right.png', name: 'turn_sharp_right', category: 11 },
  { id: 1717, filename: 'png/maps/turn_slight_left.png', name: 'turn_slight_left', category: 11 },
  { id: 1718, filename: 'png/maps/turn_slight_right.png', name: 'turn_slight_right', category: 11 },
  { id: 1719, filename: 'png/maps/two_wheeler.png', name: 'two_wheeler', category: 11 },
  { id: 1720, filename: 'png/maps/u_turn_left.png', name: 'u_turn_left', category: 11 },
  { id: 1721, filename: 'png/maps/u_turn_right.png', name: 'u_turn_right', category: 11 },
  {
    id: 1722,
    filename: 'png/maps/volunteer_activism.png',
    name: 'volunteer_activism',
    category: 11,
  },
  { id: 1723, filename: 'png/maps/warehouse.png', name: 'warehouse', category: 11 },
  { id: 1724, filename: 'png/maps/wine_bar.png', name: 'wine_bar', category: 11 },
  { id: 1725, filename: 'png/maps/wrong_location.png', name: 'wrong_location', category: 11 },
  { id: 1726, filename: 'png/maps/zoom_in_map.png', name: 'zoom_in_map', category: 11 },
  { id: 1727, filename: 'png/maps/zoom_out_map.png', name: 'zoom_out_map', category: 11 },
  {
    id: 1728,
    filename: 'png/navigation/app_settings_alt.png',
    name: 'app_settings_alt',
    category: 12,
  },
  { id: 1729, filename: 'png/navigation/apps.png', name: 'apps', category: 12 },
  { id: 1730, filename: 'png/navigation/apps_outage.png', name: 'apps_outage', category: 12 },
  { id: 1731, filename: 'png/navigation/arrow_back.png', name: 'arrow_back', category: 12 },
  { id: 1732, filename: 'png/navigation/arrow_back_ios.png', name: 'arrow_back_ios', category: 12 },
  {
    id: 1733,
    filename: 'png/navigation/arrow_back_ios_new.png',
    name: 'arrow_back_ios_new',
    category: 12,
  },
  { id: 1734, filename: 'png/navigation/arrow_downward.png', name: 'arrow_downward', category: 12 },
  {
    id: 1735,
    filename: 'png/navigation/arrow_drop_down.png',
    name: 'arrow_drop_down',
    category: 12,
  },
  {
    id: 1736,
    filename: 'png/navigation/arrow_drop_down_circle.png',
    name: 'arrow_drop_down_circle',
    category: 12,
  },
  { id: 1737, filename: 'png/navigation/arrow_drop_up.png', name: 'arrow_drop_up', category: 12 },
  { id: 1738, filename: 'png/navigation/arrow_forward.png', name: 'arrow_forward', category: 12 },
  {
    id: 1739,
    filename: 'png/navigation/arrow_forward_ios.png',
    name: 'arrow_forward_ios',
    category: 12,
  },
  { id: 1740, filename: 'png/navigation/arrow_left.png', name: 'arrow_left', category: 12 },
  { id: 1741, filename: 'png/navigation/arrow_right.png', name: 'arrow_right', category: 12 },
  { id: 1742, filename: 'png/navigation/arrow_upward.png', name: 'arrow_upward', category: 12 },
  {
    id: 1743,
    filename: 'png/navigation/assistant_direction.png',
    name: 'assistant_direction',
    category: 12,
  },
  {
    id: 1744,
    filename: 'png/navigation/assistant_navigation.png',
    name: 'assistant_navigation',
    category: 12,
  },
  { id: 1745, filename: 'png/navigation/campaign.png', name: 'campaign', category: 12 },
  { id: 1746, filename: 'png/navigation/cancel.png', name: 'cancel', category: 12 },
  { id: 1747, filename: 'png/navigation/check.png', name: 'check', category: 12 },
  { id: 1748, filename: 'png/navigation/chevron_left.png', name: 'chevron_left', category: 12 },
  { id: 1749, filename: 'png/navigation/chevron_right.png', name: 'chevron_right', category: 12 },
  { id: 1750, filename: 'png/navigation/close.png', name: 'close', category: 12 },
  { id: 1751, filename: 'png/navigation/double_arrow.png', name: 'double_arrow', category: 12 },
  { id: 1752, filename: 'png/navigation/east.png', name: 'east', category: 12 },
  {
    id: 1753,
    filename: 'png/navigation/expand_circle_down.png',
    name: 'expand_circle_down',
    category: 12,
  },
  { id: 1754, filename: 'png/navigation/expand_less.png', name: 'expand_less', category: 12 },
  { id: 1755, filename: 'png/navigation/expand_more.png', name: 'expand_more', category: 12 },
  { id: 1756, filename: 'png/navigation/first_page.png', name: 'first_page', category: 12 },
  { id: 1757, filename: 'png/navigation/fullscreen.png', name: 'fullscreen', category: 12 },
  {
    id: 1758,
    filename: 'png/navigation/fullscreen_exit.png',
    name: 'fullscreen_exit',
    category: 12,
  },
  { id: 1759, filename: 'png/navigation/home_work.png', name: 'home_work', category: 12 },
  { id: 1760, filename: 'png/navigation/last_page.png', name: 'last_page', category: 12 },
  { id: 1761, filename: 'png/navigation/legend_toggle.png', name: 'legend_toggle', category: 12 },
  { id: 1762, filename: 'png/navigation/maps_home_work.png', name: 'maps_home_work', category: 12 },
  { id: 1763, filename: 'png/navigation/menu.png', name: 'menu', category: 12 },
  { id: 1764, filename: 'png/navigation/menu_open.png', name: 'menu_open', category: 12 },
  { id: 1765, filename: 'png/navigation/more_horiz.png', name: 'more_horiz', category: 12 },
  { id: 1766, filename: 'png/navigation/more_vert.png', name: 'more_vert', category: 12 },
  { id: 1767, filename: 'png/navigation/north.png', name: 'north', category: 12 },
  { id: 1768, filename: 'png/navigation/north_east.png', name: 'north_east', category: 12 },
  { id: 1769, filename: 'png/navigation/north_west.png', name: 'north_west', category: 12 },
  { id: 1770, filename: 'png/navigation/offline_share.png', name: 'offline_share', category: 12 },
  { id: 1771, filename: 'png/navigation/payments.png', name: 'payments', category: 12 },
  {
    id: 1772,
    filename: 'png/navigation/pivot_table_chart.png',
    name: 'pivot_table_chart',
    category: 12,
  },
  { id: 1773, filename: 'png/navigation/refresh.png', name: 'refresh', category: 12 },
  { id: 1774, filename: 'png/navigation/south.png', name: 'south', category: 12 },
  { id: 1775, filename: 'png/navigation/south_east.png', name: 'south_east', category: 12 },
  { id: 1776, filename: 'png/navigation/south_west.png', name: 'south_west', category: 12 },
  {
    id: 1777,
    filename: 'png/navigation/subdirectory_arrow_left.png',
    name: 'subdirectory_arrow_left',
    category: 12,
  },
  {
    id: 1778,
    filename: 'png/navigation/subdirectory_arrow_right.png',
    name: 'subdirectory_arrow_right',
    category: 12,
  },
  { id: 1779, filename: 'png/navigation/switch_left.png', name: 'switch_left', category: 12 },
  { id: 1780, filename: 'png/navigation/switch_right.png', name: 'switch_right', category: 12 },
  { id: 1781, filename: 'png/navigation/unfold_less.png', name: 'unfold_less', category: 12 },
  { id: 1782, filename: 'png/navigation/unfold_more.png', name: 'unfold_more', category: 12 },
  {
    id: 1783,
    filename: 'png/navigation/waterfall_chart.png',
    name: 'waterfall_chart',
    category: 12,
  },
  { id: 1784, filename: 'png/navigation/west.png', name: 'west', category: 12 },
  { id: 1785, filename: 'png/notification/account_tree.png', name: 'account_tree', category: 13 },
  { id: 1786, filename: 'png/notification/adb.png', name: 'adb', category: 13 },
  { id: 1787, filename: 'png/notification/add_call.png', name: 'add_call', category: 13 },
  {
    id: 1788,
    filename: 'png/notification/airline_seat_flat.png',
    name: 'airline_seat_flat',
    category: 13,
  },
  {
    id: 1789,
    filename: 'png/notification/airline_seat_flat_angled.png',
    name: 'airline_seat_flat_angled',
    category: 13,
  },
  {
    id: 1790,
    filename: 'png/notification/airline_seat_individual_suite.png',
    name: 'airline_seat_individual_suite',
    category: 13,
  },
  {
    id: 1791,
    filename: 'png/notification/airline_seat_legroom_extra.png',
    name: 'airline_seat_legroom_extra',
    category: 13,
  },
  {
    id: 1792,
    filename: 'png/notification/airline_seat_legroom_normal.png',
    name: 'airline_seat_legroom_normal',
    category: 13,
  },
  {
    id: 1793,
    filename: 'png/notification/airline_seat_legroom_reduced.png',
    name: 'airline_seat_legroom_reduced',
    category: 13,
  },
  {
    id: 1794,
    filename: 'png/notification/airline_seat_recline_extra.png',
    name: 'airline_seat_recline_extra',
    category: 13,
  },
  {
    id: 1795,
    filename: 'png/notification/airline_seat_recline_normal.png',
    name: 'airline_seat_recline_normal',
    category: 13,
  },
  {
    id: 1796,
    filename: 'png/notification/bluetooth_audio.png',
    name: 'bluetooth_audio',
    category: 13,
  },
  {
    id: 1797,
    filename: 'png/notification/confirmation_number.png',
    name: 'confirmation_number',
    category: 13,
  },
  {
    id: 1798,
    filename: 'png/notification/directions_off.png',
    name: 'directions_off',
    category: 13,
  },
  { id: 1799, filename: 'png/notification/disc_full.png', name: 'disc_full', category: 13 },
  { id: 1800, filename: 'png/notification/do_disturb.png', name: 'do_disturb', category: 13 },
  {
    id: 1801,
    filename: 'png/notification/do_disturb_alt.png',
    name: 'do_disturb_alt',
    category: 13,
  },
  {
    id: 1802,
    filename: 'png/notification/do_disturb_off.png',
    name: 'do_disturb_off',
    category: 13,
  },
  { id: 1803, filename: 'png/notification/do_disturb_on.png', name: 'do_disturb_on', category: 13 },
  {
    id: 1804,
    filename: 'png/notification/do_not_disturb.png',
    name: 'do_not_disturb',
    category: 13,
  },
  {
    id: 1805,
    filename: 'png/notification/do_not_disturb_alt.png',
    name: 'do_not_disturb_alt',
    category: 13,
  },
  {
    id: 1806,
    filename: 'png/notification/do_not_disturb_off.png',
    name: 'do_not_disturb_off',
    category: 13,
  },
  {
    id: 1807,
    filename: 'png/notification/do_not_disturb_on.png',
    name: 'do_not_disturb_on',
    category: 13,
  },
  { id: 1808, filename: 'png/notification/drive_eta.png', name: 'drive_eta', category: 13 },
  {
    id: 1809,
    filename: 'png/notification/enhanced_encryption.png',
    name: 'enhanced_encryption',
    category: 13,
  },
  {
    id: 1810,
    filename: 'png/notification/event_available.png',
    name: 'event_available',
    category: 13,
  },
  { id: 1811, filename: 'png/notification/event_busy.png', name: 'event_busy', category: 13 },
  { id: 1812, filename: 'png/notification/event_note.png', name: 'event_note', category: 13 },
  {
    id: 1813,
    filename: 'png/notification/folder_special.png',
    name: 'folder_special',
    category: 13,
  },
  {
    id: 1814,
    filename: 'png/notification/imagesearch_roller.png',
    name: 'imagesearch_roller',
    category: 13,
  },
  { id: 1815, filename: 'png/notification/live_tv.png', name: 'live_tv', category: 13 },
  { id: 1816, filename: 'png/notification/mms.png', name: 'mms', category: 13 },
  { id: 1817, filename: 'png/notification/more.png', name: 'more', category: 13 },
  { id: 1818, filename: 'png/notification/network_check.png', name: 'network_check', category: 13 },
  {
    id: 1819,
    filename: 'png/notification/network_locked.png',
    name: 'network_locked',
    category: 13,
  },
  { id: 1820, filename: 'png/notification/no_encryption.png', name: 'no_encryption', category: 13 },
  {
    id: 1821,
    filename: 'png/notification/no_encryption_gmailerrorred.png',
    name: 'no_encryption_gmailerrorred',
    category: 13,
  },
  {
    id: 1822,
    filename: 'png/notification/ondemand_video.png',
    name: 'ondemand_video',
    category: 13,
  },
  {
    id: 1823,
    filename: 'png/notification/personal_video.png',
    name: 'personal_video',
    category: 13,
  },
  {
    id: 1824,
    filename: 'png/notification/phone_bluetooth_speaker.png',
    name: 'phone_bluetooth_speaker',
    category: 13,
  },
  {
    id: 1825,
    filename: 'png/notification/phone_callback.png',
    name: 'phone_callback',
    category: 13,
  },
  {
    id: 1826,
    filename: 'png/notification/phone_forwarded.png',
    name: 'phone_forwarded',
    category: 13,
  },
  { id: 1827, filename: 'png/notification/phone_in_talk.png', name: 'phone_in_talk', category: 13 },
  { id: 1828, filename: 'png/notification/phone_locked.png', name: 'phone_locked', category: 13 },
  { id: 1829, filename: 'png/notification/phone_missed.png', name: 'phone_missed', category: 13 },
  { id: 1830, filename: 'png/notification/phone_paused.png', name: 'phone_paused', category: 13 },
  { id: 1831, filename: 'png/notification/power.png', name: 'power', category: 13 },
  { id: 1832, filename: 'png/notification/power_off.png', name: 'power_off', category: 13 },
  { id: 1833, filename: 'png/notification/priority_high.png', name: 'priority_high', category: 13 },
  {
    id: 1834,
    filename: 'png/notification/running_with_errors.png',
    name: 'running_with_errors',
    category: 13,
  },
  { id: 1835, filename: 'png/notification/sd_card.png', name: 'sd_card', category: 13 },
  { id: 1836, filename: 'png/notification/sd_card_alert.png', name: 'sd_card_alert', category: 13 },
  {
    id: 1837,
    filename: 'png/notification/sim_card_alert.png',
    name: 'sim_card_alert',
    category: 13,
  },
  { id: 1838, filename: 'png/notification/sms.png', name: 'sms', category: 13 },
  { id: 1839, filename: 'png/notification/sms_failed.png', name: 'sms_failed', category: 13 },
  { id: 1840, filename: 'png/notification/support_agent.png', name: 'support_agent', category: 13 },
  { id: 1841, filename: 'png/notification/sync.png', name: 'sync', category: 13 },
  { id: 1842, filename: 'png/notification/sync_disabled.png', name: 'sync_disabled', category: 13 },
  { id: 1843, filename: 'png/notification/sync_lock.png', name: 'sync_lock', category: 13 },
  { id: 1844, filename: 'png/notification/sync_problem.png', name: 'sync_problem', category: 13 },
  { id: 1845, filename: 'png/notification/system_update.png', name: 'system_update', category: 13 },
  { id: 1846, filename: 'png/notification/tap_and_play.png', name: 'tap_and_play', category: 13 },
  { id: 1847, filename: 'png/notification/time_to_leave.png', name: 'time_to_leave', category: 13 },
  { id: 1848, filename: 'png/notification/tv_off.png', name: 'tv_off', category: 13 },
  { id: 1849, filename: 'png/notification/vibration.png', name: 'vibration', category: 13 },
  { id: 1850, filename: 'png/notification/voice_chat.png', name: 'voice_chat', category: 13 },
  { id: 1851, filename: 'png/notification/vpn_lock.png', name: 'vpn_lock', category: 13 },
  { id: 1852, filename: 'png/notification/wc.png', name: 'wc', category: 13 },
  { id: 1853, filename: 'png/notification/wifi.png', name: 'wifi', category: 13 },
  { id: 1854, filename: 'png/notification/wifi_off.png', name: 'wifi_off', category: 13 },
  { id: 1855, filename: 'png/places/ac_unit.png', name: 'ac_unit', category: 14 },
  { id: 1856, filename: 'png/places/airport_shuttle.png', name: 'airport_shuttle', category: 14 },
  { id: 1857, filename: 'png/places/all_inclusive.png', name: 'all_inclusive', category: 14 },
  { id: 1858, filename: 'png/places/apartment.png', name: 'apartment', category: 14 },
  {
    id: 1859,
    filename: 'png/places/baby_changing_station.png',
    name: 'baby_changing_station',
    category: 14,
  },
  { id: 1860, filename: 'png/places/backpack.png', name: 'backpack', category: 14 },
  { id: 1861, filename: 'png/places/balcony.png', name: 'balcony', category: 14 },
  { id: 1862, filename: 'png/places/bathtub.png', name: 'bathtub', category: 14 },
  { id: 1863, filename: 'png/places/beach_access.png', name: 'beach_access', category: 14 },
  { id: 1864, filename: 'png/places/bento.png', name: 'bento', category: 14 },
  { id: 1865, filename: 'png/places/bungalow.png', name: 'bungalow', category: 14 },
  { id: 1866, filename: 'png/places/business_center.png', name: 'business_center', category: 14 },
  { id: 1867, filename: 'png/places/cabin.png', name: 'cabin', category: 14 },
  { id: 1868, filename: 'png/places/carpenter.png', name: 'carpenter', category: 14 },
  { id: 1869, filename: 'png/places/casino.png', name: 'casino', category: 14 },
  { id: 1870, filename: 'png/places/chalet.png', name: 'chalet', category: 14 },
  { id: 1871, filename: 'png/places/charging_station.png', name: 'charging_station', category: 14 },
  { id: 1872, filename: 'png/places/checkroom.png', name: 'checkroom', category: 14 },
  { id: 1873, filename: 'png/places/child_care.png', name: 'child_care', category: 14 },
  { id: 1874, filename: 'png/places/child_friendly.png', name: 'child_friendly', category: 14 },
  { id: 1875, filename: 'png/places/corporate_fare.png', name: 'corporate_fare', category: 14 },
  { id: 1876, filename: 'png/places/cottage.png', name: 'cottage', category: 14 },
  { id: 1877, filename: 'png/places/countertops.png', name: 'countertops', category: 14 },
  { id: 1878, filename: 'png/places/crib.png', name: 'crib', category: 14 },
  { id: 1879, filename: 'png/places/do_not_step.png', name: 'do_not_step', category: 14 },
  { id: 1880, filename: 'png/places/do_not_touch.png', name: 'do_not_touch', category: 14 },
  { id: 1881, filename: 'png/places/dry.png', name: 'dry', category: 14 },
  { id: 1882, filename: 'png/places/elevator.png', name: 'elevator', category: 14 },
  { id: 1883, filename: 'png/places/escalator.png', name: 'escalator', category: 14 },
  {
    id: 1884,
    filename: 'png/places/escalator_warning.png',
    name: 'escalator_warning',
    category: 14,
  },
  { id: 1885, filename: 'png/places/family_restroom.png', name: 'family_restroom', category: 14 },
  { id: 1886, filename: 'png/places/fence.png', name: 'fence', category: 14 },
  {
    id: 1887,
    filename: 'png/places/fire_extinguisher.png',
    name: 'fire_extinguisher',
    category: 14,
  },
  { id: 1888, filename: 'png/places/fitness_center.png', name: 'fitness_center', category: 14 },
  { id: 1889, filename: 'png/places/food_bank.png', name: 'food_bank', category: 14 },
  { id: 1890, filename: 'png/places/foundation.png', name: 'foundation', category: 14 },
  { id: 1891, filename: 'png/places/free_breakfast.png', name: 'free_breakfast', category: 14 },
  { id: 1892, filename: 'png/places/gite.png', name: 'gite', category: 14 },
  { id: 1893, filename: 'png/places/golf_course.png', name: 'golf_course', category: 14 },
  { id: 1894, filename: 'png/places/grass.png', name: 'grass', category: 14 },
  { id: 1895, filename: 'png/places/holiday_village.png', name: 'holiday_village', category: 14 },
  { id: 1896, filename: 'png/places/hot_tub.png', name: 'hot_tub', category: 14 },
  { id: 1897, filename: 'png/places/house.png', name: 'house', category: 14 },
  { id: 1898, filename: 'png/places/house_siding.png', name: 'house_siding', category: 14 },
  { id: 1899, filename: 'png/places/houseboat.png', name: 'houseboat', category: 14 },
  { id: 1900, filename: 'png/places/iron.png', name: 'iron', category: 14 },
  { id: 1901, filename: 'png/places/kitchen.png', name: 'kitchen', category: 14 },
  { id: 1902, filename: 'png/places/meeting_room.png', name: 'meeting_room', category: 14 },
  { id: 1903, filename: 'png/places/microwave.png', name: 'microwave', category: 14 },
  { id: 1904, filename: 'png/places/night_shelter.png', name: 'night_shelter', category: 14 },
  { id: 1905, filename: 'png/places/no_backpack.png', name: 'no_backpack', category: 14 },
  { id: 1906, filename: 'png/places/no_cell.png', name: 'no_cell', category: 14 },
  { id: 1907, filename: 'png/places/no_drinks.png', name: 'no_drinks', category: 14 },
  { id: 1908, filename: 'png/places/no_flash.png', name: 'no_flash', category: 14 },
  { id: 1909, filename: 'png/places/no_food.png', name: 'no_food', category: 14 },
  { id: 1910, filename: 'png/places/no_meeting_room.png', name: 'no_meeting_room', category: 14 },
  { id: 1911, filename: 'png/places/no_photography.png', name: 'no_photography', category: 14 },
  { id: 1912, filename: 'png/places/no_stroller.png', name: 'no_stroller', category: 14 },
  { id: 1913, filename: 'png/places/other_houses.png', name: 'other_houses', category: 14 },
  { id: 1914, filename: 'png/places/pool.png', name: 'pool', category: 14 },
  { id: 1915, filename: 'png/places/rice_bowl.png', name: 'rice_bowl', category: 14 },
  { id: 1916, filename: 'png/places/roofing.png', name: 'roofing', category: 14 },
  { id: 1917, filename: 'png/places/room_preferences.png', name: 'room_preferences', category: 14 },
  { id: 1918, filename: 'png/places/room_service.png', name: 'room_service', category: 14 },
  { id: 1919, filename: 'png/places/rv_hookup.png', name: 'rv_hookup', category: 14 },
  { id: 1920, filename: 'png/places/smoke_free.png', name: 'smoke_free', category: 14 },
  { id: 1921, filename: 'png/places/smoking_rooms.png', name: 'smoking_rooms', category: 14 },
  { id: 1922, filename: 'png/places/soap.png', name: 'soap', category: 14 },
  { id: 1923, filename: 'png/places/spa.png', name: 'spa', category: 14 },
  { id: 1924, filename: 'png/places/sports_bar.png', name: 'sports_bar', category: 14 },
  { id: 1925, filename: 'png/places/stairs.png', name: 'stairs', category: 14 },
  { id: 1926, filename: 'png/places/storefront.png', name: 'storefront', category: 14 },
  { id: 1927, filename: 'png/places/stroller.png', name: 'stroller', category: 14 },
  { id: 1928, filename: 'png/places/tapas.png', name: 'tapas', category: 14 },
  { id: 1929, filename: 'png/places/tty.png', name: 'tty', category: 14 },
  { id: 1930, filename: 'png/places/umbrella.png', name: 'umbrella', category: 14 },
  { id: 1931, filename: 'png/places/vape_free.png', name: 'vape_free', category: 14 },
  { id: 1932, filename: 'png/places/vaping_rooms.png', name: 'vaping_rooms', category: 14 },
  { id: 1933, filename: 'png/places/villa.png', name: 'villa', category: 14 },
  { id: 1934, filename: 'png/places/wash.png', name: 'wash', category: 14 },
  { id: 1935, filename: 'png/places/water_damage.png', name: 'water_damage', category: 14 },
  {
    id: 1936,
    filename: 'png/places/wheelchair_pickup.png',
    name: 'wheelchair_pickup',
    category: 14,
  },
  { id: 1937, filename: 'png/search/bathroom.png', name: 'bathroom', category: 15 },
  { id: 1938, filename: 'png/search/bed.png', name: 'bed', category: 15 },
  { id: 1939, filename: 'png/search/bedroom_baby.png', name: 'bedroom_baby', category: 15 },
  { id: 1940, filename: 'png/search/bedroom_child.png', name: 'bedroom_child', category: 15 },
  { id: 1941, filename: 'png/search/bedroom_parent.png', name: 'bedroom_parent', category: 15 },
  { id: 1942, filename: 'png/search/blender.png', name: 'blender', category: 15 },
  { id: 1943, filename: 'png/search/camera_indoor.png', name: 'camera_indoor', category: 15 },
  { id: 1944, filename: 'png/search/camera_outdoor.png', name: 'camera_outdoor', category: 15 },
  { id: 1945, filename: 'png/search/chair.png', name: 'chair', category: 15 },
  { id: 1946, filename: 'png/search/chair_alt.png', name: 'chair_alt', category: 15 },
  { id: 1947, filename: 'png/search/coffee.png', name: 'coffee', category: 15 },
  { id: 1948, filename: 'png/search/coffee_maker.png', name: 'coffee_maker', category: 15 },
  { id: 1949, filename: 'png/search/dining.png', name: 'dining', category: 15 },
  { id: 1950, filename: 'png/search/door_back.png', name: 'door_back', category: 15 },
  { id: 1951, filename: 'png/search/door_front.png', name: 'door_front', category: 15 },
  { id: 1952, filename: 'png/search/door_sliding.png', name: 'door_sliding', category: 15 },
  { id: 1953, filename: 'png/search/doorbell.png', name: 'doorbell', category: 15 },
  { id: 1954, filename: 'png/search/feed.png', name: 'feed', category: 15 },
  { id: 1955, filename: 'png/search/flatware.png', name: 'flatware', category: 15 },
  { id: 1956, filename: 'png/search/garage.png', name: 'garage', category: 15 },
  { id: 1957, filename: 'png/search/light.png', name: 'light', category: 15 },
  { id: 1958, filename: 'png/search/living.png', name: 'living', category: 15 },
  { id: 1959, filename: 'png/search/manage_search.png', name: 'manage_search', category: 15 },
  { id: 1960, filename: 'png/search/podcasts.png', name: 'podcasts', category: 15 },
  { id: 1961, filename: 'png/search/shower.png', name: 'shower', category: 15 },
  { id: 1962, filename: 'png/search/table_bar.png', name: 'table_bar', category: 15 },
  { id: 1963, filename: 'png/search/table_restaurant.png', name: 'table_restaurant', category: 15 },
  { id: 1964, filename: 'png/search/window.png', name: 'window', category: 15 },
  { id: 1965, filename: 'png/search/yard.png', name: 'yard', category: 15 },
  { id: 1966, filename: 'png/social/6_ft_apart.png', name: '6_ft_apart', category: 16 },
  { id: 1967, filename: 'png/social/add_moderator.png', name: 'add_moderator', category: 16 },
  { id: 1968, filename: 'png/social/add_reaction.png', name: 'add_reaction', category: 16 },
  { id: 1969, filename: 'png/social/architecture.png', name: 'architecture', category: 16 },
  { id: 1970, filename: 'png/social/back_hand.png', name: 'back_hand', category: 16 },
  { id: 1971, filename: 'png/social/boy.png', name: 'boy', category: 16 },
  { id: 1972, filename: 'png/social/cake.png', name: 'cake', category: 16 },
  { id: 1973, filename: 'png/social/catching_pokemon.png', name: 'catching_pokemon', category: 16 },
  { id: 1974, filename: 'png/social/clean_hands.png', name: 'clean_hands', category: 16 },
  { id: 1975, filename: 'png/social/co2.png', name: 'co2', category: 16 },
  { id: 1976, filename: 'png/social/compost.png', name: 'compost', category: 16 },
  {
    id: 1977,
    filename: 'png/social/connect_without_contact.png',
    name: 'connect_without_contact',
    category: 16,
  },
  { id: 1978, filename: 'png/social/construction.png', name: 'construction', category: 16 },
  { id: 1979, filename: 'png/social/cookie.png', name: 'cookie', category: 16 },
  { id: 1980, filename: 'png/social/coronavirus.png', name: 'coronavirus', category: 16 },
  { id: 1981, filename: 'png/social/cruelty_free.png', name: 'cruelty_free', category: 16 },
  { id: 1982, filename: 'png/social/cyclone.png', name: 'cyclone', category: 16 },
  { id: 1983, filename: 'png/social/deck.png', name: 'deck', category: 16 },
  { id: 1984, filename: 'png/social/domain.png', name: 'domain', category: 16 },
  { id: 1985, filename: 'png/social/domain_add.png', name: 'domain_add', category: 16 },
  { id: 1986, filename: 'png/social/downhill_skiing.png', name: 'downhill_skiing', category: 16 },
  {
    id: 1987,
    filename: 'png/social/edit_notifications.png',
    name: 'edit_notifications',
    category: 16,
  },
  { id: 1988, filename: 'png/social/elderly.png', name: 'elderly', category: 16 },
  { id: 1989, filename: 'png/social/elderly_woman.png', name: 'elderly_woman', category: 16 },
  { id: 1990, filename: 'png/social/emoji_emotions.png', name: 'emoji_emotions', category: 16 },
  { id: 1991, filename: 'png/social/emoji_events.png', name: 'emoji_events', category: 16 },
  { id: 1992, filename: 'png/social/emoji_flags.png', name: 'emoji_flags', category: 16 },
  {
    id: 1993,
    filename: 'png/social/emoji_food_beverage.png',
    name: 'emoji_food_beverage',
    category: 16,
  },
  { id: 1994, filename: 'png/social/emoji_nature.png', name: 'emoji_nature', category: 16 },
  { id: 1995, filename: 'png/social/emoji_objects.png', name: 'emoji_objects', category: 16 },
  { id: 1996, filename: 'png/social/emoji_people.png', name: 'emoji_people', category: 16 },
  { id: 1997, filename: 'png/social/emoji_symbols.png', name: 'emoji_symbols', category: 16 },
  {
    id: 1998,
    filename: 'png/social/emoji_transportation.png',
    name: 'emoji_transportation',
    category: 16,
  },
  { id: 1999, filename: 'png/social/engineering.png', name: 'engineering', category: 16 },
  { id: 2000, filename: 'png/social/facebook.png', name: 'facebook', category: 16 },
  { id: 2001, filename: 'png/social/female.png', name: 'female', category: 16 },
  { id: 2002, filename: 'png/social/fireplace.png', name: 'fireplace', category: 16 },
  { id: 2003, filename: 'png/social/fitbit.png', name: 'fitbit', category: 16 },
  { id: 2004, filename: 'png/social/flood.png', name: 'flood', category: 16 },
  { id: 2005, filename: 'png/social/follow_the_signs.png', name: 'follow_the_signs', category: 16 },
  { id: 2006, filename: 'png/social/front_hand.png', name: 'front_hand', category: 16 },
  { id: 2007, filename: 'png/social/girl.png', name: 'girl', category: 16 },
  { id: 2008, filename: 'png/social/group.png', name: 'group', category: 16 },
  { id: 2009, filename: 'png/social/group_add.png', name: 'group_add', category: 16 },
  { id: 2010, filename: 'png/social/group_off.png', name: 'group_off', category: 16 },
  { id: 2011, filename: 'png/social/group_remove.png', name: 'group_remove', category: 16 },
  { id: 2012, filename: 'png/social/groups.png', name: 'groups', category: 16 },
  { id: 2013, filename: 'png/social/handshake.png', name: 'handshake', category: 16 },
  {
    id: 2014,
    filename: 'png/social/health_and_safety.png',
    name: 'health_and_safety',
    category: 16,
  },
  { id: 2015, filename: 'png/social/heart_broken.png', name: 'heart_broken', category: 16 },
  { id: 2016, filename: 'png/social/hiking.png', name: 'hiking', category: 16 },
  { id: 2017, filename: 'png/social/history_edu.png', name: 'history_edu', category: 16 },
  { id: 2018, filename: 'png/social/hive.png', name: 'hive', category: 16 },
  { id: 2019, filename: 'png/social/ice_skating.png', name: 'ice_skating', category: 16 },
  { id: 2020, filename: 'png/social/interests.png', name: 'interests', category: 16 },
  { id: 2021, filename: 'png/social/ios_share.png', name: 'ios_share', category: 16 },
  { id: 2022, filename: 'png/social/kayaking.png', name: 'kayaking', category: 16 },
  { id: 2023, filename: 'png/social/king_bed.png', name: 'king_bed', category: 16 },
  { id: 2024, filename: 'png/social/kitesurfing.png', name: 'kitesurfing', category: 16 },
  { id: 2025, filename: 'png/social/landslide.png', name: 'landslide', category: 16 },
  { id: 2026, filename: 'png/social/location_city.png', name: 'location_city', category: 16 },
  { id: 2027, filename: 'png/social/luggage.png', name: 'luggage', category: 16 },
  { id: 2028, filename: 'png/social/male.png', name: 'male', category: 16 },
  { id: 2029, filename: 'png/social/man.png', name: 'man', category: 16 },
  { id: 2030, filename: 'png/social/masks.png', name: 'masks', category: 16 },
  { id: 2031, filename: 'png/social/military_tech.png', name: 'military_tech', category: 16 },
  { id: 2032, filename: 'png/social/mood.png', name: 'mood', category: 16 },
  { id: 2033, filename: 'png/social/mood_bad.png', name: 'mood_bad', category: 16 },
  { id: 2034, filename: 'png/social/nights_stay.png', name: 'nights_stay', category: 16 },
  { id: 2035, filename: 'png/social/no_luggage.png', name: 'no_luggage', category: 16 },
  { id: 2036, filename: 'png/social/nordic_walking.png', name: 'nordic_walking', category: 16 },
  { id: 2037, filename: 'png/social/notification_add.png', name: 'notification_add', category: 16 },
  { id: 2038, filename: 'png/social/notifications.png', name: 'notifications', category: 16 },
  {
    id: 2039,
    filename: 'png/social/notifications_active.png',
    name: 'notifications_active',
    category: 16,
  },
  {
    id: 2040,
    filename: 'png/social/notifications_none.png',
    name: 'notifications_none',
    category: 16,
  },
  {
    id: 2041,
    filename: 'png/social/notifications_off.png',
    name: 'notifications_off',
    category: 16,
  },
  {
    id: 2042,
    filename: 'png/social/notifications_paused.png',
    name: 'notifications_paused',
    category: 16,
  },
  { id: 2043, filename: 'png/social/outdoor_grill.png', name: 'outdoor_grill', category: 16 },
  { id: 2044, filename: 'png/social/pages.png', name: 'pages', category: 16 },
  { id: 2045, filename: 'png/social/paragliding.png', name: 'paragliding', category: 16 },
  { id: 2046, filename: 'png/social/party_mode.png', name: 'party_mode', category: 16 },
  { id: 2047, filename: 'png/social/people.png', name: 'people', category: 16 },
  { id: 2048, filename: 'png/social/people_alt.png', name: 'people_alt', category: 16 },
  { id: 2049, filename: 'png/social/people_outline.png', name: 'people_outline', category: 16 },
  { id: 2050, filename: 'png/social/person.png', name: 'person', category: 16 },
  { id: 2051, filename: 'png/social/person_add.png', name: 'person_add', category: 16 },
  { id: 2052, filename: 'png/social/person_add_alt.png', name: 'person_add_alt', category: 16 },
  { id: 2053, filename: 'png/social/person_add_alt_1.png', name: 'person_add_alt_1', category: 16 },
  { id: 2054, filename: 'png/social/person_off.png', name: 'person_off', category: 16 },
  { id: 2055, filename: 'png/social/person_outline.png', name: 'person_outline', category: 16 },
  { id: 2056, filename: 'png/social/person_remove.png', name: 'person_remove', category: 16 },
  {
    id: 2057,
    filename: 'png/social/person_remove_alt_1.png',
    name: 'person_remove_alt_1',
    category: 16,
  },
  { id: 2058, filename: 'png/social/personal_injury.png', name: 'personal_injury', category: 16 },
  { id: 2059, filename: 'png/social/piano.png', name: 'piano', category: 16 },
  { id: 2060, filename: 'png/social/piano_off.png', name: 'piano_off', category: 16 },
  { id: 2061, filename: 'png/social/pix.png', name: 'pix', category: 16 },
  { id: 2062, filename: 'png/social/plus_one.png', name: 'plus_one', category: 16 },
  { id: 2063, filename: 'png/social/poll.png', name: 'poll', category: 16 },
  {
    id: 2064,
    filename: 'png/social/precision_manufacturing.png',
    name: 'precision_manufacturing',
    category: 16,
  },
  { id: 2065, filename: 'png/social/psychology.png', name: 'psychology', category: 16 },
  { id: 2066, filename: 'png/social/public.png', name: 'public', category: 16 },
  { id: 2067, filename: 'png/social/public_off.png', name: 'public_off', category: 16 },
  {
    id: 2068,
    filename: 'png/social/real_estate_agent.png',
    name: 'real_estate_agent',
    category: 16,
  },
  { id: 2069, filename: 'png/social/recommend.png', name: 'recommend', category: 16 },
  { id: 2070, filename: 'png/social/recycling.png', name: 'recycling', category: 16 },
  { id: 2071, filename: 'png/social/reduce_capacity.png', name: 'reduce_capacity', category: 16 },
  { id: 2072, filename: 'png/social/remove_moderator.png', name: 'remove_moderator', category: 16 },
  { id: 2073, filename: 'png/social/roller_skating.png', name: 'roller_skating', category: 16 },
  { id: 2074, filename: 'png/social/safety_divider.png', name: 'safety_divider', category: 16 },
  { id: 2075, filename: 'png/social/sanitizer.png', name: 'sanitizer', category: 16 },
  { id: 2076, filename: 'png/social/scale.png', name: 'scale', category: 16 },
  { id: 2077, filename: 'png/social/school.png', name: 'school', category: 16 },
  { id: 2078, filename: 'png/social/science.png', name: 'science', category: 16 },
  { id: 2079, filename: 'png/social/scoreboard.png', name: 'scoreboard', category: 16 },
  { id: 2080, filename: 'png/social/scuba_diving.png', name: 'scuba_diving', category: 16 },
  { id: 2081, filename: 'png/social/self_improvement.png', name: 'self_improvement', category: 16 },
  {
    id: 2082,
    filename: 'png/social/sentiment_dissatisfied.png',
    name: 'sentiment_dissatisfied',
    category: 16,
  },
  {
    id: 2083,
    filename: 'png/social/sentiment_neutral.png',
    name: 'sentiment_neutral',
    category: 16,
  },
  {
    id: 2084,
    filename: 'png/social/sentiment_satisfied.png',
    name: 'sentiment_satisfied',
    category: 16,
  },
  {
    id: 2085,
    filename: 'png/social/sentiment_very_dissatisfied.png',
    name: 'sentiment_very_dissatisfied',
    category: 16,
  },
  {
    id: 2086,
    filename: 'png/social/sentiment_very_satisfied.png',
    name: 'sentiment_very_satisfied',
    category: 16,
  },
  { id: 2087, filename: 'png/social/severe_cold.png', name: 'severe_cold', category: 16 },
  { id: 2088, filename: 'png/social/share.png', name: 'share', category: 16 },
  { id: 2089, filename: 'png/social/sick.png', name: 'sick', category: 16 },
  { id: 2090, filename: 'png/social/sign_language.png', name: 'sign_language', category: 16 },
  { id: 2091, filename: 'png/social/single_bed.png', name: 'single_bed', category: 16 },
  { id: 2092, filename: 'png/social/skateboarding.png', name: 'skateboarding', category: 16 },
  { id: 2093, filename: 'png/social/sledding.png', name: 'sledding', category: 16 },
  { id: 2094, filename: 'png/social/snowboarding.png', name: 'snowboarding', category: 16 },
  { id: 2095, filename: 'png/social/snowshoeing.png', name: 'snowshoeing', category: 16 },
  { id: 2096, filename: 'png/social/social_distance.png', name: 'social_distance', category: 16 },
  { id: 2097, filename: 'png/social/south_america.png', name: 'south_america', category: 16 },
  { id: 2098, filename: 'png/social/sports.png', name: 'sports', category: 16 },
  { id: 2099, filename: 'png/social/sports_baseball.png', name: 'sports_baseball', category: 16 },
  {
    id: 2100,
    filename: 'png/social/sports_basketball.png',
    name: 'sports_basketball',
    category: 16,
  },
  { id: 2101, filename: 'png/social/sports_cricket.png', name: 'sports_cricket', category: 16 },
  { id: 2102, filename: 'png/social/sports_esports.png', name: 'sports_esports', category: 16 },
  { id: 2103, filename: 'png/social/sports_football.png', name: 'sports_football', category: 16 },
  { id: 2104, filename: 'png/social/sports_golf.png', name: 'sports_golf', category: 16 },
  {
    id: 2105,
    filename: 'png/social/sports_gymnastics.png',
    name: 'sports_gymnastics',
    category: 16,
  },
  { id: 2106, filename: 'png/social/sports_handball.png', name: 'sports_handball', category: 16 },
  { id: 2107, filename: 'png/social/sports_hockey.png', name: 'sports_hockey', category: 16 },
  { id: 2108, filename: 'png/social/sports_kabaddi.png', name: 'sports_kabaddi', category: 16 },
  {
    id: 2109,
    filename: 'png/social/sports_martial_arts.png',
    name: 'sports_martial_arts',
    category: 16,
  },
  { id: 2110, filename: 'png/social/sports_mma.png', name: 'sports_mma', category: 16 },
  {
    id: 2111,
    filename: 'png/social/sports_motorsports.png',
    name: 'sports_motorsports',
    category: 16,
  },
  { id: 2112, filename: 'png/social/sports_rugby.png', name: 'sports_rugby', category: 16 },
  { id: 2113, filename: 'png/social/sports_soccer.png', name: 'sports_soccer', category: 16 },
  { id: 2114, filename: 'png/social/sports_tennis.png', name: 'sports_tennis', category: 16 },
  {
    id: 2115,
    filename: 'png/social/sports_volleyball.png',
    name: 'sports_volleyball',
    category: 16,
  },
  { id: 2116, filename: 'png/social/surfing.png', name: 'surfing', category: 16 },
  { id: 2117, filename: 'png/social/switch_account.png', name: 'switch_account', category: 16 },
  { id: 2118, filename: 'png/social/thumb_down_alt.png', name: 'thumb_down_alt', category: 16 },
  { id: 2119, filename: 'png/social/thumb_up_alt.png', name: 'thumb_up_alt', category: 16 },
  { id: 2120, filename: 'png/social/thunderstorm.png', name: 'thunderstorm', category: 16 },
  { id: 2121, filename: 'png/social/tornado.png', name: 'tornado', category: 16 },
  { id: 2122, filename: 'png/social/transgender.png', name: 'transgender', category: 16 },
  { id: 2123, filename: 'png/social/travel_explore.png', name: 'travel_explore', category: 16 },
  { id: 2124, filename: 'png/social/tsunami.png', name: 'tsunami', category: 16 },
  { id: 2125, filename: 'png/social/vaccines.png', name: 'vaccines', category: 16 },
  { id: 2126, filename: 'png/social/volcano.png', name: 'volcano', category: 16 },
  { id: 2127, filename: 'png/social/water_drop.png', name: 'water_drop', category: 16 },
  { id: 2128, filename: 'png/social/waving_hand.png', name: 'waving_hand', category: 16 },
  { id: 2129, filename: 'png/social/whatsapp.png', name: 'whatsapp', category: 16 },
  { id: 2130, filename: 'png/social/whatshot.png', name: 'whatshot', category: 16 },
  { id: 2131, filename: 'png/social/woman.png', name: 'woman', category: 16 },
  {
    id: 2132,
    filename: 'png/social/workspace_premium.png',
    name: 'workspace_premium',
    category: 16,
  },
  { id: 2133, filename: 'png/toggle/check_box.png', name: 'check_box', category: 17 },
  {
    id: 2134,
    filename: 'png/toggle/check_box_outline_blank.png',
    name: 'check_box_outline_blank',
    category: 17,
  },
  {
    id: 2135,
    filename: 'png/toggle/indeterminate_check_box.png',
    name: 'indeterminate_check_box',
    category: 17,
  },
  {
    id: 2136,
    filename: 'png/toggle/radio_button_checked.png',
    name: 'radio_button_checked',
    category: 17,
  },
  {
    id: 2137,
    filename: 'png/toggle/radio_button_unchecked.png',
    name: 'radio_button_unchecked',
    category: 17,
  },
  { id: 2138, filename: 'png/toggle/star.png', name: 'star', category: 17 },
  { id: 2139, filename: 'png/toggle/star_border.png', name: 'star_border', category: 17 },
  {
    id: 2140,
    filename: 'png/toggle/star_border_purple500.png',
    name: 'star_border_purple500',
    category: 17,
  },
  { id: 2141, filename: 'png/toggle/star_half.png', name: 'star_half', category: 17 },
  { id: 2142, filename: 'png/toggle/star_outline.png', name: 'star_outline', category: 17 },
  { id: 2143, filename: 'png/toggle/star_purple500.png', name: 'star_purple500', category: 17 },
  { id: 2144, filename: 'png/toggle/toggle_off.png', name: 'toggle_off', category: 17 },
  { id: 2145, filename: 'png/toggle/toggle_on.png', name: 'toggle_on', category: 17 },
]
