export enum Category {
  ACTION,
  ALERT,
  AV,
  COMMUNICATION,
  CONTENT,
  DEVICE,
  EDITOR,
  FILE,
  HARDWARE,
  HOME,
  IMAGE,
  MAPS,
  NAVIGATION,
  NOTIFICATION,
  PLACES,
  SEARCH,
  SOCIAL,
  TOGGLE,
}
